//Mobile------------------------------------------------------------------------------------------------------
.cols-2-icons {
	text-align: center;
	h2 {
		font-size: 2rem;
		color: var(--color-primary);
		margin-bottom: 32px;
	}
	.text-container {
		background-color: var(--color-primary);
		color: #FFF;
		text-align: center;
		padding: 32px;
		min-height: 330px;
		display: flex;
		align-items: flex-end;
		border-radius: 24px;
		
		p {
			font-size: 1.5rem;
			font-weight: 700;
			line-height: 1.3;
		}
	}
	.content-wrapper {
		position: relative;
	}
	.icons-inline {
		background-color: var(--color-accent);
		border-radius: 16px;
		text-align: center;
		padding: 24px;
		img {
			margin-bottom: 24px;
		}
		p {
			color: #FFF;
			font-size: 1.25rem;
		}
	}
	.more {
		text-align: center;
		font-size: 4.5rem;
		line-height: 1;
		padding: 12px;
		color: var(--color-primary);
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}

@include media-breakpoint-down(md) {
	.cols-2-icons {
		.icons-wrapper {
			padding: 0 15%;
			margin-bottom: -120px;
			position: relative;
		}
	}
}
//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.cols-2-icons {
		text-align: left;
		.icons-wrapper {
			display: flex;
			align-items: stretch;
			margin-left: 50px;
			margin-right: 50px;
			margin-bottom: -165px;
			
		}
		.icons-inline {
			width: 40%;
			flex-shrink: 0;
			box-shadow: 0px 46px 36px -31px rgba(0,0,0,0.23);
		}
		.more {
			display: flex;
			align-items: center;
			color: #FFF;
			font-weight: 700;
			padding: 24px;
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.cols-2-icons {
		h2 {
			font-size: 2.5rem;
			margin-bottom: 0;
		}
		
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}