//Mobile------------------------------------------------------------------------------------------------------
.hero-video {
	position: relative;
	.video-wrapper {
		height: 100vh;
		&:after {
			content: '';
			display: block;
			position: absolute;
			inset: 0;
			background: linear-gradient(180deg, rgba(0, 46, 56, 0.6) 0%, rgba(0, 130, 158, 0.6) 100%);
		}
	}
	video {
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.hero-content {
		position: absolute;
		inset: 0;
		padding: 32px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.logo-video {
		width: 200px;
		margin-bottom: 24px;
		img {
			width: 100%;
		}
	}
	.text-wrap {
		max-width: 740px;
		h2 {
			color: #FFF;
			font-size: 2rem;
			margin-bottom: 24px;
		}
	}
	.scroll-down {
		width: 60px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #FFF;
		border-radius: 16px;
		.icon {
			fill : var(--color-accent)
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.hero-video {
		.hero-content {
			padding: 64px;
		}
		.logo-video {
			width: 273px;
		}
		.text-wrap {
			h2 {
				font-size: 3.5rem;
				line-height: 4.125rem;
			}
		}
		.scroll-down {
			width: 72px;
			height: 72px;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}