//Mobile------------------------------------------------------------------------------------------------------
.schede {
	.sectionHeading__title {
		color: var(--color-primary);
	}
	.sectionHeading__text {
		font-size: 1.125rem;
	}
	.tabs-group {
		border-color: var(--color-accent);
		border-radius: 20px;
		.is-active {
			background-color: var(--color-accent);
		}
		.tabs__button {
			color: var(--color-accent);
			border-radius: 16px;
		}
		
	}
	.full-img {
			width: 100%;
			margin-bottom: 24px;
		}
	h3 {
		font-size: 1.5rem;
		color: var(--color-text);
		margin-bottom: 24px;
	}
	p {
		font-size: 1rem;
		
	}
	ul {
		padding-left: 1em;
		font-size: 1rem;
		li {list-style-type: disc;}
	}
	.img-full-wrapper {
		position: relative;
		img {
			width: 100%;
		}
		&.z-1 {
			margin-bottom: -100px;
		}
	}
	.img-full {
		width: 100%;
	}
	.top-img {
		position: absolute;
		top: 20px;
		left: 0;
	}
	.pl-md-40 {
		padding-top: 80px;
	}
	.pr-md-40 {
		padding-bottom: 40px;
	}
	.img-fullscreen {
		position: relative;
		.bg-light {
			position: absolute;
			top: 0;
			width: 100%;
			height: 50%;
		}
		img {
			position: relative;
			z-index: 1;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.schede {
		.full-img {
			margin-bottom: 0;
		}
		.pl-md-40 {
			padding-top: 0;
			padding-left: 40px;
		}
		.pr-md-40 {
			padding-top: 0;
			padding-right: 40px;
		}
		
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.schede {
		.d-lg-block {
			display: block !important;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}