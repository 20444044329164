//Mobile------------------------------------------------------------------------------------------------------
.slider-2-cols {
	@extend .schede;
	.circle {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		border: 6px solid var(--color-primary);
		border-radius: 50px;
		margin-bottom: 24px;
		&:after {
			content: '';
			display: block;
			border-radius: 50px;
			width: 12px;
			height: 12px;
			background-color: var(--color-accent);

		}
	}
	.rounded-img-wrapper {
		position: relative;
		overflow: hidden;
		padding-top: 100%;
		border-radius: 16px;
		img {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	h3 {
		font-size: 1.5rem;
	}
	ul {
		padding-left: 1.2em;
	}
	.carousel-wrapper {
		padding:32px;
		padding-bottom: 0;
		height: 100%;
	}
	.sectionSlider {
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: space-between;
	}
	.swiper-notification {
		display: none;
	}
	.sectionSlider .nav.-slider .nav__item {
		border-radius: 8px;
		background-color: var(--color-accent);
		svg {
			stroke : #FFF
		}
		&[aria-disabled="true"] {
			background-color: #CED4DA;
			svg {
				stroke: #5C6F82;
			}
		}
	}
	.full-vertical-image {
		position: relative;
		border-radius: 16px;
		overflow: hidden;
		margin-top: 24px;
		img {
			width: 100%;
		}
	}
	
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.slider-2-cols{
		.text-wrapper {
			padding: 48px 96px 48px 0;
		}
		.full-vertical-image {
			margin-top: 0;
			height: 100%;
			img {
				position: absolute;
				inset: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}