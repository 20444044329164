//Mobile------------------------------------------------------------------------------------------------------
.faq {
	@extend .slider-2-cols;
	.accordion {
		border: none;
	}
	.accordion__item {
		box-shadow: 0px 9px 15px -2px rgba(0,0,0,0.2);
		border-radius: 12px;
		padding: 24px;
		&.is-active {
			.accordion__button {
				.icon {
					transform: rotate(180deg);
				}
			}
		}
	}
	.accordion__button {
		padding: 0;
		button {
			font-size: 1.25rem;
			font-weight: 700;
			color: var(--color-primary);
			text-align: left;
		}
		.icon {
			fill: var(--color-accent);
			transition: all 0.3s;
			transform-origin: center;
			margin-left: 24px;
			flex-shrink: 0;
		}
	}
	.accordion__content__inner {
		padding: 24px 0;
		text-align: left;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}