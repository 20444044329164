/* ------------------------------------------------------------------

  01. Variables
  02. Reveal animations

-------------------------------------------------------------------- */
.ratio {
  position: relative;
  display: block;
  overflow: hidden;
}

.ratio::before {
  display: block;
  width: 100%;
  content: "";
}

.ratio-1\:1::before {
  padding-bottom: 100%;
}

.ratio-2\:1::before {
  padding-bottom: 50%;
}

.ratio-2\:3::before {
  padding-bottom: 150%;
}

.ratio-3\:2::before {
  padding-bottom: 66.66667%;
}

.ratio-3\:4::before {
  padding-bottom: 133.33333%;
}

.ratio-4\:3::before {
  padding-bottom: 75%;
}

.ratio-5\:4::before {
  padding-bottom: 80%;
}

.ratio-8\:5::before {
  padding-bottom: 62.5%;
}

.ratio-48\:50::before {
  padding-bottom: 104.16667%;
}

.ratio-62\:60::before {
  padding-bottom: 96.77419%;
}

.ratio-64\:55::before {
  padding-bottom: 85.9375%;
}

.ratio-63\:60::before {
  padding-bottom: 95.2381%;
}

.ratio-93\:53::before {
  padding-bottom: 56.98925%;
}

.ratio-41\:35::before {
  padding-bottom: 85.36585%;
}

.ratio-41\:45::before {
  padding-bottom: 109.7561%;
}

.ratio-42\:50::before {
  padding-bottom: 119.04762%;
}

.ratio-55\:40::before {
  padding-bottom: 72.72727%;
}

.ratio-27\:30::before {
  padding-bottom: 111.11111%;
}

.ratio-27\:33::before {
  padding-bottom: 122.22222%;
}

.ratio-37\:40::before {
  padding-bottom: 108.10811%;
}

.ratio-57\:60::before {
  padding-bottom: 105.26316%;
}

.ratio-57\:39::before {
  padding-bottom: 68.42105%;
}

.ratio-37\:25::before {
  padding-bottom: 67.56757%;
}

.ratio-37\:50::before {
  padding-bottom: 135.13514%;
}

.ratio-97\:55::before {
  padding-bottom: 56.70103%;
}

.ratio-77\:60::before {
  padding-bottom: 77.92208%;
}

.ratio-77\:50::before {
  padding-bottom: 64.93506%;
}

.ratio-129\:112::before {
  padding-bottom: 86.82171%;
}

.ratio-16\:9::before {
  padding-bottom: 56.25%;
}

/*--------------------------------------------------
	01. Variables
---------------------------------------------------*/
@font-face {
  font-family: 'Sofia Pro';
  font-weight: 400;
  src: url("../fonts/font/Sofia%20Pro%20Regular%20Az.woff2") format("woff2");
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 500;
  src: url("../fonts/font/Sofia%20Pro%20Medium%20Az.woff2") format("woff2");
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 600;
  src: url("../fonts/font/Sofia%20Pro%20Semi%20Bold%20Az.woff2") format("woff2");
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 700;
  src: url("../fonts/font/Sofia%20Pro%20Bold%20Az.woff2") format("woff2");
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 800;
  src: url("../fonts/font/Sofia%20Pro%20Black%20Az.woff2") format("woff2");
}

:root {
  --text-xs:   0.6875rem;
  --text-sm:   0.8125rem;
  --text-base: 0.875rem;
  --text-lg:   0.9375rem;
  --text-xl:   1.125rem;
  --text-2xl:  1.25rem;
  --text-3xl:  2.5rem;
  --text-4xl:  3.75rem;
  --text-5xl:  5.3125rem;
  --text-25:  1.5625rem;
  --text-22:  1.375rem;
  --text-32:  2rem;
  --text-50:  3.125rem;
  --text-26:  1.625rem;
  --text-16:  1rem;
  --text-30:  1.875rem;
}

:root {
  --preloader-image: url('https://creativelayers.net/themes/sassio-html/img/general/logo-light.svg');
}

:root {
  --accent-color: #0044EB;
  --light-accent-color: #E5ECFD;
  --dark-accent-color: #3B59D4;
  --teal-color: #39C1FF;
  --light-purple-color: #F0EFFF;
  --purple-color: #615EF6;
  --purple-2-color: #6B25C5;
  --purple-3-color: #5A13B5;
  --darker-blue-color: #0E1376;
  --dark-blue-color: #1732A4;
  --blue-color: #867BF2;
  --light-blue-color: #E7E5FC;
  --blue-2-color: #4C7FF1;
  --red-2-color: #E13F52;
  --red-color: #FF0071;
  --red-bright-color: #FD3600;
  --light-red-color: #FFEDF5;
  --light-green-color-full: #26D9DA;
  --green-color: #33C89A;
  --dark-green-color: #008181;
  --light-green-color: #D3F8F8;
  --yellow-color: #EC9B00;
  --light-yellow-color: #FFF3DC;
  --light-orange-color: #FFEDDF;
  --orange-color: #F86B45;
  --orange-2-color: #FE754F;
  --orange-3-color: #EB6245;
  --bg-light-1: #f5f6f8;
  --bg-light-2: #FAFAFE;
  --bg-light-3: #EEEEEE;
  --bg-light-4: #F5F7FE;
  --bg-light-5: #F7F9FC;
  --bg-light-6: #F5F4F2;
  --bg-dark-1: #1A3454;
  --bg-dark-2: #01213A;
  --bg-dark-3: #312950;
  --bg-dark-4: #0E1376;
  --bg-dark-5: #281D60;
  --bg-dark-6: #322867;
  --bg-dark-7: #2D4F93;
  --bg-dark-8: #21286A;
  --bg-dark-9: #11243E;
  --bg-dark-10: #1D1E6D;
  --bg-dark-11: #17262F;
  --bg-dark-12: #1D144E;
  --bg-dark-13: #3E3470;
  --text-dark-1: #1A3454;
  --font-light: rgba(white, 0.6);
  --font-dark: #404046;
}

:root {
  --font-primary: "Sofia Pro", sans-serif;
  --font-secondary: "Sofia Pro", sans-serif;
  --button-border-radius: 200px;
}

/*--------------------------------------------------
	02. Reveal animations
---------------------------------------------------*/
[data-anim-wrap] {
  pointer-events: none;
}

[data-anim-wrap].animated {
  pointer-events: auto;
}

[data-anim*='cover-white']::after,
[data-anim-child*='cover-white']::after {
  background-color: white;
}

[data-anim*='cover-black']::after,
[data-anim-child*='cover-black']::after {
  background-color: black;
}

[data-anim*='cover-light-1']::after,
[data-anim-child*='cover-light-1']::after {
  background-color: var(--bg-light-1);
}

[data-anim*='cover-light-2']::after,
[data-anim-child*='cover-light-2']::after {
  background-color: var(--bg-light-2);
}

[data-anim*='cover-dark-1']::after,
[data-anim-child*='cover-dark-1']::after {
  background-color: var(--bg-dark-1);
}

[data-anim*='cover-dark-2']::after,
[data-anim-child*='cover-dark-2']::after {
  background-color: var(--bg-dark-2);
}

[data-anim*='cover-accent']::after,
[data-anim-child*='cover-accent']::after {
  background-color: var(--accent-color);
}

@-webkit-keyframes reveal {
  100% {
    opacity: 1;
  }
}

@keyframes reveal {
  100% {
    opacity: 1;
  }
}

[data-anim^='cover-right'],
[data-anim-child^='cover-right'] {
  display: block;
  position: relative;
}

[data-anim^='cover-right']::after,
[data-anim-child^='cover-right']::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  transform: scaleX(0);
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
          animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

[data-anim^='cover-right'] > *,
[data-anim-child^='cover-right'] > * {
  opacity: 0;
  pointer-events: none;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
  -webkit-animation-duration: 20ms;
          animation-duration: 20ms;
  -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
          animation-timing-function: cubic-bezier(0, 0, 1, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

[data-anim^='cover-right'].is-in-view::after,
[data-anim-child^='cover-right'].is-in-view::after {
  -webkit-animation-name: coverRight;
          animation-name: coverRight;
}

[data-anim^='cover-right'].is-in-view > *,
[data-anim-child^='cover-right'].is-in-view > * {
  -webkit-animation-name: reveal;
          animation-name: reveal;
  pointer-events: auto;
}

[data-anim^='cover-up'],
[data-anim-child^='cover-up'] {
  display: block;
  position: relative;
}

[data-anim^='cover-up']::after,
[data-anim-child^='cover-up']::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  transform: scaleY(0);
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
          animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

[data-anim^='cover-up'] > *,
[data-anim-child^='cover-up'] > * {
  opacity: 0;
  pointer-events: none;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
  -webkit-animation-duration: 20ms;
          animation-duration: 20ms;
  -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
          animation-timing-function: cubic-bezier(0, 0, 1, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

[data-anim^='cover-up'].is-in-view::after,
[data-anim-child^='cover-up'].is-in-view::after {
  -webkit-animation-name: coverUp;
          animation-name: coverUp;
}

[data-anim^='cover-up'].is-in-view > *,
[data-anim-child^='cover-up'].is-in-view > * {
  -webkit-animation-name: reveal;
          animation-name: reveal;
  pointer-events: auto;
}

@-webkit-keyframes coverRight {
  0% {
    transform-origin: 0 50%;
    transform: scaleX(0);
  }
  42% {
    transform-origin: 0 50%;
    transform: scaleX(1);
  }
  58% {
    transform-origin: 100% 50%;
    transform: scaleX(1);
  }
  100% {
    transform-origin: 100% 50%;
    transform: scaleX(0);
  }
}

@keyframes coverRight {
  0% {
    transform-origin: 0 50%;
    transform: scaleX(0);
  }
  42% {
    transform-origin: 0 50%;
    transform: scaleX(1);
  }
  58% {
    transform-origin: 100% 50%;
    transform: scaleX(1);
  }
  100% {
    transform-origin: 100% 50%;
    transform: scaleX(0);
  }
}

@-webkit-keyframes coverUp {
  0% {
    transform-origin: 50% 100%;
    transform: scaleY(0);
  }
  42% {
    transform-origin: 50% 100%;
    transform: scaleY(1);
  }
  58% {
    transform-origin: 50% 0;
    transform: scaleY(1);
  }
  100% {
    transform-origin: 50% 0;
    transform: scaleY(0);
  }
}

@keyframes coverUp {
  0% {
    transform-origin: 50% 100%;
    transform: scaleY(0);
  }
  42% {
    transform-origin: 50% 100%;
    transform: scaleY(1);
  }
  58% {
    transform-origin: 50% 0;
    transform: scaleY(1);
  }
  100% {
    transform-origin: 50% 0;
    transform: scaleY(0);
  }
}

[data-anim^='img-fade'],
[data-anim-child^='img-fade'] {
  overflow: hidden;
  display: block;
  position: relative;
}

[data-anim^='img-fade'] > *,
[data-anim-child^='img-fade'] > * {
  pointer-events: none;
  opacity: 0;
  transform: scale(1.5);
  transition-property: opacity, transform;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

[data-anim^='img-fade'].is-in-view > *,
[data-anim-child^='img-fade'].is-in-view > * {
  pointer-events: auto;
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

[data-anim^='img-right'],
[data-anim-child^='img-right'] {
  overflow: hidden;
  display: block;
  position: relative;
}

[data-anim^='img-right']::after,
[data-anim-child^='img-right']::after {
  content: "";
  position: absolute;
  top: -0.0625rem;
  left: -0.0625rem;
  right: 0;
  z-index: 20;
  height: calc(100% + 0.125rem);
  width: calc(100% + 0.125rem);
  transform-origin: right;
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
          animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

[data-anim^='img-right'] > *,
[data-anim-child^='img-right'] > * {
  transform-origin: center;
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
          animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

[data-anim^='img-right'].is-in-view::after,
[data-anim-child^='img-right'].is-in-view::after {
  -webkit-animation-name: imgRight;
          animation-name: imgRight;
}

[data-anim^='img-right'].is-in-view > *,
[data-anim-child^='img-right'].is-in-view > * {
  -webkit-animation-name: imgReveal;
          animation-name: imgReveal;
}

@-webkit-keyframes imgRight {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes imgRight {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@-webkit-keyframes imgReveal {
  0% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1.01);
  }
}

@keyframes imgReveal {
  0% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1.01);
  }
}

[data-anim^='slide-'],
[data-anim-child^='slide-'] {
  opacity: 0;
  transition-property: opacity, transform;
  pointer-events: none;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

[data-anim^='slide-'].is-in-view,
[data-anim-child^='slide-'].is-in-view {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  pointer-events: auto;
}

[data-anim^='slide-up'],
[data-anim-child^='slide-up'] {
  transform: translate3d(0, 2.5rem, 0);
}

[data-anim^='slide-down'],
[data-anim-child^='slide-down'] {
  transform: translate3d(0, -2.5rem, 0);
}

[data-anim^='slide-right'],
[data-anim-child^='slide-right'] {
  transform: translate3d(-2.5rem, 0, 0);
}

[data-anim^='slide-left'],
[data-anim-child^='slide-left'] {
  transform: translate3d(2.5rem, 0, 0);
}

[data-anim^='fade'],
[data-anim-child^='fade'] {
  opacity: 0;
  transition-property: opacity;
  pointer-events: none;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

[data-anim^='fade'].is-in-view,
[data-anim-child^='fade'].is-in-view {
  opacity: 1;
  pointer-events: auto;
}

[data-anim^='scale'],
[data-anim-child^='scale'] {
  opacity: 0;
  transform: scale(0.9) translateY(60px);
  transition-property: opacity, transform;
  pointer-events: none;
  transition-duration: 1.2s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

[data-anim^='scale'].is-in-view,
[data-anim-child^='scale'].is-in-view {
  transform: none;
  opacity: 1;
  pointer-events: auto;
}

[data-anim^='fancy-1'],
[data-anim-child^='fancy-1'] {
  opacity: 0;
  transform: translateY(120px) rotateY(20deg);
  transform-origin: left;
  transition-property: opacity, transform;
  pointer-events: none;
  transition-duration: 1.2s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

[data-anim^='fancy-1'].is-in-view,
[data-anim-child^='fancy-1'].is-in-view {
  transform: none;
  opacity: 1;
  pointer-events: auto;
}

[data-anim^='fancy-2'],
[data-anim-child^='fancy-2'] {
  opacity: 0;
  transform: translateY(120px) rotateY(40deg) scaleX(1.3);
  transform-origin: left;
  transition-property: opacity, transform;
  pointer-events: none;
  transition-duration: 1.2s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

[data-anim^='fancy-2'].is-in-view,
[data-anim-child^='fancy-2'].is-in-view {
  transform: none;
  opacity: 1;
  pointer-events: auto;
}

.float-animation {
  -webkit-animation: floatOne 1s ease-out infinite alternate;
          animation: floatOne 1s ease-out infinite alternate;
}

@-webkit-keyframes floatOne {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-3%);
  }
}

@keyframes floatOne {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-3%);
  }
}

.float-animation-sm {
  -webkit-animation: floatTwo 1s ease-out infinite alternate;
          animation: floatTwo 1s ease-out infinite alternate;
}

@-webkit-keyframes floatTwo {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}

@keyframes floatTwo {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}

.pulse-animation {
  position: relative;
  z-index: 1;
}

.pulse-animation::after {
  z-index: -1;
  content: "";
  position: absolute;
  border-radius: 100%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  -webkit-animation: video_btn_pulse 3.0s infinite;
          animation: video_btn_pulse 3.0s infinite;
  -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

@-webkit-keyframes video_btn_pulse {
  0% {
    opacity: 0.6;
    transform: scale(1);
  }
  75% {
    opacity: 0;
    transform: scale(1.6);
  }
  100% {
    opacity: 0;
    transform: scale(1.6);
  }
}

@keyframes video_btn_pulse {
  0% {
    opacity: 0.6;
    transform: scale(1);
  }
  75% {
    opacity: 0;
    transform: scale(1.6);
  }
  100% {
    opacity: 0;
    transform: scale(1.6);
  }
}

[data-anim*="delay-1"],
[data-anim-child*="delay-1"] {
  transition-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

[data-anim*="delay-1"]::after,
[data-anim-child*="delay-1"]::after {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

[data-anim*="delay-1"] > *,
[data-anim-child*="delay-1"] > * {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

[data-anim*="delay-2"],
[data-anim-child*="delay-2"] {
  transition-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

[data-anim*="delay-2"]::after,
[data-anim-child*="delay-2"]::after {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

[data-anim*="delay-2"] > *,
[data-anim-child*="delay-2"] > * {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

[data-anim*="delay-3"],
[data-anim-child*="delay-3"] {
  transition-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

[data-anim*="delay-3"]::after,
[data-anim-child*="delay-3"]::after {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

[data-anim*="delay-3"] > *,
[data-anim-child*="delay-3"] > * {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

[data-anim*="delay-4"],
[data-anim-child*="delay-4"] {
  transition-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

[data-anim*="delay-4"]::after,
[data-anim-child*="delay-4"]::after {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

[data-anim*="delay-4"] > *,
[data-anim-child*="delay-4"] > * {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

[data-anim*="delay-5"],
[data-anim-child*="delay-5"] {
  transition-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

[data-anim*="delay-5"]::after,
[data-anim-child*="delay-5"]::after {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

[data-anim*="delay-5"] > *,
[data-anim-child*="delay-5"] > * {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

[data-anim*="delay-6"],
[data-anim-child*="delay-6"] {
  transition-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

[data-anim*="delay-6"]::after,
[data-anim-child*="delay-6"]::after {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

[data-anim*="delay-6"] > *,
[data-anim-child*="delay-6"] > * {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

[data-anim*="delay-7"],
[data-anim-child*="delay-7"] {
  transition-delay: 0.7s;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}

[data-anim*="delay-7"]::after,
[data-anim-child*="delay-7"]::after {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}

[data-anim*="delay-7"] > *,
[data-anim-child*="delay-7"] > * {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}

[data-anim*="delay-8"],
[data-anim-child*="delay-8"] {
  transition-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

[data-anim*="delay-8"]::after,
[data-anim-child*="delay-8"]::after {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

[data-anim*="delay-8"] > *,
[data-anim-child*="delay-8"] > * {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

[data-anim*="delay-9"],
[data-anim-child*="delay-9"] {
  transition-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

[data-anim*="delay-9"]::after,
[data-anim-child*="delay-9"]::after {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

[data-anim*="delay-9"] > *,
[data-anim-child*="delay-9"] > * {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

[data-anim*="delay-10"],
[data-anim-child*="delay-10"] {
  transition-delay: 1s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

[data-anim*="delay-10"]::after,
[data-anim-child*="delay-10"]::after {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

[data-anim*="delay-10"] > *,
[data-anim-child*="delay-10"] > * {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

[data-anim*="delay-11"],
[data-anim-child*="delay-11"] {
  transition-delay: 1.1s;
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
}

[data-anim*="delay-11"]::after,
[data-anim-child*="delay-11"]::after {
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
}

[data-anim*="delay-11"] > *,
[data-anim-child*="delay-11"] > * {
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
}

[data-anim*="delay-12"],
[data-anim-child*="delay-12"] {
  transition-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

[data-anim*="delay-12"]::after,
[data-anim-child*="delay-12"]::after {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

[data-anim*="delay-12"] > *,
[data-anim-child*="delay-12"] > * {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

[data-anim*="delay-13"],
[data-anim-child*="delay-13"] {
  transition-delay: 1.3s;
  -webkit-animation-delay: 1.3s;
          animation-delay: 1.3s;
}

[data-anim*="delay-13"]::after,
[data-anim-child*="delay-13"]::after {
  -webkit-animation-delay: 1.3s;
          animation-delay: 1.3s;
}

[data-anim*="delay-13"] > *,
[data-anim-child*="delay-13"] > * {
  -webkit-animation-delay: 1.3s;
          animation-delay: 1.3s;
}

[data-anim*="delay-14"],
[data-anim-child*="delay-14"] {
  transition-delay: 1.4s;
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}

[data-anim*="delay-14"]::after,
[data-anim-child*="delay-14"]::after {
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}

[data-anim*="delay-14"] > *,
[data-anim-child*="delay-14"] > * {
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}

[data-anim*="delay-15"],
[data-anim-child*="delay-15"] {
  transition-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

[data-anim*="delay-15"]::after,
[data-anim-child*="delay-15"]::after {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

[data-anim*="delay-15"] > *,
[data-anim-child*="delay-15"] > * {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

[data-anim*="delay-16"],
[data-anim-child*="delay-16"] {
  transition-delay: 1.6s;
  -webkit-animation-delay: 1.6s;
          animation-delay: 1.6s;
}

[data-anim*="delay-16"]::after,
[data-anim-child*="delay-16"]::after {
  -webkit-animation-delay: 1.6s;
          animation-delay: 1.6s;
}

[data-anim*="delay-16"] > *,
[data-anim-child*="delay-16"] > * {
  -webkit-animation-delay: 1.6s;
          animation-delay: 1.6s;
}

[data-anim*="delay-17"],
[data-anim-child*="delay-17"] {
  transition-delay: 1.7s;
  -webkit-animation-delay: 1.7s;
          animation-delay: 1.7s;
}

[data-anim*="delay-17"]::after,
[data-anim-child*="delay-17"]::after {
  -webkit-animation-delay: 1.7s;
          animation-delay: 1.7s;
}

[data-anim*="delay-17"] > *,
[data-anim-child*="delay-17"] > * {
  -webkit-animation-delay: 1.7s;
          animation-delay: 1.7s;
}

[data-anim*="delay-18"],
[data-anim-child*="delay-18"] {
  transition-delay: 1.8s;
  -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s;
}

[data-anim*="delay-18"]::after,
[data-anim-child*="delay-18"]::after {
  -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s;
}

[data-anim*="delay-18"] > *,
[data-anim-child*="delay-18"] > * {
  -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s;
}

[data-anim*="delay-19"],
[data-anim-child*="delay-19"] {
  transition-delay: 1.9s;
  -webkit-animation-delay: 1.9s;
          animation-delay: 1.9s;
}

[data-anim*="delay-19"]::after,
[data-anim-child*="delay-19"]::after {
  -webkit-animation-delay: 1.9s;
          animation-delay: 1.9s;
}

[data-anim*="delay-19"] > *,
[data-anim-child*="delay-19"] > * {
  -webkit-animation-delay: 1.9s;
          animation-delay: 1.9s;
}

[data-anim*="delay-20"],
[data-anim-child*="delay-20"] {
  transition-delay: 2s;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

[data-anim*="delay-20"]::after,
[data-anim-child*="delay-20"]::after {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

[data-anim*="delay-20"] > *,
[data-anim-child*="delay-20"] > * {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

/*--------------------------------------------------
	03. Base
---------------------------------------------------*/
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-primary);
  min-height: 100%;
  color: var(--font-dark);
  font-size: 16px;
  line-height: 1;
}

body {
  overflow-x: hidden;
  padding: 0;
  font-family: var(--font-primary);
  width: 100%;
  max-width: 100%;
  background-color: white;
  color: var(--font-dark);
  font-size: 0.875rem;
  line-height: 1.7;
}

@media (min-width: 1200px) {
  .container {
    max-width: 75rem;
  }
}

@media (max-width: 575px) {
  .container {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.container.-sm {
  max-width: 63.75rem;
}

@media (max-width: 1199px) {
  .container.-sm {
    max-width: 960px !important;
  }
}

@media (max-width: 991px) {
  .container.-sm {
    max-width: 720px !important;
  }
}

@media (max-width: 767px) {
  .container.-sm {
    max-width: 540px !important;
  }
}

@media (max-width: 575px) {
  .container.-sm {
    max-width: 100% !important;
  }
}

::-moz-selection {
  color: white;
  background-color: var(--accent-color);
  text-shadow: none;
}

::selection {
  color: white;
  background-color: var(--accent-color);
  text-shadow: none;
}

main {
  overflow-x: hidden;
}

a {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  transition: color 0.2s ease-in-out;
}

a:hover {
  text-decoration: none;
  color: var(--accent-color);
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.625rem;
}

.nice-select {
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-weight: normal;
  font-size: 0.875rem;
  height: 2.5rem;
  line-height: 2.25rem;
  outline: none;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
  width: auto;
}

.nice-select:hover {
  border-color: #dbdbdb;
}

.nice-select:active,
.nice-select.open,
.nice-select:focus {
  border-color: black;
}

.nice-select:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: '';
  display: block;
  height: 0.3125rem;
  margin-top: -rem(4px);
  pointer-events: none;
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
  width: 0.3125rem;
}

.nice-select.open:after {
  transform: rotate(-135deg);
}

.nice-select.open .nice-select-dropdown {
  opacity: 1;
  pointer-events: auto;
  transform: scaleY(1) translateY(0);
}

.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}

.nice-select.disabled:after {
  border-color: #cccccc;
}

.nice-select.wide {
  width: 100%;
}

.nice-select.wide .nice-select-dropdown {
  left: 0 !important;
  right: 0 !important;
}

.nice-select.right {
  float: right;
}

.nice-select.right .nice-select-dropdown {
  left: auto;
  right: 0;
}

.nice-select.small {
  font-size: 0.75rem;
  height: 2.25rem;
  line-height: 2.125rem;
}

.nice-select.small:after {
  height: 0.25rem;
  width: 0.25rem;
}

.nice-select.small .option {
  line-height: 2.125rem;
  min-height: 2.125rem;
}

.nice-select .nice-select-dropdown {
  margin-top: 0.0625rem;
  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  transform-origin: top;
  transform: scaleY(0.8) translateY(0);
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
  opacity: 0;
}

.nice-select .list {
  overflow: hidden;
  padding: 0;
  max-height: 13.125rem;
  overflow-y: auto;
  padding: 12px 0;
}

.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}

.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 2.5rem;
  list-style: none;
  outline: none;
  margin-left: 1.875rem;
  margin-right: 1.875rem;
  text-align: left;
  transition: all 0.2s;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.nice-select .option:last-child {
  border-bottom: none;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #f6f6f6;
}

.nice-select .option.selected {
  font-weight: bold;
}

.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .nice-select .nice-select-dropdown {
  display: none;
}

.no-csspointerevents .nice-select.open .nice-select-dropdown {
  display: block;
}

.nice-select .list::-webkit-scrollbar {
  width: 0;
}

.nice-select .has-multiple {
  white-space: inherit;
  height: auto;
  padding: 7px 0.75rem;
  min-height: 2.25rem;
  line-height: 1.375rem;
}

.nice-select .has-multiple span.current {
  border: 1px solid #CCC;
  background: #EEE;
  padding: 0 0.625rem;
  display: inline-block;
  line-height: 1.5rem;
  font-size: 0.875rem;
  margin-bottom: 0.1875rem;
  margin-right: 0.1875rem;
}

.nice-select .has-multiple .multiple-options {
  display: block;
  line-height: 1.5rem;
  padding: 0;
}

.nice-select .nice-select-search-box {
  box-sizing: border-box;
  width: 100%;
  padding: 0 1.875rem;
  margin-bottom: 0.625rem;
  pointer-events: none;
}

.nice-select .nice-select-search {
  box-sizing: border-box;
  background-color: #F9F6F6;
  border: none !important;
  color: #444;
  display: inline-block;
  vertical-align: middle;
  padding: 16px 1.25rem;
  margin-top: 1.25rem;
  width: 100%;
  min-height: 2.25rem;
  line-height: 1.375rem;
  height: auto;
  outline: 0 !important;
  font-size: 0.875rem;
}

/*--------------------------------------------------
	04. Buttons
---------------------------------------------------*/
button {
  padding: unset;
  margin: unset;
  border: unset;
  border-radius: unset;
  background-color: unset;
}

button:focus {
  outline: 0;
}

.button {
  position: relative;
  display: inline-block;
  text-decoration: none;
  border: 0;
  font-size: 0.875rem;
  vertical-align: middle;
  line-height: 1;
  font-weight: 400;
  text-align: center;
  border-radius: var(--button-border-radius);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.button.-sm {
  min-width: 5.625rem;
  padding: 0.625rem 1.75rem;
  font-size: 0.9375rem;
  line-height: 1;
}

.button.-md {
  min-width: 11.25rem;
  padding: 1.0625rem 2rem;
  font-size: 0.9375rem;
  line-height: 1;
}

.button.-uppercase {
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.button.-rounded-base {
  border-radius: 0.5rem !important;
}

.button.-light-purple {
  border: 2px solid transparent;
  background-color: var(--light-purple-color) !important;
}

.button.-light-purple:hover {
  background-color: var(--purple-color) !important;
  color: white !important;
}

.button.-red-2 {
  border: 2px solid transparent;
  background-color: var(--red-2-color) !important;
}

.button.-red-2:hover {
  border: 2px solid var(--red-2-color);
  background-color: white !important;
  color: var(--red-2-color) !important;
}

.button.-dark-8 {
  border: 2px solid transparent;
  background-color: var(--bg-dark-8) !important;
}

.button.-dark-8:hover {
  border: 2px solid var(--bg-dark-8);
  background-color: white !important;
  color: var(--bg-dark-8) !important;
}

.button.-white-20 {
  border: 2px solid transparent;
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.button.-white-20:hover {
  background-color: white !important;
  color: var(--bg-dark-1) !important;
}

.button.-dark-10 {
  border: 2px solid transparent;
  background-color: var(--bg-dark-10) !important;
}

.button.-dark-10:hover {
  border: 2px solid var(--bg-dark-10);
  background-color: white !important;
  color: var(--bg-dark-10) !important;
}

.button.-dark-1 {
  border: 2px solid transparent;
  background-color: var(--bg-dark-1) !important;
}

.button.-dark-1:hover {
  border: 2px solid var(--bg-dark-1);
  background-color: white !important;
  color: var(--bg-dark-1) !important;
}

.button.-dark-2 {
  border: 2px solid transparent;
  background-color: var(--bg-dark-2) !important;
}

.button.-dark-2:hover {
  border: 2px solid var(--bg-dark-2);
  background-color: white !important;
  color: var(--bg-dark-2) !important;
}

.button.-light-4 {
  background-color: var(--bg-light-4) !important;
}

.button.-light-4:hover {
  background-color: var(--accent-color) !important;
  color: white !important;
}

.button.-red-bright {
  border: 2px solid transparent;
  background-color: var(--red-bright-color) !important;
}

.button.-red-bright:hover {
  border: 2px solid var(--red-bright-color);
  background-color: white !important;
  color: var(--red-bright-color) !important;
}

.button.-blue-dark {
  border: 2px solid transparent;
  background-color: var(--dark-blue-color) !important;
}

.button.-blue-dark:hover {
  border: 2px solid var(--dark-blue-color);
  background-color: white !important;
  color: var(--dark-blue-color) !important;
}

.button.-blue-light {
  border: 2px solid transparent;
  background-color: var(--light-blue-color) !important;
}

.button.-blue-light:hover {
  border: 2px solid var(--dark-blue-color);
  background-color: var(--dark-blue-color) !important;
  color: white !important;
}

.button.-orange {
  border: 2px solid transparent;
  background-color: var(--orange-color) !important;
}

.button.-orange:hover {
  border: 2px solid var(--orange-color);
  background-color: white !important;
  color: var(--orange-color) !important;
}

.button.-orange-2 {
  border: 2px solid transparent;
  background-color: var(--orange-2-color) !important;
}

.button.-orange-2:hover {
  border: 2px solid var(--orange-2-color);
  background-color: white !important;
  color: var(--orange-2-color) !important;
}

.button.-orange-3 {
  border: 2px solid transparent;
  background-color: var(--orange-3-color) !important;
}

.button.-orange-3:hover {
  border: 2px solid var(--orange-3-color);
  background-color: white !important;
  color: var(--orange-3-color) !important;
}

.button.-outline-orange {
  border: 2px solid var(--orange-color);
  background-color: transparent !important;
}

.button.-outline-orange:hover {
  border: 2px solid transparent;
  background-color: var(--orange-color) !important;
  color: white !important;
}

.button.-dark-4 {
  border: 2px solid transparent;
  background-color: var(--bg-dark-4) !important;
}

.button.-dark-4:hover {
  border: 2px solid var(--bg-dark-4);
  background-color: white !important;
  color: var(--bg-dark-4) !important;
}

.button.-dark-7 {
  border: 2px solid transparent;
  background-color: var(--bg-dark-7) !important;
}

.button.-dark-7:hover {
  border: 2px solid var(--bg-dark-7);
  background-color: white !important;
  color: var(--bg-dark-7) !important;
}

.button.-purple {
  border: 2px solid transparent;
  background-color: var(--purple-color) !important;
}

.button.-purple:hover {
  border: 2px solid var(--purple-color);
  background-color: white !important;
  color: var(--text-dark-1) !important;
}

.button.-purple-2 {
  border: 2px solid transparent;
  background-color: var(--purple-2-color) !important;
}

.button.-purple-2:hover {
  border: 2px solid var(--purple-2-color);
  background-color: white !important;
  color: var(--text-dark-1) !important;
}

.button.-purple-3 {
  border: 2px solid transparent;
  background-color: var(--purple-3-color) !important;
}

.button.-purple-3:hover {
  border: 2px solid var(--purple-3-color);
  background-color: white !important;
  color: var(--text-dark-1) !important;
}

.button.-dark-blue {
  border: 2px solid transparent;
  background-color: var(--dark-blue-color) !important;
}

.button.-dark-blue:hover {
  border: 2px solid var(--dark-blue-color);
  background-color: white !important;
  color: var(--dark-blue-color) !important;
}

.button.-blue-2 {
  border: 2px solid transparent;
  background-color: var(--blue-2-color) !important;
}

.button.-blue-2:hover {
  border: 2px solid var(--blue-2-color);
  background-color: white !important;
  color: var(--blue-2-color) !important;
}

.button.-white {
  background-color: white !important;
}

.button.-white:hover {
  background-color: var(--accent-color) !important;
  color: white !important;
}

.button.-secondary {
  background-color: var(--secondary-color) !important;
}

.button.-secondary:hover {
  background-color: white !important;
  color: var(--secondary-color) !important;
}

.button.-accent {
  border: 2px solid transparent;
  background-color: var(--accent-color);
}

.button.-accent:hover {
  border-color: var(--accent-color);
  background-color: white;
  color: var(--accent-color) !important;
}

.button.-accent:hover .icon {
  color: var(--accent-color) !important;
}

.button.-light-accent {
  background-color: var(--light-accent-color);
}

.button.-light-accent:hover {
  background-color: var(--accent-color);
  color: white !important;
}

.button.-dark {
  border: 2px solid transparent;
  background-color: black !important;
}

.button.-dark:hover {
  border: 2px solid black;
  background-color: white !important;
  color: black !important;
}

.button.-green {
  background-color: var(--green-color);
  border: 2px solid transparent;
}

.button.-green:hover {
  border: 2px solid var(--green-color);
  background-color: white;
  color: var(--text-dark-1) !important;
}

.button.-green:hover .icon {
  color: var(--text-dark-1) !important;
}

.button.-outline-white {
  border: 2px solid #EDEDED;
  background-color: transparent;
}

.button.-outline-white:hover {
  background-color: white;
  color: black !important;
}

.button.-outline-light-dark {
  border: 2px solid #EDEDED;
  background-color: transparent;
}

.button.-outline-light-dark:hover {
  border-color: #1A3454;
  background-color: #1A3454;
  color: white !important;
}

.button.-outline-dark {
  border: 2px solid #1A3454;
  background-color: transparent;
}

.button.-outline-dark:hover {
  background-color: #1A3454;
  color: white;
}

.button.-outline-dark-accent {
  border: 2px solid #EDEDED;
  background-color: transparent;
}

.button.-outline-dark-accent:hover {
  background-color: var(--accent-color);
  color: white !important;
}

.button.-outline-accent {
  border: 2px solid var(--accent-color);
  background-color: transparent;
}

.button.-outline-accent:hover {
  background-color: var(--accent-color);
  color: white !important;
}

.button.-red {
  border: 2px solid transparent;
  background-color: var(--red-bright-color);
}

.button.-red:hover {
  border: 2px solid var(--red-bright-color);
  background-color: white !important;
  color: var(--red-bright-color) !important;
}

.button.-bg-title-dark {
  border: 2px solid transparent;
  background-color: #1A3454;
}

.button.-bg-title-dark:hover {
  border: 2px solid #1A3454;
  background-color: white;
  color: #1A3454 !important;
}

.button.-simple {
  text-decoration: underline;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.button.-icon .icon {
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.button.-icon:hover .icon {
  transform: translateX(0.375rem);
}

.button.-store {
  display: flex;
  padding: 11px 28px 11px 20px;
  border-radius: 0.5rem;
  text-decoration: none !important;
}

@media (max-width: 767px) {
  .button.-store {
    min-width: 210px;
  }
}

.button.-store .button__icon {
  padding-right: 20px;
  display: flex;
  align-items: center;
  padding-bottom: 2px;
}

.button.-store .button__content {
  flex: 1 0 auto;
  text-align: left;
  padding-left: 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  text-transform: initial;
}

.button.-store .button__content div:first-child {
  font-size: 13px;
  line-height: 1.3;
}

.button.-store .button__content div:last-child {
  font-size: 15px;
  line-height: 1.4;
  font-weight: 600;
}

/*--------------------------------------------------
	05. Forms
---------------------------------------------------*/
.form-switch {
  display: flex;
  align-items: center;
}

.form-switch label {
  margin: 0;
  margin-left: 0.875rem;
  font-size: 0.875rem;
}

.form-switch .switch {
  position: relative;
  width: 3.75rem;
  height: 1.875rem;
}

.form-switch .switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0px 0.625rem 3.125rem rgba(13, 38, 59, 0.1);
  background-color: transparent;
  transition: .3s;
  border-radius: 1.875rem;
  border: 1px solid #E4E4E4;
}

.form-switch .switch__slider::before {
  position: absolute;
  content: "";
  height: 1.25rem;
  width: 1.25rem;
  left: 0.3125rem;
  bottom: 0.25rem;
  background-color: #E4E4E4;
  border-radius: 50%;
  transition: .3s;
}

.form-switch .switch input {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.form-switch .switch input:checked + .switch__slider {
  background-color: var(--accent-color);
}

.form-switch .switch input:focus + .switch__slider {
  box-shadow: 0 0 1px var(--accent-color);
}

.form-switch .switch input:checked + .switch__slider::before {
  transform: translateX(29px);
  background-color: #FFFFFF;
}

.form-radio {
  display: flex;
  align-items: center;
}

.form-radio label {
  margin: 0;
  margin-left: 0.5625rem;
  font-size: 0.875rem;
}

.form-radio .radio {
  cursor: pointer;
  position: relative;
  display: block;
}

.form-radio .radio__mark {
  height: 1rem;
  width: 1rem;
  border-radius: 100%;
  border: 2px solid #E4E4E4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-radio .radio__icon {
  display: none;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: transparent;
}

.form-radio .radio input {
  z-index: 5;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.form-radio .radio input:checked ~ .radio__mark {
  border-color: var(--accent-color);
  border-width: 4px;
}

.form-radio .radio input:checked ~ .radio__mark .radio__icon {
  display: none;
}

.form-radio .radio:hover input ~ .radio__mark {
  background-color: rgba(0, 0, 0, 0.05);
}

.form-checkbox {
  display: flex;
  align-items: center;
}

.form-checkbox label {
  margin: 0;
  margin-left: 0.5625rem;
  font-size: 0.875rem;
}

.form-checkbox .checkbox {
  cursor: pointer;
  position: relative;
  display: block;
}

.form-checkbox .checkbox__mark {
  height: 1.125rem;
  width: 1.125rem;
  border: 2px solid #E4E4E4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-checkbox .checkbox__icon {
  display: none;
  position: absolute;
  width: 1rem;
  height: 1rem;
  stroke-width: 1.5;
  color: white;
}

.form-checkbox .checkbox input {
  z-index: 5;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.form-checkbox .checkbox input:checked ~ .checkbox__mark {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  border-radius: 4px;
}

.form-checkbox .checkbox input:checked ~ .checkbox__mark .checkbox__icon {
  display: block;
}

.form-checkbox .checkbox:hover input ~ .checkbox__mark {
  background-color: rgba(0, 68, 235, 0.05);
}

.form-checkbox .checkbox:hover input:checked ~ .checkbox__mark {
  background-color: rgba(0, 68, 235, 0.8);
}

select,
input[type="text"],
input[type="search"],
textarea,
input[type="email"] {
  border: 0;
}

form.contact-form select,
form.contact-form input[type="text"],
form.contact-form input[type="password"],
form.contact-form input[type="search"],
form.contact-form textarea,
form.contact-form input[type="email"] {
  border: 0;
  font-size: 0.8125rem;
  line-height: 1.3;
  font-weight: 400;
  width: 100%;
  padding-bottom: 0.875rem;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  transition: border 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

form.contact-form select:focus,
form.contact-form input[type="text"]:focus,
form.contact-form input[type="password"]:focus,
form.contact-form input[type="search"]:focus,
form.contact-form textarea:focus,
form.contact-form input[type="email"]:focus {
  outline: none;
  border-bottom: 1px solid black;
}

form.contact-form select.-border-dark,
form.contact-form input[type="text"].-border-dark,
form.contact-form input[type="password"].-border-dark,
form.contact-form input[type="search"].-border-dark,
form.contact-form textarea.-border-dark,
form.contact-form input[type="email"].-border-dark {
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
}

form.contact-form ::-moz-placeholder {
  font-weight: 400;
}

form.contact-form :-ms-input-placeholder {
  font-weight: 400;
}

form.contact-form ::placeholder {
  font-weight: 400;
}

form.contact-form.-light label,
form.contact-form.-light p {
  color: white;
}

form.contact-form.-light select,
form.contact-form.-light input[type="text"],
form.contact-form.-light input[type="search"],
form.contact-form.-light textarea,
form.contact-form.-light input[type="email"] {
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

form.contact-form.-light ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

form.contact-form.-light :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

form.contact-form.-light ::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.form-newsletter {
  max-width: 482px;
}

.form-newsletter form {
  width: 100%;
}

.form-newsletter .form-group {
  position: relative;
  width: 100%;
}

.form-newsletter input {
  padding: 28px !important;
  background-color: white;
  font-size: 13px !important;
  border-radius: 8px;
  width: 100%;
}

.form-newsletter [type="submit"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.5625rem;
  min-width: 10.625rem;
  padding: 1.0625rem 2rem;
}

.form-newsletter.-simple {
  max-width: 482px;
}

.form-newsletter.-simple form {
  width: 100%;
}

.form-newsletter.-simple .form-group {
  position: relative;
  width: 100%;
  display: flex;
}

.form-newsletter.-simple input {
  padding: 0 28px !important;
  background-color: white;
  font-size: 13px !important;
  border-radius: 8px;
  width: 100%;
  height: 3.125rem;
}

.form-newsletter.-simple input.-border-dark {
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
}

.form-newsletter.-simple [type="submit"] {
  position: relative;
  top: unset;
  transform: none;
  right: 0;
  padding: 0;
  min-width: unset !important;
  flex: 1 0 auto;
  width: 3.125rem;
  height: 3.125rem;
  max-width: 3.125rem;
  max-height: 3.125rem;
}

.input-counter {
  display: flex;
  position: relative;
  width: 8.75rem;
  height: 3.25rem;
  /* Firefox */
}

.input-counter input::-webkit-outer-spin-button,
.input-counter input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-counter input[type=number] {
  -moz-appearance: textfield;
}

.input-counter__counter {
  z-index: 5;
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5rem;
  border: 2px solid #EDEDED;
  border-radius: 200px !important;
}

.input-counter__controls {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-counter__up {
  width: 3rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-counter__up .icon {
  stroke-width: 2;
  color: black;
  width: 1rem;
  height: 1rem;
}

.input-counter__down {
  width: 3rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-counter__down .icon {
  stroke-width: 2;
  color: black;
  width: 1rem;
  height: 1rem;
}

.shopSidebar__item [slider] {
  margin-top: 0.75rem;
}

.shopSidebar__item [slider] > div > [sign] {
  background-color: unset;
  width: unset;
  height: unset;
  border-radius: unset;
  display: inline-block;
  text-align: unset;
  margin-top: 1.375rem;
  color: black;
}

.shopSidebar__item [slider] > div > [sign]:last-child {
  float: right;
}

.shopSidebar__item [slider] > div > [sign] span::before {
  content: 'Min Price: $';
  display: inline-block;
  color: #615E5E;
}

.shopSidebar__item [slider] > div > [sign]:last-child span::before {
  content: 'Max Price: $';
  display: inline-block;
  color: #615E5E;
}

.shopSidebar__item [slider] > div > [sign] > span {
  font-size: 0.875rem;
  line-height: 1rem;
  color: black;
}

[slider] {
  position: relative;
  height: 0.3125rem;
  border-radius: 0.625rem;
  text-align: left;
  margin: 45px 0 10px 0;
}

[slider] > div {
  width: 100%;
}

[slider] > div > [inverse-left] {
  position: absolute;
  left: 0;
  height: 0.3125rem;
  border-radius: 0.625rem;
  background-color: #CCC;
}

[slider] > div > [inverse-right] {
  position: absolute;
  right: 0;
  height: 0.3125rem;
  border-radius: 0.625rem;
  background-color: #CCC;
}

[slider] > div > [range] {
  position: absolute;
  left: 0;
  height: 0.3125rem;
  border-radius: 0.875rem;
  background-color: var(--accent-color);
}

[slider] > div > [thumb] {
  position: absolute;
  top: -0.4375rem;
  z-index: 2;
  height: 1.0625rem;
  width: 1.0625rem;
  text-align: left;
  margin-left: -0.6875rem;
  cursor: pointer;
  background-color: #FFF;
  border-radius: 50%;
  outline: none;
  border: 2px solid var(--accent-color);
}

div[slider] > input[type=range]::-ms-thumb {
  pointer-events: all;
  width: 1.0625rem;
  height: 1.0625rem;
  border-radius: 0rem;
  border: 0 none;
  background: red;
}

div[slider] > input[type=range]::-moz-range-thumb {
  pointer-events: all;
  width: 1.0625rem;
  height: 1.0625rem;
  border-radius: 0rem;
  border: 0 none;
  background: red;
}

div[slider] > input[type=range]::-webkit-slider-thumb {
  pointer-events: all;
  width: 1.0625rem;
  height: 1.0625rem;
  border-radius: 0rem;
  border: 0 none;
  background: red;
  -webkit-appearance: none;
}

div[slider] > input[type=range]::-ms-fill-lower {
  background: transparent;
  border: 0 none;
}

div[slider] > input[type=range]::-ms-fill-upper {
  background: transparent;
  border: 0 none;
}

[slider] > input[type=range] {
  position: absolute;
  pointer-events: none;
  -webkit-appearance: none;
  z-index: 3;
  height: 0.3125rem;
  top: -0.125rem;
  width: 100%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}

div[slider] > input[type=range]::-ms-track {
  -webkit-appearance: none;
  background: transparent;
  color: transparent;
}

div[slider] > input[type=range]::-moz-range-track {
  -moz-appearance: none;
  background: transparent;
  color: transparent;
}

div[slider] > input[type=range]:focus::-webkit-slider-runnable-track {
  background: transparent;
  border: transparent;
}

div[slider] > input[type=range]:focus {
  outline: none;
}

div[slider] > input[type=range]::-ms-tooltip {
  display: none;
}

[slider] > div > [sign] {
  background-color: var(--bg-light-4);
  color: var(--accent-color);
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
  display: inline-block;
  text-align: center;
  margin-top: 1.375rem;
}

[slider] > div > [sign] > span {
  font-size: 0.75rem;
  line-height: 1.75rem;
}

[slider]:hover > div > [sign] {
  opacity: 1;
}

/*--------------------------------------------------
	06. Typography
---------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
  font-weight: 700;
  color: var(--text-dark-1);
  margin: 0;
}

h1 {
  font-size: var(--text-6xl);
}

h2 {
  font-size: var(--text-5xl);
}

h3 {
  font-size: var(--text-4xl);
}

h4 {
  font-size: var(--text-3xl);
}

h5 {
  font-size: var(--text-2xl);
}

h6 {
  font-size: var(--text-xl);
}

.text-xs {
  font-size: var(--text-xs) !important;
}

.text-sm {
  font-size: var(--text-sm) !important;
}

.text-base {
  font-size: var(--text-base) !important;
}

.text-lg {
  font-size: var(--text-lg) !important;
}

.text-xl {
  font-size: var(--text-xl) !important;
}

.text-2xl {
  font-size: var(--text-2xl) !important;
}

.text-3xl {
  font-size: var(--text-3xl) !important;
}

.text-4xl {
  font-size: var(--text-4xl) !important;
}

.text-5xl {
  font-size: var(--text-5xl) !important;
}

.text-6xl {
  font-size: var(--text-6xl) !important;
}

.text-25 {
  font-size: var(--text-25) !important;
}

.text-32 {
  font-size: var(--text-32) !important;
}

.text-16 {
  font-size: var(--text-16) !important;
}

.text-22 {
  font-size: var(--text-22) !important;
}

.text-26 {
  font-size: var(--text-26) !important;
}

.text-30 {
  font-size: var(--text-30) !important;
}

.text-50 {
  font-size: var(--text-50) !important;
}

@media (max-width: 1199px) {
  .lg\:text-xs {
    font-size: var(--text-xs) !important;
  }
  .lg\:text-sm {
    font-size: var(--text-sm) !important;
  }
  .lg\:text-base {
    font-size: var(--text-base) !important;
  }
  .lg\:text-lg {
    font-size: var(--text-lg) !important;
  }
  .lg\:text-xl {
    font-size: var(--text-xl) !important;
  }
  .lg\:text-2xl {
    font-size: var(--text-2xl) !important;
  }
  .lg\:text-3xl {
    font-size: var(--text-3xl) !important;
  }
  .lg\:text-4xl {
    font-size: var(--text-4xl) !important;
  }
  .lg\:text-5xl {
    font-size: var(--text-5xl) !important;
  }
  .lg\:text-6xl {
    font-size: var(--text-6xl) !important;
  }
  .lg\:text-32 {
    font-size: var(--text-32) !important;
  }
}

@media (max-width: 991px) {
  .md\:text-xs {
    font-size: var(--text-xs) !important;
  }
  .md\:text-sm {
    font-size: var(--text-sm) !important;
  }
  .md\:text-base {
    font-size: var(--text-base) !important;
  }
  .md\:text-lg {
    font-size: var(--text-lg) !important;
  }
  .md\:text-xl {
    font-size: var(--text-xl) !important;
  }
  .md\:text-2xl {
    font-size: var(--text-2xl) !important;
  }
  .md\:text-3xl {
    font-size: var(--text-3xl) !important;
  }
  .md\:text-4xl {
    font-size: var(--text-4xl) !important;
  }
  .md\:text-5xl {
    font-size: var(--text-5xl) !important;
  }
  .md\:text-6xl {
    font-size: var(--text-6xl) !important;
  }
  .md\:text-32 {
    font-size: var(--text-32) !important;
  }
}

@media (max-width: 767px) {
  .sm\:text-xs {
    font-size: var(--text-xs) !important;
  }
  .sm\:text-sm {
    font-size: var(--text-sm) !important;
  }
  .sm\:text-base {
    font-size: var(--text-base) !important;
  }
  .sm\:text-lg {
    font-size: var(--text-lg) !important;
  }
  .sm\:text-xl {
    font-size: var(--text-xl) !important;
  }
  .sm\:text-2xl {
    font-size: var(--text-2xl) !important;
  }
  .sm\:text-3xl {
    font-size: var(--text-3xl) !important;
  }
  .sm\:text-4xl {
    font-size: var(--text-4xl) !important;
  }
  .sm\:text-5xl {
    font-size: var(--text-5xl) !important;
  }
  .sm\:text-6xl {
    font-size: var(--text-6xl) !important;
  }
  .sm\:text-32 {
    font-size: var(--text-32) !important;
  }
}

@media (max-width: 575px) {
  .xs\:text-xs {
    font-size: var(--text-xs) !important;
  }
  .xs\:text-sm {
    font-size: var(--text-sm) !important;
  }
  .xs\:text-base {
    font-size: var(--text-base) !important;
  }
  .xs\:text-lg {
    font-size: var(--text-lg) !important;
  }
  .xs\:text-xl {
    font-size: var(--text-xl) !important;
  }
  .xs\:text-2xl {
    font-size: var(--text-2xl) !important;
  }
  .xs\:text-3xl {
    font-size: var(--text-3xl) !important;
  }
  .xs\:text-4xl {
    font-size: var(--text-4xl) !important;
  }
  .xs\:text-5xl {
    font-size: var(--text-5xl) !important;
  }
  .xs\:text-6xl {
    font-size: var(--text-6xl) !important;
  }
  .xs\:text-32 {
    font-size: var(--text-32) !important;
  }
}

.lh-1 {
  line-height: 1 !important;
}

.lh-11 {
  line-height: 1.1 !important;
}

.lh-12 {
  line-height: 1.2 !important;
}

.lh-13 {
  line-height: 1.3 !important;
}

.lh-14 {
  line-height: 1.4 !important;
}

.lh-14 {
  line-height: 1.5 !important;
}

.lh-16 {
  line-height: 1.6 !important;
}

.lh-17 {
  line-height: 1.7 !important;
}

.lh-18 {
  line-height: 1.8 !important;
}

.lh-19 {
  line-height: 1.9 !important;
}

.lh-2 {
  line-height: 2 !important;
}

.header-container {
  padding: 0 10rem;
}

@media (max-width: 1199px) {
  .header-container {
    padding: 0 3rem;
  }
}

@media (max-width: 991px) {
  .header-container {
    padding: 0 1.875rem;
  }
}

@media (max-width: 767px) {
  .header-container {
    padding: 0 1.5rem;
  }
}

.header {
  z-index: 500;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.header__bar {
  z-index: 5;
  position: relative;
  width: 100%;
  padding: 1.75rem 10rem;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@media (max-width: 1199px) {
  .header__bar {
    padding: 1.75rem 3rem;
  }
}

@media (max-width: 991px) {
  .header__bar {
    padding: 1.75rem 1.875rem;
  }
}

@media (max-width: 767px) {
  .header__bar {
    padding: 0.75rem 1.25rem;
  }
}

.header.-container .header__bar {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.header.-container.is-sticky .header__bar {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.header__logo {
  position: relative;
}

.header .base-logo {
  display: block;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.header .base-logo-first {
  display: block;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 1;
}

.header .base-logo-second {
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.header.is-sticky .base-logo-first {
  opacity: 0;
}

.header.is-sticky .base-logo-second {
  opacity: 1;
}

.header .menu-logo {
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

@media (max-width: 991px) {
  .header .menu-logo {
    display: block;
  }
}

.header.menu-open .base-logo {
  opacity: 0;
}

.header.menu-open .menu-logo {
  opacity: 1;
}

.header__right {
  display: flex;
}

.header__menu {
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
}

.header a {
  text-decoration: none;
}

.header .nav > li > a {
  text-decoration: none;
}

.header .nav > li > a:hover {
  text-decoration: underline !important;
  color: currentColor !important;
}

.header .nav-button-close {
  position: absolute;
  top: 0;
  opacity: 0;
}

.header .nav-button-close .icon {
  font-size: 1.0625rem;
}

.header.is-sticky.-sticky-light .header__bar {
  background-color: #322867 !important;
}

.header.is-sticky {
  position: fixed;
}

.header.is-sticky .header__bar {
  background-color: white;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  padding: 1.75rem 9.375rem !important;
}

@media (max-width: 1199px) {
  .header.is-sticky .header__bar {
    padding: 1.75rem 3rem !important;
  }
}

@media (max-width: 991px) {
  .header.is-sticky .header__bar {
    padding: 1.75rem 1.875rem !important;
  }
}

@media (max-width: 767px) {
  .header.is-sticky .header__bar {
    padding: 0.75rem 1.25rem !important;
  }
}

.header.is-sticky.-sticky-dark .header__bar .nav > li > a,
.header.is-sticky.-sticky-dark .header__bar .icon,
.header.is-sticky.-sticky-dark .header__bar > * {
  color: black !important;
}

.header.is-sticky.-sticky-dark .header__bar .header__right p {
  color: black !important;
}

.header.is-sticky.-sticky-dark .header__bar .header__right a.button {
  color: white !important;
  border: 2px solid var(--text-dark-1) !important;
  background-color: var(--text-dark-1) !important;
}

.header.is-sticky.-sticky-dark .header__bar .header__right a.button:hover {
  color: var(--text-dark-1) !important;
  background-color: transparent !important;
}

@media (min-width: 1200px) {
  .header.-type-2 .header__bar {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
}

.header.-type-2 .header__logo a .icon {
  font-size: 3.25rem;
}

.header.-type-7 .header__bar {
  padding: 5.625rem 7.5rem;
}

@media (max-width: 1199px) {
  .header.-type-7 .header__bar {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media (max-width: 991px) {
  .header.-type-7 .header__bar {
    padding: 1.75rem 1.875rem;
  }
}

@media (max-width: 767px) {
  .header.-type-7 .header__bar {
    padding: 0.75rem 1.25rem;
  }
}

.header.-type-7 .header__logo a .icon {
  font-size: 3.25rem;
}

.header .nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .nav li {
  display: flex;
  align-items: center;
}

.header .nav li a {
  display: block;
  padding: 1.25rem 1rem;
  font-size: 0.9375rem;
  line-height: 1.2;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .menu {
    position: unset !important;
  }
}

@media (min-width: 992px) {
  .menu {
    position: relative;
    width: unset;
  }
  .menu .nav {
    display: flex;
    width: 100%;
  }
  .menu .nav > li > a {
    position: relative;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .menu .nav li {
    cursor: pointer;
  }
  .menu .nav li:hover > a {
    color: var(--accent-color);
  }
  .menu .nav li:hover > a::after {
    transform: scaleX(1);
  }
  .menu .nav li.-has-mega-menu {
    position: unset !important;
  }
  .menu .nav li.-has-mega-menu:hover .mega {
    opacity: 1;
    pointer-events: auto;
  }
  .menu .nav li.menu-item-has-children {
    position: relative;
  }
  .menu .nav li.menu-item-has-children:hover > .nav__submenu {
    opacity: 1;
    pointer-events: auto;
  }
  .menu .nav__submenu {
    z-index: 4;
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: 1.875rem 1.25rem 0.9375rem 1.25rem;
    min-width: 15rem;
    border-radius: 0.5rem;
    background-color: white;
    border: 1px solid #EEEEEE;
    box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
    transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
    pointer-events: none;
    opacity: 0;
  }
  .menu .nav__submenu_item > a {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    padding: 0.875rem 1.25rem !important;
    line-height: 1;
    border-radius: 0.5rem;
    color: var(--font-dark) !important;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .menu .nav__submenu_item > a:hover {
    background-color: #F5F7FE;
  }
  .menu .nav__submenu_item.menu-item-has-children > a {
    display: block;
    position: relative;
  }
  .menu .nav__submenu_item.menu-item-has-children > a::after {
    content: '\e907';
    font-family: 'icomoon' !important;
    font-size: 0.625rem;
    font-weight: normal;
    position: absolute;
    right: 1.25rem;
  }
  .menu .nav__submenu_item.menu-item-has-children .nav__submenu {
    position: absolute;
    top: -1.25rem;
    left: calc(100%);
    pointer-events: none;
    opacity: 0;
  }
  .menu .mobile__background {
    display: none !important;
  }
  .menu .mobile__back {
    display: none !important;
  }
  .menu .mobile__footer {
    display: none !important;
  }
  .menu .mobile__socials {
    display: none !important;
  }
  .menu .mobile__copyright {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .menu {
    z-index: -1;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    padding: 0;
  }
  .menu.is-active {
    pointer-events: auto;
  }
  .menu .mega {
    display: none;
  }
  .menu__container {
    width: 100%;
    height: 100%;
    padding: 0 1.25rem;
    padding-top: 30px;
    margin-top: 4.6875rem;
    margin-top: 6.625rem;
    position: relative;
    margin-bottom: 1.875rem;
    overflow: auto;
  }
  .menu ul {
    pointer-events: none;
    position: relative;
    width: 100%;
  }
  .menu ul.is-active {
    pointer-events: auto;
  }
  .menu ul.nav__submenu {
    display: none !important;
  }
  .menu ul.nav__submenu.is-list-displayed {
    display: block !important;
  }
  .menu .nav {
    position: relative;
    height: unset;
    width: 100%;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .menu .nav.is-open {
    transform: none;
    pointer-events: auto;
    opacity: 1;
  }
  .menu .nav li {
    overflow: hidden;
    width: 100%;
    margin: 0 !important;
    height: unset;
  }
  .menu .nav li a {
    position: relative;
    font-size: 1rem;
    line-height: 1;
    width: 100%;
    padding: 0.875rem 1.25rem !important;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--font-dark) !important;
    transform: translateY(100%);
    border-radius: 0.5rem;
  }
  .menu .nav li a::before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    background-color: #F5F7FE;
    opacity: 0;
  }
  .menu .nav li a:hover::before {
    opacity: 1;
  }
  .menu .nav li.menu-item-has-children > a::after {
    content: '\e907';
    font-family: 'icomoon' !important;
    font-size: 0.625rem;
    font-weight: normal;
    position: absolute;
    right: 1.25rem;
  }
  .menu .nav__submenu {
    position: absolute;
    top: 0;
    left: 0;
  }
  .menu .nav__submenu li a {
    position: relative;
    font-size: 1rem;
    line-height: 1;
    width: 100%;
    padding: 0.875rem 1.25rem !important;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #1A3454;
    transform: translateY(100%);
    border-radius: 0.5rem;
  }
  .menu .nav__submenu li a::before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    background-color: #F5F7FE;
    opacity: 0;
  }
  .menu .nav__submenu li a:hover::before {
    opacity: 1;
  }
  .menu .mobile__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    transform: scaleY(0);
    transform-origin: bottom;
  }
  .menu .mobile__back {
    position: relative;
    width: 100%;
    margin-bottom: 1.25rem;
    opacity: 0;
    display: none;
  }
  .menu .mobile__back.-is-active {
    display: block;
  }
  .menu .mobile__back a {
    background-color: var(--accent-color);
    position: relative;
    font-size: 1rem;
    line-height: 1;
    width: 100%;
    padding: 0.875rem 1.25rem !important;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: white;
    border-radius: 0.5rem;
  }
  .menu .mobile__back a::before {
    content: '\e907';
    font-family: 'icomoon' !important;
    font-size: 0.625rem;
    font-weight: normal;
    position: relative;
    margin-right: 1rem;
    transform: rotate(180deg);
  }
  .menu .mobile__footer {
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: flex-end;
    border-top: 1px solid #EEEEEE;
    padding: 26px 20px;
    width: 100%;
    opacity: 0;
  }
  .menu .mobile__socials {
    display: flex;
  }
  .menu .mobile__socials a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 8px;
    font-size: 14px;
    border-radius: 100%;
    border: 1px solid transparent;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    color: #404046;
  }
  .menu .mobile__socials a:hover {
    color: var(--accent-color) !important;
  }
}

@media (max-width: 767px) {
  .menu__container {
    margin-top: 4.6875rem !important;
  }
}

.lines.-vertical-lines {
  z-index: -1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 13.75rem;
  right: 13.75rem;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .lines.-vertical-lines {
    justify-content: space-evenly;
    left: 0;
    right: 0;
  }
}

.lines.-vertical-lines span {
  height: 100%;
  width: 1px;
  background-color: #EFF0F0;
}

@media (max-width: 767px) {
  .lines.-vertical-lines {
    display: none;
  }
}

.loadmore-hidden {
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  display: none;
}

.loadmore-hidden.is-active {
  opacity: 1;
  display: flex;
}

.switch-content {
  position: relative;
}

.switch-content > *:nth-child(1) {
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  opacity: 1;
}

.switch-content > *:nth-child(2) {
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  margin: 0 !important;
}

.switch-content.is-active > *:nth-child(1) {
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.switch-content.is-active > *:nth-child(2) {
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  opacity: 1;
}

.sectionHeading__subtitle {
  margin-bottom: 2.3125rem;
}

@media (max-width: 991px) {
  .sectionHeading__subtitle {
    margin-bottom: 1.5625rem;
  }
}

.sectionHeading__subtitle span {
  color: var(--accent-color);
  font-size: 0.6875rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding: 0.5rem 1.5625rem;
  background-color: var(--light-accent-color);
  border-radius: 200px;
}

.sectionHeading__title {
  color: var(--text-dark-1);
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.4;
}

@media (max-width: 991px) {
  .sectionHeading__title {
    font-size: 2.125rem;
  }
}

@media (max-width: 767px) {
  .sectionHeading__title {
    font-size: 1.5625rem;
  }
}

@media (max-width: 575px) {
  .sectionHeading__title br {
    display: none !important;
  }
}

.sectionHeading__text {
  font-size: 0.9375rem;
  line-height: 1.625rem;
  margin-top: 1.125rem;
}

.sectionHeading.-light .sectionHeading__subtitle span {
  background: rgba(255, 255, 255, 0.05);
  color: white;
}

.sectionHeading.-light .sectionHeading__title {
  color: white;
}

.atropos {
  position: relative;
  display: block;
  perspective: 1200px;
  transform: translate3d(0, 0, 0);
}

.atropos-rotate-touch, .atropos-rotate-scroll-x, .atropos-rotate-scroll-y {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.atropos-rotate-touch-scroll-y {
  touch-action: pan-y;
}

.atropos-rotate-touch-scroll-x {
  touch-action: pan-x;
}

.atropos-rotate-touch {
  touch-action: none;
}

.atropos.-no-shadow .atropos-shadow {
  display: none;
}

.atropos-scale,
.atropos-rotate {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition-property: transform;
  display: block;
}

.atropos-shadow,
.atropos-highlight {
  position: absolute;
  pointer-events: none;
  transition-property: transform, opacity;
  display: block;
  opacity: 0;
}

.atropos-shadow {
  z-index: -1;
  background: #000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  filter: blur(30px);
}

.atropos-highlight {
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  background-image: radial-gradient(circle at 50%, rgba(255, 255, 255, 0.25), transparent 50%);
  z-index: 0;
}

.atropos-rotate {
  position: relative;
}

.atropos-inner {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  transform-style: preserve-3d;
  transform: translate3d(0, 0, 0);
  display: block;
}

.atropos-active {
  z-index: 1;
}

.atropos-active .atropos-shadow {
  opacity: 1 !important;
}

[data-atropos-offset] {
  transition-property: transform;
}

[data-atropos-opacity] {
  transition-property: opacity;
}

[data-atropos-offset][data-atropos-opacity] {
  transition-property: transform, opacity;
}

.masthead.-type-1 {
  position: relative;
  margin-top: 6.5625rem;
  height: 50rem;
}

@media (max-width: 991px) {
  .masthead.-type-1 {
    margin-top: 6.5625rem;
    padding-top: 2.5rem;
    height: auto;
    margin-bottom: 2.5rem;
  }
}

@media (max-width: 767px) {
  .masthead.-type-1 {
    margin-top: 4.6875rem;
    padding-top: 3.75rem;
  }
}

.masthead.-type-1 .masthead__bg {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 3.4375rem;
  right: 3.4375rem;
}

@media (max-width: 991px) {
  .masthead.-type-1 .masthead__bg {
    left: 0;
    right: 0;
    height: 50rem;
  }
}

.masthead.-type-1 .masthead__bg img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}

@media (max-width: 767px) {
  .masthead.-type-1 .masthead__bg img:first-child {
    display: none;
  }
}

.masthead.-type-1 .masthead__bg img:last-child {
  display: none;
}

@media (max-width: 767px) {
  .masthead.-type-1 .masthead__bg img:last-child {
    display: block;
  }
}

.masthead.-type-1 .masthead__image {
  position: absolute;
  top: 0;
  right: 9.5rem;
}

.masthead.-type-1 .masthead__image img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 1199px) {
  .masthead.-type-1 .masthead__image {
    right: 0;
  }
}

@media (max-width: 991px) {
  .masthead.-type-1 .masthead__image {
    position: relative;
    padding-top: 5rem;
  }
}

.masthead.-type-1 .masthead__content {
  padding-top: 10.5rem;
}

@media (max-width: 991px) {
  .masthead.-type-1 .masthead__content {
    padding-top: 4.375rem;
    padding-top: 0;
  }
}

.masthead.-type-1 .masthead__subtitle {
  margin-bottom: 2rem;
}

@media (max-width: 767px) {
  .masthead.-type-1 .masthead__subtitle {
    margin-bottom: 1.25rem;
  }
}

.masthead.-type-1 .masthead__subtitle span {
  display: inline-block;
  color: #1A3454;
  padding: 0.5rem 1.5625rem;
  background-color: #e3e8fa;
  border-radius: 12.5rem;
  font-size: 0.8125rem;
  line-height: 1;
}

.masthead.-type-1 .masthead__title {
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 1.4;
}

@media (max-width: 991px) {
  .masthead.-type-1 .masthead__title {
    font-size: 2.5rem;
  }
}

@media (max-width: 767px) {
  .masthead.-type-1 .masthead__title {
    font-size: 1.875rem;
  }
}

.masthead.-type-1 .masthead__text {
  margin-top: 0.9375rem;
}

@media (max-width: 767px) {
  .masthead.-type-1 .masthead__text {
    margin-top: 0.5rem;
  }
}

.masthead.-type-1 .masthead__pagination_numbers {
  z-index: 5;
  position: absolute;
  top: 20.625rem;
  left: 3.75rem;
  display: flex;
}

.masthead.-type-1 .masthead__pagination_numbers span {
  color: white;
}

.masthead.-type-1 .masthead__pagination_numbers span:nth-child(1) {
  font-size: 0.875rem;
  line-height: 1rem;
}

.masthead.-type-1 .masthead__pagination_numbers span:nth-child(2) {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0 0.4375rem;
}

.masthead.-type-1 .masthead__pagination_numbers span:nth-child(3) {
  font-size: 2.5rem;
  line-height: 2.9375rem;
}

.masthead.-type-1 .masthead__socials {
  position: absolute;
  top: 50%;
  right: 3.75rem;
  transform: translateY(-50%);
  -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
  display: flex;
}

.masthead.-type-1 .masthead__socials a + a {
  margin-top: 1.875rem;
}

.masthead.-type-1 .masthead__scroll {
  cursor: pointer;
  position: absolute;
  bottom: 3.125rem;
  right: 3.75rem;
}

.masthead.-type-1 .masthead__scroll_item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 100%;
  color: white;
  font-size: 0.875rem;
  line-height: 1rem;
}

.masthead.-type-1 .masthead__scroll_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 100%;
  border: 1px solid #fff;
  transition: all 0.1s ease-out;
  color: white;
  margin-top: 1.5625rem;
  overflow: hidden;
}

.masthead.-type-1 .masthead__scroll_icon .icon {
  -webkit-animation-duration: 1.8s;
          animation-duration: 1.8s;
  -webkit-animation-name: uiScrollInfinite;
          animation-name: uiScrollInfinite;
  -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
          animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  color: white;
  position: relative;
  font-size: 1.875rem;
  transition: all 0.1s ease-out;
}

@-webkit-keyframes uiScrollInfinite {
  0% {
    transform: translateY(-100%) rotate(90deg);
  }
  100% {
    transform: translateY(100%) rotate(90deg);
  }
}

@keyframes uiScrollInfinite {
  0% {
    transform: translateY(-100%) rotate(90deg);
  }
  100% {
    transform: translateY(100%) rotate(90deg);
  }
}

.masthead-pagination {
  z-index: 10;
  position: absolute;
  bottom: 3.125rem;
  left: 3.75rem;
  display: flex;
}

@media (max-width: 991px) {
  .masthead-pagination {
    bottom: 1.875rem;
    left: 1.875rem;
  }
}

.masthead-pagination__item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  opacity: 0.5;
  transition: opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1) 0.2s;
}

.masthead-pagination__item .masthead-pagination__line {
  transition: transform 0.4s cubic-bezier(0.77, 0, 0.175, 1) 0.2s;
  transform: scaleX(0);
  transform-origin: left;
}

.masthead-pagination__item.is-active {
  opacity: 1;
}

.masthead-pagination__item.is-active .masthead-pagination__line {
  transform: scaleX(1);
}

.masthead-pagination .masthead-pagination__item + .masthead-pagination__item {
  margin-left: 4.6875rem;
}

@media (max-width: 991px) {
  .masthead-pagination .masthead-pagination__item + .masthead-pagination__item {
    margin-left: 2.5rem;
  }
}

.masthead-pagination__number {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  color: white;
}

.masthead-pagination__title {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-top: 0.75rem;
  color: white;
}

.masthead-pagination__line {
  margin-top: 0.5rem;
  transform-origin: left;
  width: 6.5rem;
  height: 0.0625rem;
  background-color: white;
}

@media (max-width: 991px) {
  .masthead-pagination__line {
    width: 100%;
  }
}

.masthead.-type-2 .masthead__bg {
  z-index: -1;
  position: absolute;
}

@media (max-width: 991px) {
  .masthead.-type-2 .masthead__bg {
    height: 100%;
  }
  .masthead.-type-2 .masthead__bg img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.masthead.-type-2 .masthead__content {
  padding-top: 190px;
}

@media (max-width: 991px) {
  .masthead.-type-2 .masthead__content {
    padding-top: 160px;
    padding-bottom: 80px;
  }
}

.masthead.-type-2 .masthead__title {
  font-size: 60px;
  font-weight: 700;
  line-height: 1.4;
}

@media (max-width: 991px) {
  .masthead.-type-2 .masthead__title {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-2 .masthead__title {
    font-size: 30px;
  }
}

.masthead.-type-3 {
  position: relative;
  padding-top: 160px;
  padding-bottom: 220px;
}

@media (max-width: 1199px) {
  .masthead.-type-3 {
    padding-top: 150px;
    padding-bottom: 220px;
  }
}

@media (max-width: 991px) {
  .masthead.-type-3 {
    padding-top: 140px;
    padding-bottom: 0;
  }
}

.masthead.-type-3 .masthead__bg {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.masthead.-type-3 .masthead__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.masthead.-type-3 .masthead__image {
  position: absolute;
  right: -15vw;
}

.masthead.-type-3 .masthead__image img {
  width: 62vw;
  height: auto;
}

@media (max-width: 991px) {
  .masthead.-type-3 .masthead__image {
    position: relative;
    right: unset;
  }
  .masthead.-type-3 .masthead__image img {
    width: 100%;
  }
}

.masthead.-type-3 .masthead__content {
  padding-top: 100px;
}

@media (max-width: 991px) {
  .masthead.-type-3 .masthead__content {
    padding-top: 0;
  }
}

.masthead.-type-3 .masthead__title {
  font-size: 60px;
  font-weight: 700;
  line-height: 1.4;
}

@media (max-width: 767px) {
  .masthead.-type-3 .masthead__title {
    font-size: 42px;
  }
}

.masthead.-type-4 {
  position: relative;
  padding-top: 250px;
  padding-bottom: 250px;
}

@media (max-width: 1199px) {
  .masthead.-type-4 {
    padding-top: 220px;
    padding-bottom: 220px;
  }
}

@media (max-width: 991px) {
  .masthead.-type-4 {
    padding-top: 160px;
    padding-bottom: 100px;
  }
}

.masthead.-type-4 .masthead__bg {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.masthead.-type-4 .masthead__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.masthead.-type-4 .masthead__image {
  position: absolute;
  right: 3vw;
  top: 50%;
  transform: translateY(-50%);
}

.masthead.-type-4 .masthead__image img {
  width: 48vw;
  height: auto;
}

@media (max-width: 991px) {
  .masthead.-type-4 .masthead__image {
    position: relative;
    transform: none;
    top: unset;
    right: unset;
  }
  .masthead.-type-4 .masthead__image img {
    width: 100%;
  }
}

.masthead.-type-4 .masthead__title {
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 1.4;
}

@media (max-width: 767px) {
  .masthead.-type-4 .masthead__title {
    font-size: 42px;
  }
}

.masthead.-type-5 {
  position: relative;
  padding-top: 250px;
  padding-bottom: 340px;
}

@media (max-width: 1199px) {
  .masthead.-type-5 {
    padding-top: 220px;
    padding-bottom: 220px;
  }
}

@media (max-width: 991px) {
  .masthead.-type-5 {
    padding-top: 160px;
    padding-bottom: 100px;
  }
}

.masthead.-type-5 .masthead__bg {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  width: 95vw;
  bottom: 0;
}

.masthead.-type-5 .masthead__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.masthead.-type-5 .masthead__image {
  position: absolute;
  right: -24vw;
  top: 50%;
  transform: translateY(-50%);
  padding-top: 8vw;
}

.masthead.-type-5 .masthead__image img {
  width: 85vw;
  height: auto;
}

@media (max-width: 991px) {
  .masthead.-type-5 .masthead__image {
    position: relative;
    transform: none;
    top: unset;
    right: unset;
    display: flex;
    justify-content: center;
  }
  .masthead.-type-5 .masthead__image img {
    width: 100%;
    width: 120vw;
  }
}

.masthead.-type-5 .masthead__title {
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 1.4;
}

@media (max-width: 767px) {
  .masthead.-type-5 .masthead__title {
    font-size: 42px;
  }
}

.masthead.-type-6 {
  position: relative;
  padding-top: 280px;
  padding-bottom: 240px;
}

@media (max-width: 1199px) {
  .masthead.-type-6 {
    padding-top: 220px;
    padding-bottom: 220px;
  }
}

@media (max-width: 991px) {
  .masthead.-type-6 {
    padding-top: 160px;
    padding-bottom: 0;
  }
}

.masthead.-type-6 .masthead__bg {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  bottom: 0;
}

.masthead.-type-6 .masthead__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.masthead.-type-6 .masthead__image {
  position: absolute;
  bottom: -1vw;
  right: 0;
}

.masthead.-type-6 .masthead__image img {
  width: 100%;
  height: auto;
}

@media (max-width: 1199px) {
  .masthead.-type-6 .masthead__image {
    right: -26vw;
  }
}

@media (max-width: 991px) {
  .masthead.-type-6 .masthead__image {
    position: relative;
    transform: none;
    top: unset;
    bottom: unset;
    right: unset;
    left: -20vw;
  }
  .masthead.-type-6 .masthead__image img {
    width: 120vw;
  }
}

@media (max-width: 767px) {
  .masthead.-type-6 .masthead__image {
    left: -25vw;
  }
}

.masthead.-type-6 .masthead__title {
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 1.4;
}

@media (max-width: 767px) {
  .masthead.-type-6 .masthead__title {
    font-size: 42px;
  }
}

.masthead.-type-7 {
  position: relative;
  padding-top: 17.5rem;
  padding-bottom: 13.75rem;
}

@media (max-width: 1199px) {
  .masthead.-type-7 {
    padding-top: 220px;
    padding-bottom: 220px;
  }
}

@media (max-width: 991px) {
  .masthead.-type-7 {
    padding-top: 160px;
    padding-bottom: 450px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-7 {
    padding-top: 160px;
    padding-bottom: 300px;
  }
}

.masthead.-type-7 .masthead__bg {
  z-index: -1;
  position: absolute;
  top: 1.875rem;
  right: 1.875rem;
  left: 1.875rem;
  bottom: 0;
}

.masthead.-type-7 .masthead__bg img {
  width: 100%;
  height: 100%;
}

@media (max-width: 991px) {
  .masthead.-type-7 .masthead__bg img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media (max-width: 991px) {
  .masthead.-type-7 .masthead__bg {
    top: 0;
    right: 0;
    left: 0;
  }
}

.masthead.-type-7 .masthead__image {
  position: absolute;
  bottom: 0;
  right: 11vw;
}

.masthead.-type-7 .masthead__image img {
  width: 40vw;
  height: auto;
}

.masthead.-type-7 .masthead__image img:nth-child(1) {
  z-index: 0;
  position: absolute;
  padding-top: 4vw;
}

.masthead.-type-7 .masthead__image img:nth-child(2) {
  position: relative;
  z-index: 1;
}

@media (max-width: 1600px) {
  .masthead.-type-7 .masthead__image {
    right: 6vw;
  }
  .masthead.-type-7 .masthead__image img {
    width: 50vw;
  }
}

@media (max-width: 1199px) {
  .masthead.-type-7 .masthead__image {
    right: -2vw;
  }
  .masthead.-type-7 .masthead__image img {
    width: 60vw;
  }
}

@media (max-width: 991px) {
  .masthead.-type-7 .masthead__image {
    transform: none;
    top: unset;
    bottom: unset;
    right: unset;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .masthead.-type-7 .masthead__image img {
    width: 100vw;
  }
}

.masthead.-type-7 .masthead__title {
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 1.4;
}

@media (max-width: 767px) {
  .masthead.-type-7 .masthead__title {
    font-size: 42px;
  }
}

.masthead.-type-8 {
  position: relative;
  padding-top: 17.5rem;
  padding-bottom: 13.75rem;
}

@media (max-width: 1199px) {
  .masthead.-type-8 {
    padding-top: 220px;
    padding-bottom: 140px;
  }
}

@media (max-width: 991px) {
  .masthead.-type-8 {
    padding-top: 200px;
    padding-bottom: 40px;
  }
}

.masthead.-type-8 .masthead__bg {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.masthead.-type-8 .masthead__bg img {
  width: 100%;
  height: auto;
}

.masthead.-type-8 .masthead__image {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
}

.masthead.-type-8 .masthead__image img {
  width: 90%;
  height: auto;
}

@media (max-width: 1199px) {
  .masthead.-type-8 .masthead__image img {
    width: 55vw;
  }
}

.masthead.-type-8 .masthead__video {
  position: absolute;
  top: 30%;
  left: 58%;
}

.masthead.-type-8 .masthead__title {
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 1.4;
}

@media (max-width: 767px) {
  .masthead.-type-8 .masthead__title {
    font-size: 42px;
  }
}

.masthead.-type-9 {
  position: relative;
  padding-top: 12.5rem;
  padding-bottom: 13.75rem;
}

@media (max-width: 1199px) {
  .masthead.-type-9 {
    padding-top: 180px;
    padding-bottom: 140px;
  }
}

@media (max-width: 991px) {
  .masthead.-type-9 {
    padding-top: 160px;
    padding-bottom: 100px;
  }
}

.masthead.-type-9 .masthead__bg {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.masthead.-type-9 .masthead__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.masthead.-type-9 .masthead__image {
  margin-right: -80px;
}

@media (max-width: 991px) {
  .masthead.-type-9 .masthead__image {
    margin-right: 0;
  }
}

.masthead.-type-9 .masthead__image img {
  height: auto;
  width: 100%;
}

@media (max-width: 991px) {
  .masthead.-type-9 .masthead__image {
    position: relative;
    transform: none;
    top: unset;
    bottom: unset;
    right: unset;
    padding-top: 0;
  }
  .masthead.-type-9 .masthead__image img {
    width: 100%;
  }
}

.masthead.-type-9 .masthead__video {
  position: absolute;
  top: 100px;
  right: 150px;
}

@media (max-width: 767px) {
  .masthead.-type-9 .masthead__video {
    top: 50%;
    right: unset;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.masthead.-type-9 .masthead__title {
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 1.4;
}

@media (max-width: 767px) {
  .masthead.-type-9 .masthead__title {
    font-size: 42px;
  }
}

.socials {
  display: flex;
  align-items: center;
}

.socials > * a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.socials > * a:hover {
  color: var(--accent-color);
}

.socials > * + * {
  margin-left: 1rem;
}

.footer.-type-1 {
  position: relative;
  padding: 2.8125rem 0;
}

@media (max-width: 991px) {
  .footer.-type-1 {
    padding: 1.875rem 0;
  }
}

.footer.-type-1 .footer__left a {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.625rem;
  text-decoration: none;
}

.footer.-type-1 .footer__center {
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 991px) {
  .footer.-type-1 .footer__center {
    position: relative;
  }
}

.footer.-type-2 {
  padding-top: 256px;
}

.footer.-type-3 {
  padding-top: 80px;
}

.footer.-type-4 {
  padding-top: 120px;
}

.numCounter {
  position: relative;
  display: flex;
  flex-direction: column;
}

.numCounter.-light .counter__back {
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.2);
}

.numCounter__number {
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@media (max-width: 767px) {
  .numCounter__number {
    font-size: 1.5625rem !important;
  }
}

@media (max-width: 767px) {
  .numCounter__title {
    font-weight: 400;
  }
}

.numCounter__line {
  position: relative;
  width: 100%;
  height: 2px;
  background-color: #EEEEEE;
}

.numCounter__line::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--accent-color);
  transform-origin: left;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scaleX(0);
}

.numCounter:hover .numCounter__number {
  color: var(--accent-color);
}

.numCounter:hover .numCounter__line::before {
  transform: scaleX(1);
}

.numCounter.-small .numCounter.-light .counter__back {
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.2);
}

.numCounter.-small .numCounter__number {
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@media (max-width: 767px) {
  .numCounter.-small .numCounter__number {
    font-size: 1.5625rem !important;
  }
}

@media (max-width: 767px) {
  .numCounter.-small .numCounter__title {
    font-weight: 400;
  }
}

.numCounter.-small .numCounter__line {
  position: relative;
  width: 1px;
  height: 1.625rem;
  background-color: #EEEEEE;
}

.numCounter.-small .numCounter__line::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--accent-color);
  transform-origin: left;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scaleX(0);
}

.numCounter.-small .numCounter:hover .numCounter__number {
  color: var(--accent-color);
}

.numCounter.-small .numCounter:hover .numCounter__line::before {
  transform: scaleX(1);
}

.cta-image {
  position: absolute;
  right: -60px;
  bottom: -2px;
}

@media (max-width: 991px) {
  .cta-image {
    position: relative;
    right: 0;
  }
}

.blog__image {
  position: relative;
}

.blog__category {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding: 10px 20px;
}

.blog__info {
  display: flex;
  margin: -6px;
}

.blog__info a {
  padding: 6px;
  color: #B88768;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.blog__title {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
}

.blog__button {
  margin-top: 20px;
}

.blog.-hover .blog__image {
  overflow: hidden;
}

.blog.-hover .blog__image img {
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.blog.-hover:hover .blog__image img {
  transform: scale(1.1);
}

.blog.-hover:hover .blog__info {
  transform: none;
}

.blog.-hover:hover .blog__title {
  transform: none;
}

.clients__image {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  .clients__image img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 767px) {
  .clients__image img {
    width: 80%;
    height: auto;
  }
}

.portfolio__content {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 40px;
  padding-bottom: 16px;
  width: 650px;
  max-width: 80%;
  background-color: white;
  opacity: 0;
  transition: all 0.3s ease-out 0.2s;
}

@media (max-width: 991px) {
  .portfolio__content {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.portfolio__category {
  color: #D7926B;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.portfolio__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
}

@media (max-width: 991px) {
  .portfolio__title {
    font-size: 18px;
    line-height: 21px;
  }
}

.swiper-slide-active .portfolio__content {
  opacity: 1;
}

.portfolioCard.-type-1 {
  position: relative;
  display: block;
}

.portfolioCard.-type-1:hover .portfolioCard__content {
  transform: none;
  opacity: 1;
}

.portfolioCard.-type-1 .portfolioCard__content {
  position: absolute;
  bottom: 0.625rem;
  left: 0.625rem;
  right: 0.625rem;
  background-color: white;
  transform: translateY(-16px);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.portfolioCard.-type-1 .portfolioCard__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.portfolioCard.-type-2 {
  position: relative;
}

.portfolioCard.-type-2 .portfolioCard__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  padding-left: 2.5rem;
  padding-bottom: 1.875rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.portfolioCard.-type-2 .portfolioCard__content a {
  position: relative;
  z-index: 4;
}

.portfolioCard.-type-2 .portfolioCard__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.portfolioCard.-zoom .portfolioCard__img {
  overflow: hidden;
}

.portfolioCard.-zoom .portfolioCard__img > div {
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.portfolioCard.-zoom:hover .portfolioCard__img > div {
  transform: scale(1.1);
}

.portfolioCard.-type-2.-hover .portfolioCard__img {
  overflow: hidden;
}

.portfolioCard.-type-2.-hover .portfolioCard__img__inner {
  transform: scale(1.01);
  transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.portfolioCard.-type-2.-hover .portfolioCard__content {
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.portfolioCard.-type-2.-hover .portfolioCard__content p,
.portfolioCard.-type-2.-hover .portfolioCard__content a {
  opacity: 0;
  transform: translateY(2rem);
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.portfolioCard.-type-2.-hover .portfolioCard__content h3 {
  opacity: 0;
  transform: translateY(2rem);
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.06s, transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.06s;
}

.portfolioCard.-type-2.-hover:hover .portfolioCard__img__inner {
  transform: scale(1.08);
}

.portfolioCard.-type-2.-hover:hover .portfolioCard__content {
  opacity: 1;
}

.portfolioCard.-type-2.-hover:hover .portfolioCard__content a,
.portfolioCard.-type-2.-hover:hover .portfolioCard__content p,
.portfolioCard.-type-2.-hover:hover .portfolioCard__content h3 {
  opacity: 1;
  transform: none;
}

.portfolioCard.-type-3 {
  position: relative;
  display: block;
}

.portfolioCard.-type-3:hover .portfolioCard__content {
  transform: none;
  opacity: 1;
}

.portfolioCard.-type-3 .portfolioCard__content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #E4E4E4;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transform: translateY(-10px);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.portfolioCard.-type-3 .portfolioCard__content::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: #D7926B;
}

.portfolioCard.-type-3 .portfolioCard__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.sectionSlider {
  width: 100%;
}

.pagination.-slider {
  display: flex;
  justify-content: center;
}

.pagination.-slider .pagination__item {
  width: 0.5625rem;
  height: 0.5625rem;
  background-color: transparent;
  border: 1px solid var(--accent-color);
  border-radius: 100%;
}

.pagination.-slider .pagination__item.is-active {
  background-color: var(--accent-color);
}

.pagination.-slider.-white .pagination__item {
  border: 1px solid white;
}

.pagination.-slider.-white .pagination__item.is-active {
  background-color: white;
}

.pagination.-slider .pagination__item + .pagination__item {
  margin-left: 0.625rem;
}

.pagination.-slider.-left {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  flex-direction: column;
}

.pagination.-slider.-left .pagination__item + .pagination__item {
  margin-left: 0;
  margin-top: 18px;
}

.sectionSlider .nav.-slider {
  display: flex;
}

.sectionSlider .nav.-slider .nav__item {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 100%;
  background-color: #F5F7FE;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.sectionSlider .nav.-slider .nav__item .icon {
  position: relative;
  font-size: 1.875rem;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.sectionSlider .nav.-slider .nav__item:hover {
  background-color: var(--accent-color);
}

.sectionSlider .nav.-slider .nav__item:hover .icon {
  color: white;
}

.sectionSlider .nav.-slider.-absolute-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

@media (max-width: 991px) {
  .sectionSlider .nav.-slider.-absolute-center {
    position: relative;
    top: unset;
    transform: unset;
  }
}

.sectionSlider .scrollbar.-slider {
  position: relative;
  height: 4px;
  width: 100%;
  background-color: #F5F4F2;
  border-radius: 0.625rem;
}

.sectionSlider .scrollbar.-slider > div {
  height: 100%;
  background-color: #C6C6C6;
  border-radius: 0.625rem;
}

body.is-cursor-active {
  cursor: none !important;
}

body.is-cursor-active * {
  cursor: none !important;
}

.cursor {
  position: fixed;
  pointer-events: none;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.cursor__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.cursor__follower {
  position: absolute;
  background-color: #888888;
  opacity: 0.25;
  border-radius: 100%;
  width: 1.5rem;
  height: 1.5rem;
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), background-color 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.cursor__arrows {
  position: absolute;
  display: flex;
  opacity: 0;
  transform: scale(0.7) rotate(40deg);
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.cursor__arrows > * {
  position: relative;
  color: black;
  stroke-width: 1;
}

.cursor__arrows > *:first-child {
  left: -0.625rem;
}

.cursor__arrows > *:last-child {
  right: -0.625rem;
}

.cursor__label {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
  transform: scale(0);
  will-change: transform, opacity;
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.cursor__icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  transform: scale(0);
  will-change: transform, opacity;
  transition: transform 500ms cubic-bezier(0.165, 0.84, 0.44, 1), opacity 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.cursor__icon svg {
  width: 48px;
  height: 48px;
  stroke-width: 0.75;
}

.cursor__slider_icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  transform: scale(0);
  will-change: transform, opacity;
  transition: transform 500ms cubic-bezier(0.165, 0.84, 0.44, 1), opacity 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.cursor__slider_icon svg {
  width: 48px;
  height: 48px;
  stroke-width: 0.75;
}

.cursor {
  visibility: hidden;
}

.cursor.is-enabled {
  visibility: visible;
}

.cursor.is-hidden .cursor__wrapper {
  transform: scale(0) !important;
}

.cursor.is-mouse-down .cursor__wrapper {
  transform: scale(0.8);
}

.cursor.is-active .cursor__follower {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
  transform: scale(3.4);
  opacity: 0.25;
}

.cursor.is-active.has-label .cursor__follower {
  background-color: #01213A;
  opacity: 1;
}

.cursor.is-active.has-label .cursor__label {
  transform: scale(1);
}

.cursor.is-active.has-label-light .cursor__follower {
  box-shadow: 0px 5px 70px rgba(1, 33, 58, 0.03);
  background-color: #ffffff;
  opacity: 1;
}

.cursor.is-active.has-label-light .cursor__label {
  color: var(--bg-dark-1) !important;
  transform: scale(1);
}

.cursor.is-active.has-icon .cursor__follower {
  background-color: black;
  opacity: 0.8;
}

.cursor.is-active.has-icon .cursor__icon {
  transform: scale(1);
}

.cursor.is-active.has-slider-icon .cursor__follower {
  background-color: black;
  opacity: 0.8;
}

.cursor.is-active.has-slider-icon .cursor__slider_icon {
  transform: scale(1);
}

body.preloader-visible .preloader {
  opacity: 1;
}

.preloader {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  will-change: transform;
  transform-origin: bottom;
  opacity: 1;
  background-color: white;
  display: none !important;
}

.bar-stroke-hidden {
  stroke-width: 0px !important;
}

.pieChart__chart {
  transform: rotate(-90deg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pieChart circle {
  stroke-dashoffset: 0;
  fill: transparent;
}

.pieChart circle.pieChart__bar {
  stroke: var(--accent-color);
  stroke-width: 1.5px;
}

.pieChart__back {
  stroke: black;
  stroke-width: 1.5px !important;
}

.pieChart__percent {
  transform: rotate(90deg);
  position: absolute;
  font-size: 35px;
  line-height: 41px;
  font-weight: 600;
}

@media (max-width: 991px) {
  .pieChart__percent {
    font-size: 18px;
    line-height: 21px;
  }
}

.pieChart__title {
  margin-top: 30px;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
}

@media (max-width: 991px) {
  .pieChart__title {
    margin-top: 20px;
    font-size: 16px;
    line-height: 19px;
  }
}

.pieChart__text {
  margin-top: 14px;
  font-size: 14px;
  line-height: 24px;
}

@media (max-width: 991px) {
  .pieChart__text {
    margin-top: 8px;
  }
}

.pieChart {
  transition: background-color 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.pieChart .pieChart-title,
.pieChart .pieChart-text,
.pieChart .pieChart-percent {
  transition: color 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.pieChart-light circle {
  stroke: rgba(255, 255, 255, 0.2);
}

.pieChart-light:hover {
  background-color: white;
}

.pieChart-light:hover .pieChart-percent,
.pieChart-light:hover .pieChart-title {
  color: black;
}

.pieChart-light:hover .pieChart-text {
  color: var(--font-dark);
}

.pieChart-dark circle {
  stroke: rgba(0, 0, 0, 0.1);
}

.pieChart-dark:hover {
  background-color: black;
}

.pieChart-dark:hover .pieChart-percent,
.pieChart-dark:hover .pieChart-title {
  color: white;
}

.pieChart-dark:hover .pieChart-text {
  color: var(--font-light);
}

.lineChart__item {
  margin-top: 2rem;
  margin-top: 2rem;
}

.lineChart__item:first-child {
  margin-top: 0;
}

.lineChart__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.lineChart__content p {
  font-size: inherit;
  color: inherit;
  line-height: inherit;
}

.lineChart-bar {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 0.375rem;
  border-radius: 200px;
}

.lineChart-bar__item {
  width: 100%;
  height: 100%;
  transform-origin: 0% 50%;
  transform: scaleX(0);
  border-radius: 200px;
}

.lineChart-bar__bg {
  position: absolute;
  width: 100%;
  height: 0.375rem;
  border-radius: 200px;
  background-color: rgba(0, 0, 0, 0.1);
}

.lineChart-bar__bg.-light {
  background-color: rgba(255, 255, 255, 0.1);
}

.mega {
  cursor: auto;
  z-index: 2000;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 2.5rem 1.25rem;
  padding-bottom: 1.25rem;
  display: flex;
  width: 52.5rem;
  border-radius: 0.5rem;
  background-color: white;
  border: 1px solid #EEEEEE;
  box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  pointer-events: none;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .mega {
    left: 50%;
    transform: translateX(-50%);
  }
}

.mega__menu {
  width: 100%;
}

.mega__menu .row {
  width: 100%;
}

.mega__column {
  display: flex;
  flex-direction: column;
}

.mega__list a {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  padding: 0.8125rem 1.25rem !important;
  line-height: 1;
  color: var(--font-dark) !important;
  border-radius: 0.5rem;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.mega__list a:hover {
  background-color: #F5F7FE;
}

.mega-cta {
  position: relative;
  width: 16.875rem;
  flex: 0 1 auto;
}

.mega-cta__img {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mega-cta__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0.25rem;
}

.mega-cta__content {
  padding: 5.625rem 1.875rem;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mega-cta__subtitle {
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.mega-cta__title {
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1.5;
}

.accordion__item {
  padding: 0.5rem 0;
}

.accordion__content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

.accordion__button {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0.75rem 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transition: 0.4s;
}

.accordion__button button {
  color: #454545;
}

.accordion__button:active {
  color: #454545;
}

.accordion.-simple .accordion__item {
  padding: 0;
}

.accordion.-simple .accordion__content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

.accordion.-simple .accordion__content__inner {
  padding-bottom: 1.25rem;
}

.accordion.-simple .accordion__button {
  cursor: pointer;
  color: var(--text-dark-1);
  padding: 1.1875rem 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  outline: none;
  transition: 0.4s;
}

.accordion.-simple .accordion__button button {
  color: var(--text-dark-1);
}

.accordion.-simple .accordion__icon {
  position: relative;
  margin-right: 0.9375rem;
}

.accordion.-simple .accordion__icon .icon {
  width: 1.5rem;
  height: 1.5rem;
  stroke-width: 2;
  transition: all 0.2s ease-out;
}

.accordion.-simple .accordion__icon .icon:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
}

.accordion.-simple .accordion__icon .icon:nth-child(2) {
  position: relative;
  transform: rotate(-90deg);
  opacity: 0;
}

.accordion.-simple .accordion__item.is-active .accordion__icon > *:nth-child(1) {
  transform: rotate(90deg);
  opacity: 0;
}

.accordion.-simple .accordion__item.is-active .accordion__icon > *:nth-child(2) {
  transform: none;
  opacity: 1;
}

.accordion.-simple.-light .accordion__item + .accordion__item {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.accordion.-bordered .accordion__item {
  padding: 0;
}

.accordion.-bordered .accordion__item + .accordion__item {
  margin-top: 1.25rem;
}

.accordion.-bordered .accordion__content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

.accordion.-bordered .accordion__content__inner {
  padding: 2.25rem 4.8125rem 2.25rem 1.4375rem;
}

.accordion.-bordered .accordion__button {
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--text-dark-1);
  padding: 1.1875rem 1.4375rem;
  width: 100%;
  border: 1px solid #EEEEEE;
  border-radius: 1rem;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.accordion.-bordered .accordion__button button {
  color: var(--text-dark-1);
}

.accordion.-bordered .accordion__icon {
  position: relative;
  margin-right: 0.9375rem;
}

.accordion.-bordered .accordion__icon .icon {
  width: 1.5rem;
  height: 1.5rem;
  stroke-width: 2;
  transition: all 0.2s ease-out;
}

.accordion.-bordered .accordion__icon .icon:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
}

.accordion.-bordered .accordion__icon .icon:nth-child(2) {
  position: relative;
  transform: rotate(-90deg);
  opacity: 0;
}

.accordion.-bordered .accordion__item.is-active .accordion__button {
  box-shadow: 0px 5px 70px rgba(1, 33, 58, 0.03);
}

.accordion.-bordered .accordion__item.is-active .accordion__icon > *:nth-child(1) {
  transform: rotate(90deg);
  opacity: 0;
}

.accordion.-bordered .accordion__item.is-active .accordion__icon > *:nth-child(2) {
  transform: none;
  opacity: 1;
}

.accordion.-block.-light .accordion__item + .accordion__item {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.tabs__content {
  position: relative;
}

.tabs__pane {
  position: absolute;
  top: 0;
  visibility: hidden;
  transition: none;
  opacity: 0;
}

.tabs__pane.is-active {
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.1s;
  position: relative;
  opacity: 1;
  visibility: visible;
}

.tabs-group {
  border: 1px solid var(--purple-3-color);
  padding: 4px 4px;
  border-radius: 200px;
}

@media (max-width: 991px) {
  .tabs-group {
    padding: 12px;
    border-radius: 16px;
  }
}

.tabs-group .is-active {
  background-color: var(--purple-3-color);
  color: white !important;
}

.tabs-group .tabs__button {
  width: 100%;
}

.tabs-group .col-md {
  padding: 0;
}

.tabs-line {
  position: relative;
}

.tabs-line::after {
  content: "";
  width: 2px;
  height: calc(100% - 160px);
  z-index: 1;
  position: absolute;
  top: 90px;
  left: 45px;
  background-color: var(--bg-light-4);
}

@media (max-width: 767px) {
  .tabs-line::after {
    display: none;
  }
}

.tabs.-buttons .tabs__button {
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  background-color: var(--bg-light-4);
}

.tabs.-buttons .tabs__button.is-active {
  color: white !important;
  background-color: var(--accent-color) !important;
}

.tabs.-buttons .tabs__button:not(:first-child) {
  margin-left: 0.75rem;
}

.tabs.-buttons .tabs__pane {
  display: none;
  opacity: 0;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tabs.-buttons .tabs__pane.is-active {
  display: block;
  opacity: 1;
}

.tabs.-active-1 .tabs__controls button.is-active {
  color: black !important;
  background-color: white !important;
}

.tabs.-active-2 .tabs__controls .tabs__button.is-active {
  background-color: var(--bg-dark-5) !important;
}

.tabs.-active-3 .tabs__controls .tabs__button.is-active .-tab-active {
  background-color: var(--dark-blue-color) !important;
  color: white !important;
}

.tabs.-active-accent .tabs__controls .tabs__button.is-active {
  color: var(--accent-color) !important;
}

.tabs.-lines .tabs__controls {
  border-bottom: 1px solid #EEEEEE;
  display: flex;
}

.tabs.-lines .tabs__button {
  margin-bottom: -1px;
  padding: 0.625rem 0.75rem;
  padding-bottom: 2.125rem;
  font-weight: 600;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 100%;
}

.tabs.-lines .tabs__button.is-active {
  color: var(--accent-color);
  border-bottom: 1px solid var(--accent-color);
}

.tabs.-lines .tabs__button + .tabs__button {
  margin-left: 0.5rem;
}

.tabs.-lines-small .tabs__controls {
  border-bottom: 1px solid #EEEEEE;
  display: flex;
}

.tabs.-lines-small .tabs__button {
  margin-bottom: -1px;
  padding: 0.625rem 0.75rem;
  font-weight: 600;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-bottom: 2px solid transparent;
}

.tabs.-lines-small .tabs__button.is-active {
  color: var(--accent-color) !important;
  border-bottom: 2px solid var(--accent-color);
}

.tabs.-lines-small .tabs__button + .tabs__button {
  margin-left: 0.5rem;
}

.tabs.-lines-small .tabs__pane {
  display: none;
  opacity: 0;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tabs.-lines-small .tabs__pane.is-active {
  display: block;
  opacity: 1;
}

.tabs.-button-bg-white .tabs__button.is-active {
  background-color: white !important;
  box-shadow: 0px 5px 70px rgba(1, 33, 58, 0.03);
}

.tabs.-button-bg-white .tabs__pane {
  display: none;
  opacity: 0;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tabs.-button-bg-white .tabs__pane.is-active {
  display: block;
  opacity: 1;
}

.table {
  width: 100%;
  caption-side: bottom;
  border-collapse: collapse;
}

.table thead {
  background-color: var(--bg-light-4);
  color: var(--accent-color);
}

.table th {
  padding: 1.5625rem 1.5625rem;
}

.table tbody tr {
  border-bottom: 1px solid #EEEEEE;
}

.table td {
  padding: 1.5625rem 1.5625rem;
  color: #615E5E;
}

.table.-striped > tbody > tr:nth-of-type(even) {
  background-color: #F6F8F9;
}

.alert__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.625rem;
  border-radius: 0.25rem;
}

.alert__title {
  font-weight: 600;
}

.alert.-info .alert__content {
  background-color: #CDE9F6;
}

.alert.-info .alert__icon .icon,
.alert.-info .alert__title {
  color: #4780AA;
}

.alert.-warning .alert__content {
  background-color: #F7F3D7;
}

.alert.-warning .alert__icon .icon,
.alert.-warning .alert__title {
  color: #927238;
}

.alert.-error .alert__content {
  background-color: #ECC8C5;
}

.alert.-error .alert__icon .icon,
.alert.-error .alert__title {
  color: #AB3331;
}

.alert.-success .alert__content {
  background-color: #DEF2D7;
}

.alert.-success .alert__icon .icon,
.alert.-success .alert__title {
  color: #5B7052;
}

.shopCard__image {
  z-index: 5;
  position: relative;
}

.shopCard__image:hover .shopCard__button {
  opacity: 1;
}

.shopCard__button {
  z-index: 10;
  position: absolute;
  bottom: 0.625rem;
  left: 0.625rem;
  right: 0.625rem;
  font-size: 0.875rem;
  line-height: 1rem;
  color: white;
  display: flex;
  justify-content: center;
  padding: 1.125rem;
  align-items: center;
  text-transform: uppercase;
  background-color: #18130C;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.shopCard__button .icon {
  margin-right: 0.875rem;
  stroke-width: 1;
}

.shopCart-header {
  padding: 1.25rem 4.375rem;
  padding-right: 0;
  background-color: #F9F6F6;
}

@media (max-width: 1199px) {
  .shopCart-header {
    padding-left: 2.5rem;
  }
}

.shopCart-header__title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1875rem;
  text-transform: uppercase;
  color: black;
}

@media (max-width: 767px) {
  .shopCart-products .shopCart-products__cross {
    left: unset;
    top: 2.25rem;
    right: 0;
  }
  .shopCart-products .shopCart-products__item {
    padding-left: 0rem;
  }
}

.shopCart-products__title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1875rem;
  text-transform: uppercase;
  color: black;
}

.shopCart-products__item {
  position: relative;
  padding: 1.875rem 0;
  padding-left: 4.375rem;
  border-bottom: 1px solid #E4E4E4;
}

@media (max-width: 1199px) {
  .shopCart-products__item {
    padding-left: 2.5rem;
  }
}

.shopCart-products__cross {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.shopCart-products__cross .icon {
  height: 1.5rem;
  width: 1.5rem;
  stroke-width: 1.5;
}

.shopCart-products__product {
  display: flex;
  align-items: center;
}

.shopCart-products__product .image {
  position: relative;
  width: 5rem;
  height: 5rem;
  min-width: 5rem;
  min-height: 5rem;
}

.shopCart-products__product .image > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.shopCart-products__product .title {
  margin-left: 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: black;
}

.shopCart-products__price {
  font-size: 0.875rem;
  line-height: 1rem;
}

.shopCart-products__quantity {
  font-size: 0.875rem;
  line-height: 1rem;
}

.shopCart-products__subtotal {
  font-size: 0.875rem;
  line-height: 1rem;
}

.shopCart-sidebar {
  width: 21.5625rem;
}

@media (max-width: 1349px) {
  .shopCart-sidebar {
    width: 15rem;
  }
}

@media (max-width: 991px) {
  .shopCart-sidebar {
    width: 100%;
    margin-top: 3.75rem;
  }
}

.shopCart-sidebar__totals {
  border: 1px solid #E4E4E4;
  padding: 1.875rem;
}

.shopCart-sidebar__totals .title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1875rem;
  color: black;
  margin-bottom: 1.75rem;
}

.shopCart-sidebar__totals .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  line-height: 2.8125rem;
  text-align: right;
}

.shopCart-sidebar__totals .item.-border-none {
  border: none !important;
}

.shopCart-sidebar__totals .item + .item {
  border-top: 1px solid #E4E4E4;
}

.shopCart-sidebar__button {
  margin-top: 1.25rem;
}

.shopCart-footer__item {
  display: flex;
  align-items: flex-end;
}

@media (max-width: 1349px) {
  .shopCart-footer__item button {
    min-width: unset !important;
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
}

.shopCart-footer__item form {
  width: 15.625rem;
}

@media (max-width: 767px) {
  .shopCart-footer__item form {
    width: 9.375rem;
  }
}

.shopCart-footer__item input {
  font-size: 0.875rem;
  line-height: 1.5rem;
  border-bottom: 1px solid #E4E4E4 !important;
}

.shopCart-footer__item input:focus {
  border-bottom: 1px solid #000 !important;
}

.shopCheckout-form__title {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.3125rem;
  letter-spacing: 0em;
}

@media (max-width: 991px) {
  .shopSidebar {
    margin-top: 7.5rem;
  }
}

@media (max-width: 767px) {
  .shopSidebar {
    margin-top: 6.25rem;
  }
}

.shopSidebar__item .search > form {
  position: relative;
  display: flex;
  align-items: center;
}

.shopSidebar__item .search .field {
  z-index: 1;
  padding-top: 0;
  font-size: 14px;
  line-height: 24px;
  padding: 12px;
  padding-left: 28px;
  border-bottom: 1px solid #EEEEEE;
}

.shopSidebar__item .search .submit {
  z-index: 5;
  position: absolute;
  left: 0;
  padding-bottom: 4px;
}

.shopSidebar__item .search .submit::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: calc(100% + 8px);
  height: calc(100% + 8px);
}

.shopSidebar__item .search .icon {
  width: 22px;
  height: 22px;
  stroke-width: 1.5;
}

.shopSidebar__item.-category a span:first-child {
  position: relative;
}

.shopSidebar__item.-category a span:first-child::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -0.25rem;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform-origin: left;
  transform: scaleX(0);
}

.shopSidebar__item.-category a span:first-child:hover {
  color: black;
}

.shopSidebar__item.-category a span:first-child:hover::after {
  transform: scaleX(1);
}

.shopSidebar__item.-best .best__item {
  display: flex;
}

.shopSidebar__item.-best .best__image {
  position: relative;
  width: 5rem;
  height: 5rem;
  min-width: 5rem;
  min-height: 5rem;
}

.shopSidebar__item.-best .best__image > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.shopSidebar__item.-best .best__content {
  margin-left: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.shopSidebar__item.-tags .tags__wrap {
  display: flex;
  flex-wrap: wrap;
  margin: -0.3125rem;
}

.shopSidebar__item.-tags .tags__item {
  padding: 0.3125rem;
}

.shopSidebar__item.-tags .tags__item a {
  display: block;
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0.5rem 1.125rem;
  background-color: #F9F6F6;
  color: #615E5E;
  transition: all 0.1s ease-in-out;
}

.shopSidebar__item.-tags .tags__item a:hover {
  background-color: #000 !important;
  color: #FFF !important;
}

.shopSingle-preview {
  display: flex;
}

@media (max-width: 991px) {
  .shopSingle-preview {
    flex-direction: column-reverse;
  }
}

.shopSingle-preview__imageList {
  width: 140px;
  margin: -5px -10px;
  padding-right: 10px;
}

.shopSingle-preview__imageList > * {
  padding: 5px 10px;
}

@media (max-width: 991px) {
  .shopSingle-preview__imageList {
    padding-right: 0;
    padding-top: 0.625rem;
    display: flex;
    width: 100%;
    margin: -5px;
  }
  .shopSingle-preview__imageList > * {
    padding: 5px;
    width: 100%;
  }
}

.shopSingle-preview__image {
  overflow: hidden;
  width: 100%;
}

.shopSingle-info__subtitle {
  font-size: 0.875rem;
  line-height: 1;
}

.shopSingle-info__title {
  font-size: 2.1875rem;
  line-height: 1.2;
  font-weight: 600;
}

.shopSingle-info__content p {
  font-size: 0.875rem;
  line-height: 1.6;
}

.shopSingle-info__content p + p {
  margin-top: 1rem;
}

.shopSingle-info__action {
  display: flex;
  flex-wrap: wrap;
}

.shopSingle-info__footer span {
  display: block;
  font-size: 0.875rem;
  line-height: 1.6;
}

.shopCompleted-header {
  text-align: center;
}

.shopCompleted-header .icon {
  margin: 0 auto;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--accent-color);
  border-radius: 100%;
}

.shopCompleted-header .icon svg {
  stroke-width: 4;
  color: white;
  width: 2.25rem;
  height: 2.25rem;
}

.shopCompleted-header .title {
  font-size: 2.5rem;
  line-height: 1.1;
  font-weight: 600;
  letter-spacing: 0em;
  margin-top: 1.25rem;
}

@media (max-width: 767px) {
  .shopCompleted-header .title {
    font-size: 2rem;
  }
}

.shopCompleted-header .subtitle {
  font-size: 0.875rem;
  line-height: 1rem;
  margin-top: 0.625rem;
}

.shopCompleted-info {
  margin-top: 2.5rem;
  border: 1px dashed var(--accent-color);
  border-radius: 0.5rem;
  background-color: var(--accent-light-color);
  padding: 2.5rem 3.25rem;
}

@media (max-width: 767px) {
  .shopCompleted-info {
    padding: 2.125rem;
  }
}

.shopCompleted-info__item .subtitle {
  font-size: 0.875rem;
}

.shopCompleted-info__item .title {
  font-size: 0.9375rem;
  margin-top: 0.9375rem;
  font-weight: 600;
}

.shopCompleted-footer {
  margin-top: 3.75rem;
}

@media (max-width: 767px) {
  .shopCompleted-footer {
    margin-top: 2.5rem;
  }
}

.shopCompleted-footer__wrap {
  padding: 3.75rem;
}

@media (max-width: 767px) {
  .shopCompleted-footer__wrap {
    padding: 2.125rem;
  }
}

.shopCompleted-footer__wrap .title {
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1.75rem;
}

.shopCompleted-footer__wrap .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  line-height: 3.75rem;
  text-align: right;
}

.shopCompleted-footer__wrap .item.-border-none {
  border: none !important;
}

.shopCompleted-footer__wrap .item + .item {
  border-top: 1px solid #E4E4E4;
  margin-top: 0.1875rem;
}

.shopCompleted-footer__button {
  margin-top: 1.25rem;
}

/*--------------------------------------------------
	Blog comments
---------------------------------------------------*/
.blogPost.-comments .blogPost__content .comments__list li {
  list-style: none;
}

.blogPost.-comments .blogPost__content .comments__list > li:first-child > .comments-item-inner {
  border-top: none;
}

.blogPost.-comments .blogPost__content .comments__list > li:first-child {
  border-top: none;
}

.blogPost.-comments .blogPost__content .comments__list .trackback a,
.blogPost.-comments .blogPost__content .comments__list .pingback a {
  line-height: 1.5rem;
  text-decoration: none !important;
}

.blogPost.-comments .blogPost__content .comments__list {
  margin: 0;
}

.blogPost.-comments .blogPost__content .comments__list > li {
  margin-bottom: 3.75rem;
}

@media (max-width: 767px) {
  .blogPost.-comments .blogPost__content .comments__list > li {
    margin-top: 2.5rem;
  }
}

.blogPost.-comments .blogPost__content .comments__item-inner {
  display: flex;
}

.blogPost.-comments .blogPost__content .comments__item .children {
  padding: 0;
  margin: 0;
  padding-left: 5%;
  padding-top: 2rem;
}

.blogPost.-comments .blogPost__content .comments__body {
  width: 100%;
}

.blogPost.-comments .blogPost__content .comments__img {
  flex: 0 0 auto;
  width: 90px;
  height: 90px;
  max-width: 5.625rem;
  margin-right: 1.375rem;
}

@media (max-width: 767px) {
  .blogPost.-comments .blogPost__content .comments__img {
    width: 48px;
    height: 48px;
    max-width: 3rem;
    margin-right: 1rem;
  }
}

.blogPost.-comments .blogPost__content .comments__header h4 {
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 600;
}

.blogPost.-comments .blogPost__content .comments__header p {
  font-size: 0.875rem;
  line-height: 1.6;
}

.blogPost.-comments .blogPost__content .comments__text p {
  margin: 0;
  margin-top: 0.875rem;
  font-size: 0.875rem;
  line-height: 1.6;
}

.blogPost.-comments .blogPost__content .comments__text a {
  text-decoration: underline;
}

.blogPost.-comments .blogPost__content .comments__text ul, .blogPost.-comments .blogPost__content .comments__text ul li {
  list-style: disc inside !important;
}

.blogPost.-comments .blogPost__content .comments__text ol, .blogPost.-comments .blogPost__content .comments__text ol li {
  list-style: decimal inside !important;
}

.blogPost.-comments .blogPost__content .comments__reply a {
  margin-top: 0.625rem;
  text-decoration: underline;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.8;
}

/*--------------------------------------------------
	Respond form
---------------------------------------------------*/
.respondForm h3 {
  margin: 0;
}

.respondForm h3 small a {
  text-transform: uppercase;
  letter-spacing: 0.06em;
  font-size: 0.9375rem;
  color: var(--accent-color);
  margin-left: 0.5rem;
}

.respondForm__notes {
  color: var(--font-dark);
  font-size: 1rem;
}

.respondForm .logged-in-as {
  display: flex;
  margin-bottom: 0.75rem;
}

.respondForm .logged-in-as a {
  padding-right: 1rem;
}

.respondForm form p {
  font-size: 0.9375rem;
  line-height: 1;
}

.blogSingle-info .tags__wrap {
  display: flex;
  flex-wrap: wrap;
  margin: -0.3125rem;
}

.blogSingle-info .tags__item {
  padding: 0.3125rem;
}

.blogSingle-info .tags__item a {
  display: block;
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0.5rem 1.125rem;
  background-color: #F9F6F6;
  color: #615E5E;
  transition: all 0.1s ease-in-out;
}

.blogSingle-info .tags__item a:hover {
  background-color: #000 !important;
  color: #FFF !important;
}

.blogSingle-author {
  display: flex;
  padding-bottom: 3.75rem;
  border-bottom: 1px solid #E4E4E4;
}

.blogSingle-author__image {
  position: relative;
  width: 10rem;
  height: 10rem;
  min-width: 10rem;
  min-height: 10rem;
}

@media (max-width: 767px) {
  .blogSingle-author__image {
    width: 5rem;
    height: 5rem;
    min-width: 5rem;
    min-height: 5rem;
  }
}

@media (max-width: 575px) {
  .blogSingle-author__image {
    width: 3.75rem;
    height: 3.75rem;
    min-width: 3.75rem;
    min-height: 3.75rem;
  }
}

.blogSingle-author__image > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.blogSingle-author__socials {
  margin-left: -0.75rem;
}

.sliderNav {
  position: absolute;
  top: -5.125rem;
  right: 0;
  display: flex;
}

@media (max-width: 991px) {
  .sliderNav {
    position: relative;
    top: unset;
    margin-bottom: 1.5rem;
  }
}

.sliderNav__item {
  display: flex;
  align-items: center;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.sliderNav__item:hover {
  color: var(--accent-color) !important;
}

.sliderNav__item.-prev::after {
  content: '';
  width: 3.75rem;
  height: 1px;
  background-color: currentColor;
  margin-left: 0.625rem;
}

.sliderNav__item.-next::before {
  content: '';
  width: 3.75rem;
  height: 1px;
  background-color: currentColor;
  margin-right: 0.625rem;
}

.sliderNav .sliderNav__item + .sliderNav__item {
  margin-left: 1.25rem;
}

.filter-button-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -10px;
}

.filter-button-group button {
  padding: 10px;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
}

.filter-button-group button:hover {
  color: var(--accent-color) !important;
}

.filter-button-group button.btn-active {
  color: black;
  text-decoration: underline;
}

.masonry__item .portfolioCard__img,
.masonry__item .portfolioCard__img__inner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.masonry .masonry__item.-no-ratio > .ratio {
  padding-bottom: unset !important;
}

.masonry .masonry__item > .ratio {
  padding-bottom: 100%;
}

.masonry .masonry__item.-r-120 > .ratio {
  padding-bottom: 120%;
}

.masonry .masonry__item.-long > .ratio {
  padding-bottom: 200%;
}

.masonry .masonry__item.-big > .ratio {
  padding-bottom: 100%;
}

.masonry .masonry__item.-wide > .ratio {
  padding-bottom: 50%;
}

.masonry.-col-4 .masonry__sizer {
  width: 25%;
}

.masonry.-col-4 .masonry__item {
  width: 25%;
}

.masonry.-col-4 .masonry__item.-long {
  width: 25%;
}

.masonry.-col-4 .masonry__item.-big {
  width: 50%;
}

.masonry.-col-4 .masonry__item.-wide {
  width: 50%;
}

@media (max-width: 991px) {
  .masonry.-col-4 .masonry__sizer {
    width: 33.33333%;
  }
  .masonry.-col-4 .masonry__item {
    width: 33.33333%;
  }
  .masonry.-col-4 .masonry__item.-long {
    width: 33.33333%;
  }
  .masonry.-col-4 .masonry__item.-big {
    width: 66.66666%;
  }
  .masonry.-col-4 .masonry__item.-wide {
    width: 66.66666%;
  }
}

@media (max-width: 767px) {
  .masonry.-col-4 .masonry__sizer {
    width: 50%;
  }
  .masonry.-col-4 .masonry__item {
    width: 50%;
  }
  .masonry.-col-4 .masonry__item.-long {
    width: 50%;
  }
  .masonry.-col-4 .masonry__item.-big {
    width: 100%;
  }
  .masonry.-col-4 .masonry__item.-wide {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .masonry.-col-4 .masonry__sizer {
    width: 100%;
  }
  .masonry.-col-4 .masonry__item {
    width: 100%;
  }
  .masonry.-col-4 .masonry__item.-long {
    width: 100%;
  }
  .masonry.-col-4 .masonry__item.-big {
    width: 100%;
  }
  .masonry.-col-4 .masonry__item.-wide {
    width: 100%;
  }
}

.masonry.-col-3 .masonry__sizer {
  width: 33.33333%;
}

.masonry.-col-3 .masonry__item {
  width: 33.33333%;
}

.masonry.-col-3 .masonry__item.-long {
  width: 33.33333%;
}

.masonry.-col-3 .masonry__item.-big {
  width: 66.66666%;
}

.masonry.-col-3 .masonry__item.-wide {
  width: 66.66666%;
}

@media (max-width: 767px) {
  .masonry.-col-3 .masonry__sizer {
    width: 50%;
  }
  .masonry.-col-3 .masonry__item {
    width: 50%;
  }
  .masonry.-col-3 .masonry__item.-long {
    width: 50%;
  }
  .masonry.-col-3 .masonry__item.-big {
    width: 100%;
  }
  .masonry.-col-3 .masonry__item.-wide {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .masonry.-col-3 .masonry__sizer {
    width: 100%;
  }
  .masonry.-col-3 .masonry__item {
    width: 100%;
  }
  .masonry.-col-3 .masonry__item.-long {
    width: 100%;
  }
  .masonry.-col-3 .masonry__item.-big {
    width: 100%;
  }
  .masonry.-col-3 .masonry__item.-wide {
    width: 100%;
  }
}

.masonry.-col-2 .masonry__sizer {
  width: 50%;
}

.masonry.-col-2 .masonry__item {
  width: 50%;
}

.masonry.-col-2 .masonry__item.-long {
  width: 50%;
}

.masonry.-col-2 .masonry__item.-big {
  width: 100%;
}

.masonry.-col-2 .masonry__item.-wide {
  width: 100%;
}

@media (max-width: 575px) {
  .masonry.-col-2 .masonry__sizer {
    width: 100%;
  }
  .masonry.-col-2 .masonry__item {
    width: 100%;
  }
  .masonry.-col-2 .masonry__item.-long {
    width: 100%;
  }
  .masonry.-col-2 .masonry__item.-big {
    width: 100%;
  }
  .masonry.-col-2 .masonry__item.-wide {
    width: 100%;
  }
}

.masonry.-gap-16 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.masonry.-gap-16 .masonry__item {
  padding: 0 0.5rem;
  margin-bottom: 1rem;
}

.masonry.-gap-16 .masonry__item.-no-ratio > .ratio {
  padding-bottom: unset !important;
}

.masonry.-gap-16 .masonry__item > .ratio {
  padding-bottom: 100%;
}

.masonry.-gap-16 .masonry__item.-r-120 > .ratio {
  padding-bottom: 120%;
}

.masonry.-gap-16 .masonry__item.-long > .ratio {
  padding-bottom: calc(200% + 1rem);
}

.masonry.-gap-16 .masonry__item.-big > .ratio {
  padding-bottom: 100%;
}

.masonry.-gap-16 .masonry__item.-wide > .ratio {
  padding-bottom: calc(50% - 0.5rem);
}

.masonry.-gap-32 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.masonry.-gap-32 .masonry__item {
  padding: 0 1rem;
  margin-bottom: 2rem;
}

.masonry.-gap-32 .masonry__item.-no-ratio > .ratio {
  padding-bottom: unset !important;
}

.masonry.-gap-32 .masonry__item > .ratio {
  padding-bottom: 100%;
}

.masonry.-gap-32 .masonry__item.-r-120 > .ratio {
  padding-bottom: 120%;
}

.masonry.-gap-32 .masonry__item.-long > .ratio {
  padding-bottom: calc(200% + 2rem);
}

.masonry.-gap-32 .masonry__item.-big > .ratio {
  padding-bottom: 100%;
}

.masonry.-gap-32 .masonry__item.-wide > .ratio {
  padding-bottom: calc(50% - 1rem);
}

.masonry.-gap-40 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.masonry.-gap-40 .masonry__item {
  padding: 0 1.25rem;
  margin-bottom: 2.5rem;
}

.masonry.-gap-40 .masonry__item.-no-ratio > .ratio {
  padding-bottom: unset !important;
}

.masonry.-gap-40 .masonry__item > .ratio {
  padding-bottom: 100%;
}

.masonry.-gap-40 .masonry__item.-r-120 > .ratio {
  padding-bottom: 120%;
}

.masonry.-gap-40 .masonry__item.-long > .ratio {
  padding-bottom: calc(200% + 2.5rem);
}

.masonry.-gap-40 .masonry__item.-big > .ratio {
  padding-bottom: 100%;
}

.masonry.-gap-40 .masonry__item.-wide > .ratio {
  padding-bottom: calc(50% - 1.25rem);
}

.masonry.-gap-48 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.masonry.-gap-48 .masonry__item {
  padding: 0 1.5rem;
  margin-bottom: 3rem;
}

.masonry.-gap-48 .masonry__item.-no-ratio > .ratio {
  padding-bottom: unset !important;
}

.masonry.-gap-48 .masonry__item > .ratio {
  padding-bottom: 100%;
}

.masonry.-gap-48 .masonry__item.-r-120 > .ratio {
  padding-bottom: 120%;
}

.masonry.-gap-48 .masonry__item.-long > .ratio {
  padding-bottom: calc(200% + 3rem);
}

.masonry.-gap-48 .masonry__item.-big > .ratio {
  padding-bottom: 100%;
}

.masonry.-gap-48 .masonry__item.-wide > .ratio {
  padding-bottom: calc(50% - 1.5rem);
}

.masonry.-gap-fancy {
  margin-left: -7.1875rem;
  margin-right: -7.1875rem;
}

.masonry.-gap-fancy .masonry__item {
  padding: 0 7.1875rem;
  margin-bottom: 5.625rem;
}

@media (max-width: 1199px) {
  .masonry.-gap-fancy {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .masonry.-gap-fancy .masonry__item {
    padding: 0 5rem;
    margin-bottom: 5rem;
  }
}

@media (max-width: 991px) {
  .masonry.-gap-fancy {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .masonry.-gap-fancy .masonry__item {
    padding: 0 1.25rem;
    margin-bottom: 2.5rem;
  }
}

.no-page {
  width: 100%;
  height: 100vh;
}

.no-page .container,
.no-page .row {
  height: 100%;
}

.no-page__bg {
  position: absolute;
  bottom: 0;
  right: 0;
}

.no-page__title {
  font-size: 12.5rem;
  line-height: 1;
}

@media (max-width: 991px) {
  .no-page__title {
    font-size: 7.5rem;
  }
}

@media (max-width: 767px) {
  .no-page__title {
    font-size: 5.625rem;
  }
}

.related-nav {
  border-top: 1px solid #EEEEEE;
}

.related-nav__icon {
  width: 24px;
  height: 24px;
}

.related-nav__icon span {
  display: block;
  width: 4px;
  height: 4px;
  background-color: var(--font-dark);
}

.related-nav__item {
  display: flex;
  flex-wrap: nowrap;
  margin-left: -10px;
  margin-right: -10px;
}

.related-nav__item > * {
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 991px) {
  .related-nav__item > * {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.related-nav__item:hover span,
.related-nav__item:hover .icon {
  color: var(--color-accent) !important;
}

.related-nav__item.-next {
  justify-content: flex-end;
  text-align: right;
}

.related-nav__arrow .icon {
  font-size: 24px;
  color: black;
  transition: all 0.1s ease-out;
}

.related-nav__content span,
.related-nav__content p {
  text-decoration: none !important;
}

.pagination.-section {
  display: flex;
  align-items: center;
}

.pagination.-section .pagination__content {
  margin: 0 5.625rem;
}

.pagination.-section .pagination__content > * {
  font-size: 14px;
  line-height: 1;
  color: black;
  padding: 10px 14px;
}

.pagination.-section .pagination__content > *.is-active {
  color: var(--accent-color);
}

.pagination.-section .pagination__content a:hover {
  text-decoration: underline;
}

.nav-icon {
  position: relative;
  display: block;
  transition: all 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.nav-icon__circle {
  border-radius: 100%;
  border: 1px solid black;
  width: 35px;
  height: 35px;
  transition: all 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.nav-icon__circle .icon {
  font-size: 28px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.nav-icon.-left {
  padding-right: 12px;
}

.nav-icon.-left .icon {
  right: 0;
}

.nav-icon.-right {
  padding-left: 12px;
}

.nav-icon.-right .icon {
  left: 0;
}

.nav-icon:hover {
  color: var(--accent-color);
}

.nav-icon:hover .nav-icon__circle {
  border-color: var(--accent-color);
}

.badge {
  padding: 0.375rem 1rem;
  border-radius: 200px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.6875rem;
  line-height: 1;
  letter-spacing: 0.05em;
}

.page-header.-base {
  position: relative;
  margin-top: 6.5625rem;
}

@media (max-width: 767px) {
  .page-header.-base {
    margin-top: 5rem;
  }
}

.page-header.-base .page-header__bg {
  padding: 0 3.4375rem;
}

@media (max-width: 991px) {
  .page-header.-base .page-header__bg {
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    background-color: #F5F7FE;
  }
  .page-header.-base .page-header__bg img {
    display: none;
  }
}

.page-header.-base .page-header__content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  padding-bottom: 3.75rem;
}

@media (max-width: 991px) {
  .page-header.-base .page-header__content {
    position: relative;
    transform: none;
    top: unset;
    left: unset;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .page-header.-base .page-header__content {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.page-header.-base .page-header__title {
  font-size: 3.75rem;
}

@media (max-width: 767px) {
  .page-header.-base .page-header__title {
    font-size: 3.125rem;
  }
}

@media (max-width: 991px) {
  .page-header.-base.-dark .page-header__bg {
    background-color: #1A3454;
  }
}

.page-header.-blue {
  position: relative;
}

@media (max-width: 991px) {
  .page-header.-blue {
    padding-top: 100px;
    padding-bottom: 40px;
  }
}

.page-header.-blue .page-header__bg {
  height: 30rem;
}

.page-header.-blue .page-header__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@media (max-width: 991px) {
  .page-header.-blue .page-header__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.page-header.-blue .page-header__content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  padding-top: 5rem;
}

@media (max-width: 991px) {
  .page-header.-blue .page-header__content {
    position: relative;
    transform: none;
    top: unset;
    left: unset;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .page-header.-blue .page-header__content {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.page-header.-blue .page-header__title {
  font-size: 3.75rem;
}

@media (max-width: 767px) {
  .page-header.-blue .page-header__title {
    font-size: 3.125rem;
  }
}

.page-header.-image {
  position: relative;
  padding-top: 20.625rem;
  padding-bottom: 11.25rem;
}

@media (max-width: 1199px) {
  .page-header.-image {
    padding-top: 280px;
    padding-bottom: 140px;
  }
}

@media (max-width: 991px) {
  .page-header.-image {
    padding-top: 200px;
    padding-bottom: 120px;
  }
}

@media (max-width: 767px) {
  .page-header.-image {
    padding-top: 160px;
    padding-bottom: 80px;
  }
}

.page-header.-image .page-header__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page-header.-image .page-header__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.page-header.-image .page-header__title {
  font-size: 3.75rem;
}

@media (max-width: 767px) {
  .page-header.-image .page-header__title {
    font-size: 3.125rem;
  }
}

.page-header.-image-portfolio {
  position: relative;
  padding-top: 19.375rem;
  padding-bottom: 19.375rem;
}

@media (max-width: 1199px) {
  .page-header.-image-portfolio {
    padding-top: 280px;
    padding-bottom: 180px;
  }
}

@media (max-width: 991px) {
  .page-header.-image-portfolio {
    padding-top: 200px;
    padding-bottom: 160px;
  }
}

@media (max-width: 767px) {
  .page-header.-image-portfolio {
    padding-top: 160px;
    padding-bottom: 140px;
  }
}

.page-header.-image-portfolio .page-header__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page-header.-image-portfolio .page-header__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.page-header.-image-portfolio .page-header__title {
  font-size: 3.75rem;
}

@media (max-width: 767px) {
  .page-header.-image-portfolio .page-header__title {
    font-size: 3.125rem;
  }
}

.page-header.-text {
  position: relative;
  margin-top: 6.5625rem;
  padding-top: 5.9375rem;
}

@media (max-width: 991px) {
  .page-header.-text {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .page-header.-text {
    margin-top: 5rem;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.page-header.-text .page-header__title {
  font-size: 3.75rem;
}

@media (max-width: 767px) {
  .page-header.-text .page-header__title {
    font-size: 3.125rem;
  }
}

/*--------------------------------------------------
	51. Utilities
---------------------------------------------------*/
@media (max-width: 991px) {
  .atropos {
    pointer-events: none !important;
  }
}

.right-0 {
  right: 0;
}

@media (max-width: 991px) {
  .md\:h-300 {
    height: 300px !important;
  }
  .md\:h-auto {
    height: auto !important;
  }
  .md\:bg-pos-top {
    background-position: 0 -100px !important;
  }
}

.h-screen {
  height: 100vh;
}

.row-dividers-dark > *:not(:first-child) {
  border-left: 1px solid #EEEEEE;
}

@media (max-width: 991px) {
  .row-dividers-dark > *:not(:first-child) {
    border-left: none;
  }
}

.gradient-content {
  background: linear-gradient(180deg, rgba(23, 38, 47, 0) 0%, #17262F 100%);
}

@media (max-width: 767px) {
  .sm\:flex-wrap {
    flex-wrap: wrap !important;
  }
}

.container-lg {
  padding-left: 8%;
  padding-right: 8%;
  width: 100%;
  max-width: 100%;
}

.opac-0 {
  opacity: 0;
}

.opac-10 {
  opacity: 0.1;
}

.opac-20 {
  opacity: 0.2;
}

.opac-30 {
  opacity: 0.3;
}

.opac-40 {
  opacity: 0.4;
}

.opac-50 {
  opacity: 0.5;
}

.opac-60 {
  opacity: 0.6;
}

.opac-70 {
  opacity: 0.7;
}

.opac-80 {
  opacity: 0.8;
}

.opac-90 {
  opacity: 0.9;
}

.opac-100 {
  opacity: 1;
}

.size-icon-50 {
  width: 50px;
  height: 50px;
  max-width: 50px;
  max-height: 50px;
}

@media (max-width: 991px) {
  .size-icon-50 {
    width: 40px;
    max-width: 40px;
    height: 40px;
    max-height: 40px;
  }
}

.size-icon-80 {
  width: 80px;
  height: 80px;
  max-width: 80px;
  max-height: 80px;
}

.size-icon-100 {
  width: 100px;
  height: 100px;
  max-width: 100px;
  max-height: 100px;
}

@media (max-width: 991px) {
  .size-icon-100 {
    width: 64px;
    max-width: 64px;
    height: 64px;
    max-height: 64px;
  }
}

.initial-img {
  width: 100%;
  height: auto;
}

.icon-circle-check {
  background-color: #E6ECFD;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-circle-check .icon {
  width: 0.625rem;
  height: 0.625rem;
  stroke-width: 3;
}

.phone-slider-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.phone-slider-image__bg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.phone-slider-image img {
  border-radius: 30px;
  margin-top: 22px;
}

.price-switch {
  display: flex;
  align-items: center;
}

.price-switch .form-switch {
  margin: 0 1.25rem;
}

@media (max-width: 767px) {
  .price-switch .form-switch {
    margin: 0 0.625rem;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow {
  flex: 1 0 auto;
}

.flex-expand {
  flex: 1 1 auto;
}

.direction-column {
  flex-direction: column;
}

.direction-row {
  flex-direction: row;
}

@media (max-width: 767px) {
  .sm\:direction-column {
    flex-direction: column;
  }
  .sm\:direction-row {
    flex-direction: row;
  }
}

@media (max-width: 991px) {
  .md\:direction-column {
    flex-direction: column;
  }
  .md\:direction-row {
    flex-direction: row;
  }
}

.section-bg-color {
  position: relative;
}

.section-bg-color__item {
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10rem;
  right: 10rem;
  border-radius: 1.875rem;
  background-color: #F5F7FE;
}

@media (max-width: 1700px) {
  .section-bg-color__item {
    left: 5rem;
    right: 5rem;
  }
}

@media (max-width: 1550px) {
  .section-bg-color__item {
    left: 1.5rem;
    right: 1.5rem;
  }
}

@media (max-width: 767px) {
  .section-bg-color__item {
    border-radius: 0;
    left: 0;
    right: 0;
  }
}

.section-bg-color__item.-wide {
  left: 3.4375rem !important;
  right: 3.4375rem !important;
}

@media (max-width: 1300px) {
  .section-bg-color__item.-wide {
    left: 1.5rem !important;
    right: 1.5rem !important;
  }
}

@media (max-width: 767px) {
  .section-bg-color__item.-wide {
    border-radius: 0 !important;
    left: 0 !important;
    right: 0 !important;
  }
}

.section-bg-color__item.-full {
  left: 0 !important;
  right: 0 !important;
}

.position-unset {
  position: unset !important;
}

.top-30 {
  top: 1.875rem;
}

.absolute {
  position: absolute;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.absolute-x-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.absolute-y-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.absolute-full-center {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.object-fit-cover {
  -o-object-fit: cover;
     object-fit: cover;
}

.relative {
  position: relative;
}

@media (max-width: 991px) {
  .md\:container {
    max-width: 45rem;
    padding-top: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .md\:container {
    max-width: 33.75rem;
  }
}

@media (max-width: 575px) {
  .md\:container {
    max-width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (max-width: 767px) {
  .sm\:container {
    max-width: 33.75rem;
  }
}

@media (max-width: 575px) {
  .sm\:container {
    max-width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (max-width: 575px) {
  .xs\:container {
    max-width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.html-overflow-hidden {
  overflow: hidden !important;
}

.html-overflow-hidden body {
  overflow-y: scroll;
}

.smooth-scroll {
  scroll-behavior: smooth;
}

.origin-top {
  transform-origin: top !important;
}

.origin-left {
  transform-origin: left !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-normal {
  overflow: initial !important;
}

.overflow-visible {
  overflow: visible !important;
}

@media (max-width: 767px) {
  .sm\:overflow-visible {
    overflow: visible !important;
  }
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
}

.overlay-black-30::after, .overlay-black-50::after, .overlay-black-60::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.overlay-black-30::after {
  background-color: rgba(0, 0, 0, 0.3);
}

.overlay-black-50::after {
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay-black-60::after {
  background-color: rgba(0, 0, 0, 0.6);
}

.container-wide {
  padding-left: 5%;
  padding-right: 5%;
}

@media (max-width: 767px) {
  .container-wide {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-4 {
  border-radius: 0.25rem !important;
}

.rounded-8 {
  border-radius: 0.5rem !important;
}

.rounded-16 {
  border-radius: 1rem !important;
}

.rounded-20 {
  border-radius: 1.25rem !important;
}

.rounded-30 {
  border-radius: 1.875rem !important;
}

.rounded-200 {
  border-radius: 12.5rem !important;
}

.rounded-full {
  border-radius: 100% !important;
}

.z--1 {
  z-index: -1;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.size-4 {
  width: 0.25rem !important;
  height: 0.25rem !important;
}

.size-10 {
  width: 0.625rem !important;
  height: 0.625rem !important;
}

.size-14 {
  width: 0.875rem !important;
  height: 0.875rem !important;
}

.size-16 {
  width: 1rem !important;
  height: 1rem !important;
}

.size-20 {
  width: 1.25rem !important;
  height: 1.25rem !important;
}

.size-24 {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.size-32 {
  width: 2rem !important;
  height: 2rem !important;
}

.size-40 {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.size-48 {
  width: 3rem !important;
  height: 3rem !important;
}

.size-50 {
  width: 3.125rem !important;
  height: 3.125rem !important;
}

.size-60 {
  width: 3.75rem !important;
  height: 3.75rem !important;
}

.size-64 {
  width: 4rem !important;
  height: 4rem !important;
}

.size-80 {
  width: 5rem !important;
  height: 5rem !important;
}

.size-96 {
  width: 6rem !important;
  height: 6rem !important;
}

.size-120 {
  width: 7.5rem !important;
  height: 7.5rem !important;
}

.str-width-xs {
  stroke-width: 0.5 !important;
}

.str-width-sm {
  stroke-width: 0.75 !important;
}

.str-width-md {
  stroke-width: 1 !important;
}

.str-width-lg {
  stroke-width: 1.25 !important;
}

.str-width-xl {
  stroke-width: 1.5 !important;
}

.str-width-2xl {
  stroke-width: 1.75 !important;
}

.str-width-3xl {
  stroke-width: 2 !important;
}

.bg-accent-dark {
  background-color: var(--dark-accent-color);
}

.bg-accent-light {
  background-color: var(--light-accent-color);
}

.bg-accent {
  background-color: var(--accent-color);
}

.bg-black {
  background-color: #000000;
}

.bg-white {
  background-color: #FFFFFF;
}

.bg-font-dark {
  background-color: #404046;
}

.bg-light-grey {
  background-color: #f6f6f6;
}

.bg-light-grey-2 {
  background-color: #F9F6F6;
}

.bg-light-1 {
  background-color: var(--bg-light-1);
}

.bg-light-2 {
  background-color: var(--bg-light-2);
}

.bg-light-3 {
  background-color: var(--bg-light-3);
}

.bg-light-4 {
  background-color: var(--bg-light-4) !important;
}

.bg-light-5 {
  background-color: var(--bg-light-5);
}

.bg-light-6 {
  background-color: var(--bg-light-6);
}

.bg-dark-1 {
  background-color: var(--bg-dark-1);
}

.bg-dark-2 {
  background-color: var(--bg-dark-2);
}

.bg-dark-3 {
  background-color: var(--bg-dark-3);
}

.bg-dark-4 {
  background-color: var(--bg-dark-4);
}

.bg-dark-5 {
  background-color: var(--bg-dark-5);
}

.bg-dark-6 {
  background-color: var(--bg-dark-6) !important;
}

.bg-dark-8 {
  background-color: var(--bg-dark-8);
}

.bg-dark-9 {
  background-color: var(--bg-dark-9);
}

.bg-dark-10 {
  background-color: var(--bg-dark-10);
}

.bg-dark-11 {
  background-color: var(--bg-dark-11);
}

.bg-dark-12 {
  background-color: var(--bg-dark-12) !important;
}

.bg-dark-13 {
  background-color: var(--bg-dark-13) !important;
}

.bg-purple {
  background-color: var(--purple-color);
}

.bg-purple-3 {
  background-color: var(--purple-3-color);
}

.bg-blue-darker {
  background-color: var(--darker-blue-color);
}

.bg-blue-dark {
  background-color: var(--dark-blue-color);
}

.bg-blue {
  background-color: var(--blue-color);
}

.bg-blue-light {
  background-color: var(--light-blue-color);
}

.bg-red-2 {
  background-color: var(--red-2-color);
}

.bg-red {
  background-color: var(--red-color);
}

.bg-red-light {
  background-color: var(--light-red-color);
}

.bg-green {
  background-color: var(--green-color);
}

.bg-green-light {
  background-color: var(--light-green-color);
}

.bg-yellow {
  background-color: var(--yellow-color);
}

.bg-yellow-light {
  background-color: var(--light-yellow-color);
}

.bg-orange {
  background-color: var(--orange-color);
}

.bg-orange-light {
  background-color: var(--light-orange-color);
}

.text-dark-1 {
  color: var(--text-dark-1);
}

.text-dark-4 {
  color: var(--bg-dark-4);
}

.text-dark-8 {
  color: var(--bg-dark-8);
}

.text-accent {
  color: var(--accent-color);
}

.text-light {
  color: var(--font-light);
}

.text-dark {
  color: var(--font-dark);
}

.text-grey {
  color: #615E5E;
}

.text-black {
  color: #000000;
}

.text-white {
  color: #FFFFFF !important;
}

.text-blue-dark {
  color: var(--dark-blue-color);
}

.text-orange-light {
  color: var(--light-orange-color);
}

.text-orange {
  color: var(--orange-color);
}

.text-orange-3 {
  color: var(--orange-3-color);
}

.text-teal {
  color: var(--teal-color);
}

.text-red-2 {
  color: var(--red-2-color);
}

.text-purple {
  color: var(--purple-color);
}

.text-purple-3 {
  color: var(--purple-3-color);
}

.text-red-2 {
  color: var(--red-2-color);
}

.text-red {
  color: var(--red-color);
}

.text-red-light {
  color: var(--light-red-color);
}

.text-green {
  color: var(--green-color);
}

.text-green-light {
  color: var(--light-green-color);
}

.text-yellow {
  color: var(--yellow-color);
}

.text-yellow-light {
  color: var(--light-yellow-color);
}

.shadow-card {
  box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
}

.shadow-small {
  box-shadow: 0px 5px 70px rgba(1, 33, 58, 0.03);
}

.border-none {
  border: none;
}

.border-light {
  border: 1px solid #EEEEEE;
}

.border-2-white {
  border: 2px solid #FFFFFF;
}

.border-dark {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.border-top-light {
  border-top: 1px solid #EEEEEE;
}

.border-top-dark {
  border-top: 1px solid #EEEEEE;
}

.border-left-2-accent {
  border-left: 2px solid var(--accent-color);
}

.border-left-light {
  border-left: 1px solid #EEEEEE;
}

.border-left-dark {
  border-left: 1px solid #EEEEEE;
}

.border-bottom-light {
  border-bottom: 1px solid #EEEEEE;
}

.border-bottom-dark {
  border-bottom: 1px solid #EEEEEE;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal {
  text-transform: none;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.ul-list {
  padding-left: 1rem;
}

.ul-list li {
  list-style: disc;
}

.ol-list {
  padding-left: 1rem;
}

.ol-list li {
  list-style: decimal;
}

.t-base {
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hover-group {
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hover-group:hover .hg\:text-white {
  color: white !important;
}

.hover-group:hover .hg\:bg-white {
  background-color: white !important;
}

.hover-group:hover .hg\:bg-accent {
  background-color: var(--accent-color) !important;
}

.hover-group:hover .hg\:bg-text-dark {
  background-color: var(--text-dark-1) !important;
}

.hover-group:hover .hg\:bg-blue-dark {
  background-color: var(--dark-blue-color) !important;
}

.hover-group:hover .hg\:opacity-100 {
  opacity: 1 !important;
}

.hover-group:hover .hg\:shadow-card {
  position: relative;
  z-index: 5;
  box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07) !important;
}

.hover-group:hover .hg\:text-orange {
  color: var(--orange-color) !important;
}

.hover-group:hover .hg\:text-red {
  color: var(--red-color) !important;
}

.hover-group:hover .hg\:text-green {
  color: var(--green-color) !important;
}

.h\:bg-orange:hover {
  background-color: var(--orange-color) !important;
}

.h\:text-orange:hover {
  color: var(--orange-color) !important;
}

.h\:text-white:hover {
  color: white !important;
}

.h\:bg-white:hover {
  background-color: white !important;
}

.h\:bg-accent:hover {
  background-color: var(--accent-color) !important;
}

.h\:bg-dark-1:hover {
  background-color: var(--bg-dark-1) !important;
}

.h\:bg-blue-dark:hover {
  background-color: var(--dark-blue-color) !important;
}

.h\:shadow-card:hover {
  position: relative;
  z-index: 5;
  box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07) !important;
}

.h\:zoom {
  overflow: hidden;
}

.h\:zoom .h\:zoom__item {
  transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.h\:zoom:hover .h\:zoom__item {
  transform: scale(1.08);
}

.home-1-slider {
  max-width: 1800px;
  margin: 0 auto;
  overflow: hidden;
}

.-no-highlight .atropos-highlight {
  display: none !important;
}

@media (max-width: 767px) {
  .sm\:w-screen {
    width: 100vw;
  }
}

.is-pin-active {
  color: var(--accent-color) !important;
  border-bottom: 1px solid var(--accent-color) !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown {
  position: relative;
  z-index: 5;
}

.dropdown__item {
  position: absolute;
  top: 80%;
  right: -40px;
  padding: 24px 32px;
  padding-top: 40px;
  background-color: white;
  box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  min-width: 220px;
  border-radius: 8px;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateY(14px);
  opacity: 0;
}

.dropdown:hover .dropdown__item {
  transform: none;
  opacity: 1;
}

.shape {
  position: absolute;
}

.shape.-top {
  top: 0;
}

.shape.-bottom {
  bottom: 0;
}

.shape.-center-bottom {
  bottom: 60px;
}

.shape.-left {
  left: 40px;
}

.shape.-left-0 {
  left: 0;
}

.shape.-right-0 {
  right: 0;
}

.shape.-right--40 {
  right: -40px;
}

.border-none {
  border: none !important;
}

.rounded-tl-120 {
  border-top-left-radius: 7.5rem;
}

.rounded-bl-120 {
  border-bottom-left-radius: 7.5rem;
}

.mt--20 {
  margin-top: -1.25rem;
}

.border-10-white {
  border: 0.625rem solid white;
}

.shapes-2 .shapes-2-img-2 {
  position: absolute;
  left: 0;
  bottom: 0;
}

.shapes-2 img {
  max-width: 100%;
}

.max-w-100 {
  max-width: 100%;
}

.bg-pos-unset {
  background-position: unset !important;
}

.br-rounded-32 {
  border-bottom-right-radius: 2rem;
}

.section-bg-color-margin {
  position: relative;
}

.section-bg-color-margin__item {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 11.25rem;
  width: 100%;
}

@media (max-width: 1199px) {
  .section-bg-color-margin__item {
    bottom: 11.25rem;
  }
}

@media (max-width: 991px) {
  .section-bg-color-margin__item {
    bottom: 8.75rem;
  }
}

@media (max-width: 767px) {
  .section-bg-color-margin__item {
    bottom: 6.25rem;
  }
}

.border-purple {
  border: 1px solid var(--purple-color);
}

.blogSocials {
  position: absolute;
  top: 7.5rem;
  left: 3.4375rem;
}

@media (max-width: 991px) {
  .blogSocials {
    top: 6.25rem;
    left: 1.5rem;
  }
}

@media (max-width: 767px) {
  .blogSocials {
    display: none;
  }
}

.blogSocials__text {
  transform: rotate(180deg);
  -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
  text-orientation: sideways-right;
  padding-left: 4px;
}

.size-icon-4 {
  width: 4px;
  height: 4px;
  min-width: 4px;
  min-height: 4px;
  max-width: 4px;
  max-height: 4px;
}

.feature-hover {
  position: relative;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.feature-hover__content {
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateY(14px);
  opacity: 0;
}

.feature-hover:hover {
  z-index: 5;
  box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
}

.feature-hover:hover .feature-hover__content {
  transform: none;
  opacity: 1;
}

@media (max-width: 991px) {
  .feature-hover__content {
    transform: none;
    opacity: 1;
  }
}

.bg-element.-sally {
  position: absolute;
  left: 0.3125rem;
  top: -8.75rem;
}

.bg-element.-sally img {
  width: 80%;
  height: auto;
}

@media (max-width: 1199px) {
  .bg-element.-sally {
    display: none;
  }
}

.image-group.-type-1 {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 4.375rem;
}

.image-group.-type-1 img {
  max-width: 100%;
  height: auto;
}

.image-group.-type-1 img:nth-child(1) {
  z-index: 0;
  position: absolute;
  bottom: 0;
  left: 9.375rem;
}

.image-group.-type-1 img:nth-child(2) {
  z-index: 1;
  position: relative;
}

.image-group.-type-1 img:nth-child(3) {
  z-index: 2;
  position: absolute;
  left: 0;
}

@media (max-width: 991px) {
  .image-group.-type-1 img:nth-child(1),
  .image-group.-type-1 img:nth-child(2) {
    display: none;
  }
  .image-group.-type-1 img:nth-child(3) {
    position: relative;
    width: 100%;
  }
}

.image-group.-type-2 {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 8.125rem;
}

.image-group.-type-2 img {
  max-width: 100%;
  height: auto;
}

.image-group.-type-2 img:nth-child(1) {
  z-index: 0;
  position: absolute;
  bottom: 0;
  right: 9.375rem;
}

.image-group.-type-2 img:nth-child(2) {
  z-index: 1;
  position: relative;
}

.image-group.-type-2 img:nth-child(3) {
  z-index: 2;
  position: absolute;
  bottom: 3.125rem;
  right: 0;
}

@media (max-width: 991px) {
  .image-group.-type-2 {
    padding-bottom: 0;
  }
  .image-group.-type-2 img:nth-child(1),
  .image-group.-type-2 img:nth-child(2) {
    display: none;
  }
  .image-group.-type-2 img:nth-child(3) {
    bottom: unset;
    position: relative;
    width: 100%;
  }
}

.svg-shape {
  display: flex;
}

.svg-shape svg {
  width: 100%;
  height: auto;
}

.svg-shape.-r-180 {
  transform: rotate(180deg);
}

.decoration-none {
  text-decoration: none;
}

.border-top-light {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.border-top-dark {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.testimonials-quote {
  position: absolute;
  top: 2.5rem;
  right: 2.75rem;
}

.testimonials-active .testimonials-active__item {
  position: relative;
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.testimonials-active .testimonials-active__item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--light-green-color-full);
  width: 100%;
  height: 0.4375rem;
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.testimonials-active .swiper-slide-active .testimonials-active__item {
  box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
}

.testimonials-active .swiper-slide-active .testimonials-active__item::after {
  opacity: 1;
}

.tooltip {
  position: relative;
  width: 6.25rem;
  height: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #615E5E;
}

.tooltip__content {
  position: absolute;
  padding: 7px 0.6875rem;
  font-size: 0.875rem;
  line-height: 1rem;
  background-color: black;
  color: white;
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.tooltip__content::after {
  content: '';
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  background-color: black;
  transform: rotate(45deg);
}

.tooltip.-top .tooltip__content {
  bottom: calc(100% + 6px);
  transform: translateY(8px);
}

.tooltip.-top .tooltip__content::after {
  bottom: -0.25rem;
}

.tooltip.-bottom .tooltip__content {
  top: calc(100% + 6px);
  transform: translateY(-8px);
}

.tooltip.-bottom .tooltip__content::after {
  top: -0.25rem;
}

.tooltip.-left .tooltip__content {
  right: calc(100% + 6px);
  transform: translateX(8px);
}

.tooltip.-left .tooltip__content::after {
  right: -0.25rem;
}

.tooltip.-right .tooltip__content {
  left: calc(100% + 6px);
  transform: translateX(-8px);
}

.tooltip.-right .tooltip__content::after {
  left: -0.25rem;
}

.tooltip:hover .tooltip__content {
  transform: none;
  opacity: 1;
}

.quote {
  position: relative;
  font-size: 1.125rem;
  line-height: 1.875rem;
  padding: 0.3125rem 0;
  padding-left: 1.5625rem;
  margin-left: 0.625rem;
}

.quote::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0.1875rem;
  background-color: black;
}

.quote.-light::before {
  background-color: white;
}

.gallery__item {
  position: relative;
  display: block;
}

.gallery__item:hover .gallery__button {
  opacity: 1;
}

.gallery__button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: black;
  border-radius: 100%;
  width: 3.25rem;
  height: 3.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.gallery__button .icon {
  color: white;
  stroke-width: 1;
  width: 1.75rem;
  height: 1.75rem;
}

.gallery__button.-bottom-right {
  top: unset;
  left: unset;
  transform: unset;
  bottom: 1.875rem;
  right: 1.875rem;
}

.py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.px-0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.pt-0 {
  padding-top: 0rem !important;
}

.pb-0 {
  padding-bottom: 0rem !important;
}

.pl-0 {
  padding-left: 0rem !important;
}

.pr-0 {
  padding-right: 0rem !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.ml-0 {
  margin-left: 0rem !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.py-4 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.px-4 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.pt-4 {
  padding-top: 0.25rem !important;
}

.pb-4 {
  padding-bottom: 0.25rem !important;
}

.pl-4 {
  padding-left: 0.25rem !important;
}

.pr-4 {
  padding-right: 0.25rem !important;
}

.mt-4 {
  margin-top: 0.25rem !important;
}

.mb-4 {
  margin-bottom: 0.25rem !important;
}

.ml-4 {
  margin-left: 0.25rem !important;
}

.mr-4 {
  margin-right: 0.25rem !important;
}

.py-8 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.px-8 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.pt-8 {
  padding-top: 0.5rem !important;
}

.pb-8 {
  padding-bottom: 0.5rem !important;
}

.pl-8 {
  padding-left: 0.5rem !important;
}

.pr-8 {
  padding-right: 0.5rem !important;
}

.mt-8 {
  margin-top: 0.5rem !important;
}

.mb-8 {
  margin-bottom: 0.5rem !important;
}

.ml-8 {
  margin-left: 0.5rem !important;
}

.mr-8 {
  margin-right: 0.5rem !important;
}

.py-10 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.px-10 {
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
}

.pt-10 {
  padding-top: 0.625rem !important;
}

.pb-10 {
  padding-bottom: 0.625rem !important;
}

.pl-10 {
  padding-left: 0.625rem !important;
}

.pr-10 {
  padding-right: 0.625rem !important;
}

.mt-10 {
  margin-top: 0.625rem !important;
}

.mb-10 {
  margin-bottom: 0.625rem !important;
}

.ml-10 {
  margin-left: 0.625rem !important;
}

.mr-10 {
  margin-right: 0.625rem !important;
}

.py-12 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.px-12 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.pt-12 {
  padding-top: 0.75rem !important;
}

.pb-12 {
  padding-bottom: 0.75rem !important;
}

.pl-12 {
  padding-left: 0.75rem !important;
}

.pr-12 {
  padding-right: 0.75rem !important;
}

.mt-12 {
  margin-top: 0.75rem !important;
}

.mb-12 {
  margin-bottom: 0.75rem !important;
}

.ml-12 {
  margin-left: 0.75rem !important;
}

.mr-12 {
  margin-right: 0.75rem !important;
}

.py-16 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px-16 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.pt-16 {
  padding-top: 1rem !important;
}

.pb-16 {
  padding-bottom: 1rem !important;
}

.pl-16 {
  padding-left: 1rem !important;
}

.pr-16 {
  padding-right: 1rem !important;
}

.mt-16 {
  margin-top: 1rem !important;
}

.mb-16 {
  margin-bottom: 1rem !important;
}

.ml-16 {
  margin-left: 1rem !important;
}

.mr-16 {
  margin-right: 1rem !important;
}

.py-20 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.px-20 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.pt-20 {
  padding-top: 1.25rem !important;
}

.pb-20 {
  padding-bottom: 1.25rem !important;
}

.pl-20 {
  padding-left: 1.25rem !important;
}

.pr-20 {
  padding-right: 1.25rem !important;
}

.mt-20 {
  margin-top: 1.25rem !important;
}

.mb-20 {
  margin-bottom: 1.25rem !important;
}

.ml-20 {
  margin-left: 1.25rem !important;
}

.mr-20 {
  margin-right: 1.25rem !important;
}

.py-24 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.px-24 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.pt-24 {
  padding-top: 1.5rem !important;
}

.pb-24 {
  padding-bottom: 1.5rem !important;
}

.pl-24 {
  padding-left: 1.5rem !important;
}

.pr-24 {
  padding-right: 1.5rem !important;
}

.mt-24 {
  margin-top: 1.5rem !important;
}

.mb-24 {
  margin-bottom: 1.5rem !important;
}

.ml-24 {
  margin-left: 1.5rem !important;
}

.mr-24 {
  margin-right: 1.5rem !important;
}

.py-30 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.px-30 {
  padding-left: 1.875rem !important;
  padding-right: 1.875rem !important;
}

.pt-30 {
  padding-top: 1.875rem !important;
}

.pb-30 {
  padding-bottom: 1.875rem !important;
}

.pl-30 {
  padding-left: 1.875rem !important;
}

.pr-30 {
  padding-right: 1.875rem !important;
}

.mt-30 {
  margin-top: 1.875rem !important;
}

.mb-30 {
  margin-bottom: 1.875rem !important;
}

.ml-30 {
  margin-left: 1.875rem !important;
}

.mr-30 {
  margin-right: 1.875rem !important;
}

.py-32 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.px-32 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.pt-32 {
  padding-top: 2rem !important;
}

.pb-32 {
  padding-bottom: 2rem !important;
}

.pl-32 {
  padding-left: 2rem !important;
}

.pr-32 {
  padding-right: 2rem !important;
}

.mt-32 {
  margin-top: 2rem !important;
}

.mb-32 {
  margin-bottom: 2rem !important;
}

.ml-32 {
  margin-left: 2rem !important;
}

.mr-32 {
  margin-right: 2rem !important;
}

.py-34 {
  padding-top: 2.125rem !important;
  padding-bottom: 2.125rem !important;
}

.px-34 {
  padding-left: 2.125rem !important;
  padding-right: 2.125rem !important;
}

.pt-34 {
  padding-top: 2.125rem !important;
}

.pb-34 {
  padding-bottom: 2.125rem !important;
}

.pl-34 {
  padding-left: 2.125rem !important;
}

.pr-34 {
  padding-right: 2.125rem !important;
}

.mt-34 {
  margin-top: 2.125rem !important;
}

.mb-34 {
  margin-bottom: 2.125rem !important;
}

.ml-34 {
  margin-left: 2.125rem !important;
}

.mr-34 {
  margin-right: 2.125rem !important;
}

.py-40 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.px-40 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.pt-40 {
  padding-top: 2.5rem !important;
}

.pb-40 {
  padding-bottom: 2.5rem !important;
}

.pl-40 {
  padding-left: 2.5rem !important;
}

.pr-40 {
  padding-right: 2.5rem !important;
}

.mt-40 {
  margin-top: 2.5rem !important;
}

.mb-40 {
  margin-bottom: 2.5rem !important;
}

.ml-40 {
  margin-left: 2.5rem !important;
}

.mr-40 {
  margin-right: 2.5rem !important;
}

.py-48 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.px-48 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.pt-48 {
  padding-top: 3rem !important;
}

.pb-48 {
  padding-bottom: 3rem !important;
}

.pl-48 {
  padding-left: 3rem !important;
}

.pr-48 {
  padding-right: 3rem !important;
}

.mt-48 {
  margin-top: 3rem !important;
}

.mb-48 {
  margin-bottom: 3rem !important;
}

.ml-48 {
  margin-left: 3rem !important;
}

.mr-48 {
  margin-right: 3rem !important;
}

.py-50 {
  padding-top: 3.125rem !important;
  padding-bottom: 3.125rem !important;
}

.px-50 {
  padding-left: 3.125rem !important;
  padding-right: 3.125rem !important;
}

.pt-50 {
  padding-top: 3.125rem !important;
}

.pb-50 {
  padding-bottom: 3.125rem !important;
}

.pl-50 {
  padding-left: 3.125rem !important;
}

.pr-50 {
  padding-right: 3.125rem !important;
}

.mt-50 {
  margin-top: 3.125rem !important;
}

.mb-50 {
  margin-bottom: 3.125rem !important;
}

.ml-50 {
  margin-left: 3.125rem !important;
}

.mr-50 {
  margin-right: 3.125rem !important;
}

.py-60 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.px-60 {
  padding-left: 3.75rem !important;
  padding-right: 3.75rem !important;
}

.pt-60 {
  padding-top: 3.75rem !important;
}

.pb-60 {
  padding-bottom: 3.75rem !important;
}

.pl-60 {
  padding-left: 3.75rem !important;
}

.pr-60 {
  padding-right: 3.75rem !important;
}

.mt-60 {
  margin-top: 3.75rem !important;
}

.mb-60 {
  margin-bottom: 3.75rem !important;
}

.ml-60 {
  margin-left: 3.75rem !important;
}

.mr-60 {
  margin-right: 3.75rem !important;
}

.py-64 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.px-64 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.pt-64 {
  padding-top: 4rem !important;
}

.pb-64 {
  padding-bottom: 4rem !important;
}

.pl-64 {
  padding-left: 4rem !important;
}

.pr-64 {
  padding-right: 4rem !important;
}

.mt-64 {
  margin-top: 4rem !important;
}

.mb-64 {
  margin-bottom: 4rem !important;
}

.ml-64 {
  margin-left: 4rem !important;
}

.mr-64 {
  margin-right: 4rem !important;
}

.py-72 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.px-72 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}

.pt-72 {
  padding-top: 4.5rem !important;
}

.pb-72 {
  padding-bottom: 4.5rem !important;
}

.pl-72 {
  padding-left: 4.5rem !important;
}

.pr-72 {
  padding-right: 4.5rem !important;
}

.mt-72 {
  margin-top: 4.5rem !important;
}

.mb-72 {
  margin-bottom: 4.5rem !important;
}

.ml-72 {
  margin-left: 4.5rem !important;
}

.mr-72 {
  margin-right: 4.5rem !important;
}

.py-80 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.px-80 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.pt-80 {
  padding-top: 5rem !important;
}

.pb-80 {
  padding-bottom: 5rem !important;
}

.pl-80 {
  padding-left: 5rem !important;
}

.pr-80 {
  padding-right: 5rem !important;
}

.mt-80 {
  margin-top: 5rem !important;
}

.mb-80 {
  margin-bottom: 5rem !important;
}

.ml-80 {
  margin-left: 5rem !important;
}

.mr-80 {
  margin-right: 5rem !important;
}

.py-100 {
  padding-top: 6.25rem !important;
  padding-bottom: 6.25rem !important;
}

.px-100 {
  padding-left: 6.25rem !important;
  padding-right: 6.25rem !important;
}

.pt-100 {
  padding-top: 6.25rem !important;
}

.pb-100 {
  padding-bottom: 6.25rem !important;
}

.pl-100 {
  padding-left: 6.25rem !important;
}

.pr-100 {
  padding-right: 6.25rem !important;
}

.mt-100 {
  margin-top: 6.25rem !important;
}

.mb-100 {
  margin-bottom: 6.25rem !important;
}

.ml-100 {
  margin-left: 6.25rem !important;
}

.mr-100 {
  margin-right: 6.25rem !important;
}

.py-120 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.px-120 {
  padding-left: 7.5rem !important;
  padding-right: 7.5rem !important;
}

.pt-120 {
  padding-top: 7.5rem !important;
}

.pb-120 {
  padding-bottom: 7.5rem !important;
}

.pl-120 {
  padding-left: 7.5rem !important;
}

.pr-120 {
  padding-right: 7.5rem !important;
}

.mt-120 {
  margin-top: 7.5rem !important;
}

.mb-120 {
  margin-bottom: 7.5rem !important;
}

.ml-120 {
  margin-left: 7.5rem !important;
}

.mr-120 {
  margin-right: 7.5rem !important;
}

@media (max-width: 991px) {
  .md\:mt-112 {
    margin-top: 112px;
  }
}

.px-160 {
  padding-left: 160px;
  padding-right: 160px;
}

.pl-56 {
  padding-left: 56px;
}

.mt-56 {
  margin-top: 56px;
}

.py-36 {
  padding-top: 36px;
  padding-bottom: 36px;
}

.px-28 {
  padding-left: 28px;
  padding-right: 28px;
}

@media (max-width: 1199px) {
  .lg\:py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .lg\:px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .lg\:pt-0 {
    padding-top: 0rem !important;
  }
  .lg\:pb-0 {
    padding-bottom: 0rem !important;
  }
  .lg\:pl-0 {
    padding-left: 0rem !important;
  }
  .lg\:pr-0 {
    padding-right: 0rem !important;
  }
  .lg\:mt-0 {
    margin-top: 0rem !important;
  }
  .lg\:mb-0 {
    margin-bottom: 0rem !important;
  }
  .lg\:ml-0 {
    margin-left: 0rem !important;
  }
  .lg\:mr-0 {
    margin-right: 0rem !important;
  }
  .lg\:py-4 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .lg\:px-4 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .lg\:pt-4 {
    padding-top: 0.25rem !important;
  }
  .lg\:pb-4 {
    padding-bottom: 0.25rem !important;
  }
  .lg\:pl-4 {
    padding-left: 0.25rem !important;
  }
  .lg\:pr-4 {
    padding-right: 0.25rem !important;
  }
  .lg\:mt-4 {
    margin-top: 0.25rem !important;
  }
  .lg\:mb-4 {
    margin-bottom: 0.25rem !important;
  }
  .lg\:ml-4 {
    margin-left: 0.25rem !important;
  }
  .lg\:mr-4 {
    margin-right: 0.25rem !important;
  }
  .lg\:py-8 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .lg\:px-8 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .lg\:pt-8 {
    padding-top: 0.5rem !important;
  }
  .lg\:pb-8 {
    padding-bottom: 0.5rem !important;
  }
  .lg\:pl-8 {
    padding-left: 0.5rem !important;
  }
  .lg\:pr-8 {
    padding-right: 0.5rem !important;
  }
  .lg\:mt-8 {
    margin-top: 0.5rem !important;
  }
  .lg\:mb-8 {
    margin-bottom: 0.5rem !important;
  }
  .lg\:ml-8 {
    margin-left: 0.5rem !important;
  }
  .lg\:mr-8 {
    margin-right: 0.5rem !important;
  }
  .lg\:py-10 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .lg\:px-10 {
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }
  .lg\:pt-10 {
    padding-top: 0.625rem !important;
  }
  .lg\:pb-10 {
    padding-bottom: 0.625rem !important;
  }
  .lg\:pl-10 {
    padding-left: 0.625rem !important;
  }
  .lg\:pr-10 {
    padding-right: 0.625rem !important;
  }
  .lg\:mt-10 {
    margin-top: 0.625rem !important;
  }
  .lg\:mb-10 {
    margin-bottom: 0.625rem !important;
  }
  .lg\:ml-10 {
    margin-left: 0.625rem !important;
  }
  .lg\:mr-10 {
    margin-right: 0.625rem !important;
  }
  .lg\:py-12 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .lg\:px-12 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .lg\:pt-12 {
    padding-top: 0.75rem !important;
  }
  .lg\:pb-12 {
    padding-bottom: 0.75rem !important;
  }
  .lg\:pl-12 {
    padding-left: 0.75rem !important;
  }
  .lg\:pr-12 {
    padding-right: 0.75rem !important;
  }
  .lg\:mt-12 {
    margin-top: 0.75rem !important;
  }
  .lg\:mb-12 {
    margin-bottom: 0.75rem !important;
  }
  .lg\:ml-12 {
    margin-left: 0.75rem !important;
  }
  .lg\:mr-12 {
    margin-right: 0.75rem !important;
  }
  .lg\:py-16 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .lg\:px-16 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .lg\:pt-16 {
    padding-top: 1rem !important;
  }
  .lg\:pb-16 {
    padding-bottom: 1rem !important;
  }
  .lg\:pl-16 {
    padding-left: 1rem !important;
  }
  .lg\:pr-16 {
    padding-right: 1rem !important;
  }
  .lg\:mt-16 {
    margin-top: 1rem !important;
  }
  .lg\:mb-16 {
    margin-bottom: 1rem !important;
  }
  .lg\:ml-16 {
    margin-left: 1rem !important;
  }
  .lg\:mr-16 {
    margin-right: 1rem !important;
  }
  .lg\:py-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .lg\:px-20 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .lg\:pt-20 {
    padding-top: 1.25rem !important;
  }
  .lg\:pb-20 {
    padding-bottom: 1.25rem !important;
  }
  .lg\:pl-20 {
    padding-left: 1.25rem !important;
  }
  .lg\:pr-20 {
    padding-right: 1.25rem !important;
  }
  .lg\:mt-20 {
    margin-top: 1.25rem !important;
  }
  .lg\:mb-20 {
    margin-bottom: 1.25rem !important;
  }
  .lg\:ml-20 {
    margin-left: 1.25rem !important;
  }
  .lg\:mr-20 {
    margin-right: 1.25rem !important;
  }
  .lg\:py-24 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .lg\:px-24 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .lg\:pt-24 {
    padding-top: 1.5rem !important;
  }
  .lg\:pb-24 {
    padding-bottom: 1.5rem !important;
  }
  .lg\:pl-24 {
    padding-left: 1.5rem !important;
  }
  .lg\:pr-24 {
    padding-right: 1.5rem !important;
  }
  .lg\:mt-24 {
    margin-top: 1.5rem !important;
  }
  .lg\:mb-24 {
    margin-bottom: 1.5rem !important;
  }
  .lg\:ml-24 {
    margin-left: 1.5rem !important;
  }
  .lg\:mr-24 {
    margin-right: 1.5rem !important;
  }
  .lg\:py-30 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .lg\:px-30 {
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important;
  }
  .lg\:pt-30 {
    padding-top: 1.875rem !important;
  }
  .lg\:pb-30 {
    padding-bottom: 1.875rem !important;
  }
  .lg\:pl-30 {
    padding-left: 1.875rem !important;
  }
  .lg\:pr-30 {
    padding-right: 1.875rem !important;
  }
  .lg\:mt-30 {
    margin-top: 1.875rem !important;
  }
  .lg\:mb-30 {
    margin-bottom: 1.875rem !important;
  }
  .lg\:ml-30 {
    margin-left: 1.875rem !important;
  }
  .lg\:mr-30 {
    margin-right: 1.875rem !important;
  }
  .lg\:py-32 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .lg\:px-32 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .lg\:pt-32 {
    padding-top: 2rem !important;
  }
  .lg\:pb-32 {
    padding-bottom: 2rem !important;
  }
  .lg\:pl-32 {
    padding-left: 2rem !important;
  }
  .lg\:pr-32 {
    padding-right: 2rem !important;
  }
  .lg\:mt-32 {
    margin-top: 2rem !important;
  }
  .lg\:mb-32 {
    margin-bottom: 2rem !important;
  }
  .lg\:ml-32 {
    margin-left: 2rem !important;
  }
  .lg\:mr-32 {
    margin-right: 2rem !important;
  }
  .lg\:py-34 {
    padding-top: 2.125rem !important;
    padding-bottom: 2.125rem !important;
  }
  .lg\:px-34 {
    padding-left: 2.125rem !important;
    padding-right: 2.125rem !important;
  }
  .lg\:pt-34 {
    padding-top: 2.125rem !important;
  }
  .lg\:pb-34 {
    padding-bottom: 2.125rem !important;
  }
  .lg\:pl-34 {
    padding-left: 2.125rem !important;
  }
  .lg\:pr-34 {
    padding-right: 2.125rem !important;
  }
  .lg\:mt-34 {
    margin-top: 2.125rem !important;
  }
  .lg\:mb-34 {
    margin-bottom: 2.125rem !important;
  }
  .lg\:ml-34 {
    margin-left: 2.125rem !important;
  }
  .lg\:mr-34 {
    margin-right: 2.125rem !important;
  }
  .lg\:py-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .lg\:px-40 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .lg\:pt-40 {
    padding-top: 2.5rem !important;
  }
  .lg\:pb-40 {
    padding-bottom: 2.5rem !important;
  }
  .lg\:pl-40 {
    padding-left: 2.5rem !important;
  }
  .lg\:pr-40 {
    padding-right: 2.5rem !important;
  }
  .lg\:mt-40 {
    margin-top: 2.5rem !important;
  }
  .lg\:mb-40 {
    margin-bottom: 2.5rem !important;
  }
  .lg\:ml-40 {
    margin-left: 2.5rem !important;
  }
  .lg\:mr-40 {
    margin-right: 2.5rem !important;
  }
  .lg\:py-48 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .lg\:px-48 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .lg\:pt-48 {
    padding-top: 3rem !important;
  }
  .lg\:pb-48 {
    padding-bottom: 3rem !important;
  }
  .lg\:pl-48 {
    padding-left: 3rem !important;
  }
  .lg\:pr-48 {
    padding-right: 3rem !important;
  }
  .lg\:mt-48 {
    margin-top: 3rem !important;
  }
  .lg\:mb-48 {
    margin-bottom: 3rem !important;
  }
  .lg\:ml-48 {
    margin-left: 3rem !important;
  }
  .lg\:mr-48 {
    margin-right: 3rem !important;
  }
  .lg\:py-50 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .lg\:px-50 {
    padding-left: 3.125rem !important;
    padding-right: 3.125rem !important;
  }
  .lg\:pt-50 {
    padding-top: 3.125rem !important;
  }
  .lg\:pb-50 {
    padding-bottom: 3.125rem !important;
  }
  .lg\:pl-50 {
    padding-left: 3.125rem !important;
  }
  .lg\:pr-50 {
    padding-right: 3.125rem !important;
  }
  .lg\:mt-50 {
    margin-top: 3.125rem !important;
  }
  .lg\:mb-50 {
    margin-bottom: 3.125rem !important;
  }
  .lg\:ml-50 {
    margin-left: 3.125rem !important;
  }
  .lg\:mr-50 {
    margin-right: 3.125rem !important;
  }
  .lg\:py-60 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .lg\:px-60 {
    padding-left: 3.75rem !important;
    padding-right: 3.75rem !important;
  }
  .lg\:pt-60 {
    padding-top: 3.75rem !important;
  }
  .lg\:pb-60 {
    padding-bottom: 3.75rem !important;
  }
  .lg\:pl-60 {
    padding-left: 3.75rem !important;
  }
  .lg\:pr-60 {
    padding-right: 3.75rem !important;
  }
  .lg\:mt-60 {
    margin-top: 3.75rem !important;
  }
  .lg\:mb-60 {
    margin-bottom: 3.75rem !important;
  }
  .lg\:ml-60 {
    margin-left: 3.75rem !important;
  }
  .lg\:mr-60 {
    margin-right: 3.75rem !important;
  }
  .lg\:py-64 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .lg\:px-64 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .lg\:pt-64 {
    padding-top: 4rem !important;
  }
  .lg\:pb-64 {
    padding-bottom: 4rem !important;
  }
  .lg\:pl-64 {
    padding-left: 4rem !important;
  }
  .lg\:pr-64 {
    padding-right: 4rem !important;
  }
  .lg\:mt-64 {
    margin-top: 4rem !important;
  }
  .lg\:mb-64 {
    margin-bottom: 4rem !important;
  }
  .lg\:ml-64 {
    margin-left: 4rem !important;
  }
  .lg\:mr-64 {
    margin-right: 4rem !important;
  }
  .lg\:py-72 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .lg\:px-72 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .lg\:pt-72 {
    padding-top: 4.5rem !important;
  }
  .lg\:pb-72 {
    padding-bottom: 4.5rem !important;
  }
  .lg\:pl-72 {
    padding-left: 4.5rem !important;
  }
  .lg\:pr-72 {
    padding-right: 4.5rem !important;
  }
  .lg\:mt-72 {
    margin-top: 4.5rem !important;
  }
  .lg\:mb-72 {
    margin-bottom: 4.5rem !important;
  }
  .lg\:ml-72 {
    margin-left: 4.5rem !important;
  }
  .lg\:mr-72 {
    margin-right: 4.5rem !important;
  }
  .lg\:py-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .lg\:px-80 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .lg\:pt-80 {
    padding-top: 5rem !important;
  }
  .lg\:pb-80 {
    padding-bottom: 5rem !important;
  }
  .lg\:pl-80 {
    padding-left: 5rem !important;
  }
  .lg\:pr-80 {
    padding-right: 5rem !important;
  }
  .lg\:mt-80 {
    margin-top: 5rem !important;
  }
  .lg\:mb-80 {
    margin-bottom: 5rem !important;
  }
  .lg\:ml-80 {
    margin-left: 5rem !important;
  }
  .lg\:mr-80 {
    margin-right: 5rem !important;
  }
  .lg\:py-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .lg\:px-100 {
    padding-left: 6.25rem !important;
    padding-right: 6.25rem !important;
  }
  .lg\:pt-100 {
    padding-top: 6.25rem !important;
  }
  .lg\:pb-100 {
    padding-bottom: 6.25rem !important;
  }
  .lg\:pl-100 {
    padding-left: 6.25rem !important;
  }
  .lg\:pr-100 {
    padding-right: 6.25rem !important;
  }
  .lg\:mt-100 {
    margin-top: 6.25rem !important;
  }
  .lg\:mb-100 {
    margin-bottom: 6.25rem !important;
  }
  .lg\:ml-100 {
    margin-left: 6.25rem !important;
  }
  .lg\:mr-100 {
    margin-right: 6.25rem !important;
  }
  .lg\:py-120 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .lg\:px-120 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }
  .lg\:pt-120 {
    padding-top: 7.5rem !important;
  }
  .lg\:pb-120 {
    padding-bottom: 7.5rem !important;
  }
  .lg\:pl-120 {
    padding-left: 7.5rem !important;
  }
  .lg\:pr-120 {
    padding-right: 7.5rem !important;
  }
  .lg\:mt-120 {
    margin-top: 7.5rem !important;
  }
  .lg\:mb-120 {
    margin-bottom: 7.5rem !important;
  }
  .lg\:ml-120 {
    margin-left: 7.5rem !important;
  }
  .lg\:mr-120 {
    margin-right: 7.5rem !important;
  }
}

@media (max-width: 991px) {
  .md\:py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .md\:px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .md\:pt-0 {
    padding-top: 0rem !important;
  }
  .md\:pb-0 {
    padding-bottom: 0rem !important;
  }
  .md\:pl-0 {
    padding-left: 0rem !important;
  }
  .md\:pr-0 {
    padding-right: 0rem !important;
  }
  .md\:mt-0 {
    margin-top: 0rem !important;
  }
  .md\:mb-0 {
    margin-bottom: 0rem !important;
  }
  .md\:ml-0 {
    margin-left: 0rem !important;
  }
  .md\:mr-0 {
    margin-right: 0rem !important;
  }
  .md\:py-4 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .md\:px-4 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .md\:pt-4 {
    padding-top: 0.25rem !important;
  }
  .md\:pb-4 {
    padding-bottom: 0.25rem !important;
  }
  .md\:pl-4 {
    padding-left: 0.25rem !important;
  }
  .md\:pr-4 {
    padding-right: 0.25rem !important;
  }
  .md\:mt-4 {
    margin-top: 0.25rem !important;
  }
  .md\:mb-4 {
    margin-bottom: 0.25rem !important;
  }
  .md\:ml-4 {
    margin-left: 0.25rem !important;
  }
  .md\:mr-4 {
    margin-right: 0.25rem !important;
  }
  .md\:py-8 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .md\:px-8 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .md\:pt-8 {
    padding-top: 0.5rem !important;
  }
  .md\:pb-8 {
    padding-bottom: 0.5rem !important;
  }
  .md\:pl-8 {
    padding-left: 0.5rem !important;
  }
  .md\:pr-8 {
    padding-right: 0.5rem !important;
  }
  .md\:mt-8 {
    margin-top: 0.5rem !important;
  }
  .md\:mb-8 {
    margin-bottom: 0.5rem !important;
  }
  .md\:ml-8 {
    margin-left: 0.5rem !important;
  }
  .md\:mr-8 {
    margin-right: 0.5rem !important;
  }
  .md\:py-10 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .md\:px-10 {
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }
  .md\:pt-10 {
    padding-top: 0.625rem !important;
  }
  .md\:pb-10 {
    padding-bottom: 0.625rem !important;
  }
  .md\:pl-10 {
    padding-left: 0.625rem !important;
  }
  .md\:pr-10 {
    padding-right: 0.625rem !important;
  }
  .md\:mt-10 {
    margin-top: 0.625rem !important;
  }
  .md\:mb-10 {
    margin-bottom: 0.625rem !important;
  }
  .md\:ml-10 {
    margin-left: 0.625rem !important;
  }
  .md\:mr-10 {
    margin-right: 0.625rem !important;
  }
  .md\:py-12 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .md\:px-12 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .md\:pt-12 {
    padding-top: 0.75rem !important;
  }
  .md\:pb-12 {
    padding-bottom: 0.75rem !important;
  }
  .md\:pl-12 {
    padding-left: 0.75rem !important;
  }
  .md\:pr-12 {
    padding-right: 0.75rem !important;
  }
  .md\:mt-12 {
    margin-top: 0.75rem !important;
  }
  .md\:mb-12 {
    margin-bottom: 0.75rem !important;
  }
  .md\:ml-12 {
    margin-left: 0.75rem !important;
  }
  .md\:mr-12 {
    margin-right: 0.75rem !important;
  }
  .md\:py-16 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .md\:px-16 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .md\:pt-16 {
    padding-top: 1rem !important;
  }
  .md\:pb-16 {
    padding-bottom: 1rem !important;
  }
  .md\:pl-16 {
    padding-left: 1rem !important;
  }
  .md\:pr-16 {
    padding-right: 1rem !important;
  }
  .md\:mt-16 {
    margin-top: 1rem !important;
  }
  .md\:mb-16 {
    margin-bottom: 1rem !important;
  }
  .md\:ml-16 {
    margin-left: 1rem !important;
  }
  .md\:mr-16 {
    margin-right: 1rem !important;
  }
  .md\:py-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .md\:px-20 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .md\:pt-20 {
    padding-top: 1.25rem !important;
  }
  .md\:pb-20 {
    padding-bottom: 1.25rem !important;
  }
  .md\:pl-20 {
    padding-left: 1.25rem !important;
  }
  .md\:pr-20 {
    padding-right: 1.25rem !important;
  }
  .md\:mt-20 {
    margin-top: 1.25rem !important;
  }
  .md\:mb-20 {
    margin-bottom: 1.25rem !important;
  }
  .md\:ml-20 {
    margin-left: 1.25rem !important;
  }
  .md\:mr-20 {
    margin-right: 1.25rem !important;
  }
  .md\:py-24 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .md\:px-24 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .md\:pt-24 {
    padding-top: 1.5rem !important;
  }
  .md\:pb-24 {
    padding-bottom: 1.5rem !important;
  }
  .md\:pl-24 {
    padding-left: 1.5rem !important;
  }
  .md\:pr-24 {
    padding-right: 1.5rem !important;
  }
  .md\:mt-24 {
    margin-top: 1.5rem !important;
  }
  .md\:mb-24 {
    margin-bottom: 1.5rem !important;
  }
  .md\:ml-24 {
    margin-left: 1.5rem !important;
  }
  .md\:mr-24 {
    margin-right: 1.5rem !important;
  }
  .md\:py-30 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .md\:px-30 {
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important;
  }
  .md\:pt-30 {
    padding-top: 1.875rem !important;
  }
  .md\:pb-30 {
    padding-bottom: 1.875rem !important;
  }
  .md\:pl-30 {
    padding-left: 1.875rem !important;
  }
  .md\:pr-30 {
    padding-right: 1.875rem !important;
  }
  .md\:mt-30 {
    margin-top: 1.875rem !important;
  }
  .md\:mb-30 {
    margin-bottom: 1.875rem !important;
  }
  .md\:ml-30 {
    margin-left: 1.875rem !important;
  }
  .md\:mr-30 {
    margin-right: 1.875rem !important;
  }
  .md\:py-32 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .md\:px-32 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .md\:pt-32 {
    padding-top: 2rem !important;
  }
  .md\:pb-32 {
    padding-bottom: 2rem !important;
  }
  .md\:pl-32 {
    padding-left: 2rem !important;
  }
  .md\:pr-32 {
    padding-right: 2rem !important;
  }
  .md\:mt-32 {
    margin-top: 2rem !important;
  }
  .md\:mb-32 {
    margin-bottom: 2rem !important;
  }
  .md\:ml-32 {
    margin-left: 2rem !important;
  }
  .md\:mr-32 {
    margin-right: 2rem !important;
  }
  .md\:py-34 {
    padding-top: 2.125rem !important;
    padding-bottom: 2.125rem !important;
  }
  .md\:px-34 {
    padding-left: 2.125rem !important;
    padding-right: 2.125rem !important;
  }
  .md\:pt-34 {
    padding-top: 2.125rem !important;
  }
  .md\:pb-34 {
    padding-bottom: 2.125rem !important;
  }
  .md\:pl-34 {
    padding-left: 2.125rem !important;
  }
  .md\:pr-34 {
    padding-right: 2.125rem !important;
  }
  .md\:mt-34 {
    margin-top: 2.125rem !important;
  }
  .md\:mb-34 {
    margin-bottom: 2.125rem !important;
  }
  .md\:ml-34 {
    margin-left: 2.125rem !important;
  }
  .md\:mr-34 {
    margin-right: 2.125rem !important;
  }
  .md\:py-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .md\:px-40 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .md\:pt-40 {
    padding-top: 2.5rem !important;
  }
  .md\:pb-40 {
    padding-bottom: 2.5rem !important;
  }
  .md\:pl-40 {
    padding-left: 2.5rem !important;
  }
  .md\:pr-40 {
    padding-right: 2.5rem !important;
  }
  .md\:mt-40 {
    margin-top: 2.5rem !important;
  }
  .md\:mb-40 {
    margin-bottom: 2.5rem !important;
  }
  .md\:ml-40 {
    margin-left: 2.5rem !important;
  }
  .md\:mr-40 {
    margin-right: 2.5rem !important;
  }
  .md\:py-48 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .md\:px-48 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .md\:pt-48 {
    padding-top: 3rem !important;
  }
  .md\:pb-48 {
    padding-bottom: 3rem !important;
  }
  .md\:pl-48 {
    padding-left: 3rem !important;
  }
  .md\:pr-48 {
    padding-right: 3rem !important;
  }
  .md\:mt-48 {
    margin-top: 3rem !important;
  }
  .md\:mb-48 {
    margin-bottom: 3rem !important;
  }
  .md\:ml-48 {
    margin-left: 3rem !important;
  }
  .md\:mr-48 {
    margin-right: 3rem !important;
  }
  .md\:py-50 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .md\:px-50 {
    padding-left: 3.125rem !important;
    padding-right: 3.125rem !important;
  }
  .md\:pt-50 {
    padding-top: 3.125rem !important;
  }
  .md\:pb-50 {
    padding-bottom: 3.125rem !important;
  }
  .md\:pl-50 {
    padding-left: 3.125rem !important;
  }
  .md\:pr-50 {
    padding-right: 3.125rem !important;
  }
  .md\:mt-50 {
    margin-top: 3.125rem !important;
  }
  .md\:mb-50 {
    margin-bottom: 3.125rem !important;
  }
  .md\:ml-50 {
    margin-left: 3.125rem !important;
  }
  .md\:mr-50 {
    margin-right: 3.125rem !important;
  }
  .md\:py-60 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .md\:px-60 {
    padding-left: 3.75rem !important;
    padding-right: 3.75rem !important;
  }
  .md\:pt-60 {
    padding-top: 3.75rem !important;
  }
  .md\:pb-60 {
    padding-bottom: 3.75rem !important;
  }
  .md\:pl-60 {
    padding-left: 3.75rem !important;
  }
  .md\:pr-60 {
    padding-right: 3.75rem !important;
  }
  .md\:mt-60 {
    margin-top: 3.75rem !important;
  }
  .md\:mb-60 {
    margin-bottom: 3.75rem !important;
  }
  .md\:ml-60 {
    margin-left: 3.75rem !important;
  }
  .md\:mr-60 {
    margin-right: 3.75rem !important;
  }
  .md\:py-64 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .md\:px-64 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .md\:pt-64 {
    padding-top: 4rem !important;
  }
  .md\:pb-64 {
    padding-bottom: 4rem !important;
  }
  .md\:pl-64 {
    padding-left: 4rem !important;
  }
  .md\:pr-64 {
    padding-right: 4rem !important;
  }
  .md\:mt-64 {
    margin-top: 4rem !important;
  }
  .md\:mb-64 {
    margin-bottom: 4rem !important;
  }
  .md\:ml-64 {
    margin-left: 4rem !important;
  }
  .md\:mr-64 {
    margin-right: 4rem !important;
  }
  .md\:py-72 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .md\:px-72 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .md\:pt-72 {
    padding-top: 4.5rem !important;
  }
  .md\:pb-72 {
    padding-bottom: 4.5rem !important;
  }
  .md\:pl-72 {
    padding-left: 4.5rem !important;
  }
  .md\:pr-72 {
    padding-right: 4.5rem !important;
  }
  .md\:mt-72 {
    margin-top: 4.5rem !important;
  }
  .md\:mb-72 {
    margin-bottom: 4.5rem !important;
  }
  .md\:ml-72 {
    margin-left: 4.5rem !important;
  }
  .md\:mr-72 {
    margin-right: 4.5rem !important;
  }
  .md\:py-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .md\:px-80 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .md\:pt-80 {
    padding-top: 5rem !important;
  }
  .md\:pb-80 {
    padding-bottom: 5rem !important;
  }
  .md\:pl-80 {
    padding-left: 5rem !important;
  }
  .md\:pr-80 {
    padding-right: 5rem !important;
  }
  .md\:mt-80 {
    margin-top: 5rem !important;
  }
  .md\:mb-80 {
    margin-bottom: 5rem !important;
  }
  .md\:ml-80 {
    margin-left: 5rem !important;
  }
  .md\:mr-80 {
    margin-right: 5rem !important;
  }
  .md\:py-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .md\:px-100 {
    padding-left: 6.25rem !important;
    padding-right: 6.25rem !important;
  }
  .md\:pt-100 {
    padding-top: 6.25rem !important;
  }
  .md\:pb-100 {
    padding-bottom: 6.25rem !important;
  }
  .md\:pl-100 {
    padding-left: 6.25rem !important;
  }
  .md\:pr-100 {
    padding-right: 6.25rem !important;
  }
  .md\:mt-100 {
    margin-top: 6.25rem !important;
  }
  .md\:mb-100 {
    margin-bottom: 6.25rem !important;
  }
  .md\:ml-100 {
    margin-left: 6.25rem !important;
  }
  .md\:mr-100 {
    margin-right: 6.25rem !important;
  }
  .md\:py-120 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .md\:px-120 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }
  .md\:pt-120 {
    padding-top: 7.5rem !important;
  }
  .md\:pb-120 {
    padding-bottom: 7.5rem !important;
  }
  .md\:pl-120 {
    padding-left: 7.5rem !important;
  }
  .md\:pr-120 {
    padding-right: 7.5rem !important;
  }
  .md\:mt-120 {
    margin-top: 7.5rem !important;
  }
  .md\:mb-120 {
    margin-bottom: 7.5rem !important;
  }
  .md\:ml-120 {
    margin-left: 7.5rem !important;
  }
  .md\:mr-120 {
    margin-right: 7.5rem !important;
  }
}

@media (max-width: 767px) {
  .sm\:py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .sm\:px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .sm\:pt-0 {
    padding-top: 0rem !important;
  }
  .sm\:pb-0 {
    padding-bottom: 0rem !important;
  }
  .sm\:pl-0 {
    padding-left: 0rem !important;
  }
  .sm\:pr-0 {
    padding-right: 0rem !important;
  }
  .sm\:mt-0 {
    margin-top: 0rem !important;
  }
  .sm\:mb-0 {
    margin-bottom: 0rem !important;
  }
  .sm\:ml-0 {
    margin-left: 0rem !important;
  }
  .sm\:mr-0 {
    margin-right: 0rem !important;
  }
  .sm\:py-4 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .sm\:px-4 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .sm\:pt-4 {
    padding-top: 0.25rem !important;
  }
  .sm\:pb-4 {
    padding-bottom: 0.25rem !important;
  }
  .sm\:pl-4 {
    padding-left: 0.25rem !important;
  }
  .sm\:pr-4 {
    padding-right: 0.25rem !important;
  }
  .sm\:mt-4 {
    margin-top: 0.25rem !important;
  }
  .sm\:mb-4 {
    margin-bottom: 0.25rem !important;
  }
  .sm\:ml-4 {
    margin-left: 0.25rem !important;
  }
  .sm\:mr-4 {
    margin-right: 0.25rem !important;
  }
  .sm\:py-8 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .sm\:px-8 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .sm\:pt-8 {
    padding-top: 0.5rem !important;
  }
  .sm\:pb-8 {
    padding-bottom: 0.5rem !important;
  }
  .sm\:pl-8 {
    padding-left: 0.5rem !important;
  }
  .sm\:pr-8 {
    padding-right: 0.5rem !important;
  }
  .sm\:mt-8 {
    margin-top: 0.5rem !important;
  }
  .sm\:mb-8 {
    margin-bottom: 0.5rem !important;
  }
  .sm\:ml-8 {
    margin-left: 0.5rem !important;
  }
  .sm\:mr-8 {
    margin-right: 0.5rem !important;
  }
  .sm\:py-10 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .sm\:px-10 {
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }
  .sm\:pt-10 {
    padding-top: 0.625rem !important;
  }
  .sm\:pb-10 {
    padding-bottom: 0.625rem !important;
  }
  .sm\:pl-10 {
    padding-left: 0.625rem !important;
  }
  .sm\:pr-10 {
    padding-right: 0.625rem !important;
  }
  .sm\:mt-10 {
    margin-top: 0.625rem !important;
  }
  .sm\:mb-10 {
    margin-bottom: 0.625rem !important;
  }
  .sm\:ml-10 {
    margin-left: 0.625rem !important;
  }
  .sm\:mr-10 {
    margin-right: 0.625rem !important;
  }
  .sm\:py-12 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .sm\:px-12 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .sm\:pt-12 {
    padding-top: 0.75rem !important;
  }
  .sm\:pb-12 {
    padding-bottom: 0.75rem !important;
  }
  .sm\:pl-12 {
    padding-left: 0.75rem !important;
  }
  .sm\:pr-12 {
    padding-right: 0.75rem !important;
  }
  .sm\:mt-12 {
    margin-top: 0.75rem !important;
  }
  .sm\:mb-12 {
    margin-bottom: 0.75rem !important;
  }
  .sm\:ml-12 {
    margin-left: 0.75rem !important;
  }
  .sm\:mr-12 {
    margin-right: 0.75rem !important;
  }
  .sm\:py-16 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .sm\:px-16 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .sm\:pt-16 {
    padding-top: 1rem !important;
  }
  .sm\:pb-16 {
    padding-bottom: 1rem !important;
  }
  .sm\:pl-16 {
    padding-left: 1rem !important;
  }
  .sm\:pr-16 {
    padding-right: 1rem !important;
  }
  .sm\:mt-16 {
    margin-top: 1rem !important;
  }
  .sm\:mb-16 {
    margin-bottom: 1rem !important;
  }
  .sm\:ml-16 {
    margin-left: 1rem !important;
  }
  .sm\:mr-16 {
    margin-right: 1rem !important;
  }
  .sm\:py-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .sm\:px-20 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .sm\:pt-20 {
    padding-top: 1.25rem !important;
  }
  .sm\:pb-20 {
    padding-bottom: 1.25rem !important;
  }
  .sm\:pl-20 {
    padding-left: 1.25rem !important;
  }
  .sm\:pr-20 {
    padding-right: 1.25rem !important;
  }
  .sm\:mt-20 {
    margin-top: 1.25rem !important;
  }
  .sm\:mb-20 {
    margin-bottom: 1.25rem !important;
  }
  .sm\:ml-20 {
    margin-left: 1.25rem !important;
  }
  .sm\:mr-20 {
    margin-right: 1.25rem !important;
  }
  .sm\:py-24 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .sm\:px-24 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .sm\:pt-24 {
    padding-top: 1.5rem !important;
  }
  .sm\:pb-24 {
    padding-bottom: 1.5rem !important;
  }
  .sm\:pl-24 {
    padding-left: 1.5rem !important;
  }
  .sm\:pr-24 {
    padding-right: 1.5rem !important;
  }
  .sm\:mt-24 {
    margin-top: 1.5rem !important;
  }
  .sm\:mb-24 {
    margin-bottom: 1.5rem !important;
  }
  .sm\:ml-24 {
    margin-left: 1.5rem !important;
  }
  .sm\:mr-24 {
    margin-right: 1.5rem !important;
  }
  .sm\:py-30 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .sm\:px-30 {
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important;
  }
  .sm\:pt-30 {
    padding-top: 1.875rem !important;
  }
  .sm\:pb-30 {
    padding-bottom: 1.875rem !important;
  }
  .sm\:pl-30 {
    padding-left: 1.875rem !important;
  }
  .sm\:pr-30 {
    padding-right: 1.875rem !important;
  }
  .sm\:mt-30 {
    margin-top: 1.875rem !important;
  }
  .sm\:mb-30 {
    margin-bottom: 1.875rem !important;
  }
  .sm\:ml-30 {
    margin-left: 1.875rem !important;
  }
  .sm\:mr-30 {
    margin-right: 1.875rem !important;
  }
  .sm\:py-32 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .sm\:px-32 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .sm\:pt-32 {
    padding-top: 2rem !important;
  }
  .sm\:pb-32 {
    padding-bottom: 2rem !important;
  }
  .sm\:pl-32 {
    padding-left: 2rem !important;
  }
  .sm\:pr-32 {
    padding-right: 2rem !important;
  }
  .sm\:mt-32 {
    margin-top: 2rem !important;
  }
  .sm\:mb-32 {
    margin-bottom: 2rem !important;
  }
  .sm\:ml-32 {
    margin-left: 2rem !important;
  }
  .sm\:mr-32 {
    margin-right: 2rem !important;
  }
  .sm\:py-34 {
    padding-top: 2.125rem !important;
    padding-bottom: 2.125rem !important;
  }
  .sm\:px-34 {
    padding-left: 2.125rem !important;
    padding-right: 2.125rem !important;
  }
  .sm\:pt-34 {
    padding-top: 2.125rem !important;
  }
  .sm\:pb-34 {
    padding-bottom: 2.125rem !important;
  }
  .sm\:pl-34 {
    padding-left: 2.125rem !important;
  }
  .sm\:pr-34 {
    padding-right: 2.125rem !important;
  }
  .sm\:mt-34 {
    margin-top: 2.125rem !important;
  }
  .sm\:mb-34 {
    margin-bottom: 2.125rem !important;
  }
  .sm\:ml-34 {
    margin-left: 2.125rem !important;
  }
  .sm\:mr-34 {
    margin-right: 2.125rem !important;
  }
  .sm\:py-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .sm\:px-40 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .sm\:pt-40 {
    padding-top: 2.5rem !important;
  }
  .sm\:pb-40 {
    padding-bottom: 2.5rem !important;
  }
  .sm\:pl-40 {
    padding-left: 2.5rem !important;
  }
  .sm\:pr-40 {
    padding-right: 2.5rem !important;
  }
  .sm\:mt-40 {
    margin-top: 2.5rem !important;
  }
  .sm\:mb-40 {
    margin-bottom: 2.5rem !important;
  }
  .sm\:ml-40 {
    margin-left: 2.5rem !important;
  }
  .sm\:mr-40 {
    margin-right: 2.5rem !important;
  }
  .sm\:py-48 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .sm\:px-48 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .sm\:pt-48 {
    padding-top: 3rem !important;
  }
  .sm\:pb-48 {
    padding-bottom: 3rem !important;
  }
  .sm\:pl-48 {
    padding-left: 3rem !important;
  }
  .sm\:pr-48 {
    padding-right: 3rem !important;
  }
  .sm\:mt-48 {
    margin-top: 3rem !important;
  }
  .sm\:mb-48 {
    margin-bottom: 3rem !important;
  }
  .sm\:ml-48 {
    margin-left: 3rem !important;
  }
  .sm\:mr-48 {
    margin-right: 3rem !important;
  }
  .sm\:py-50 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .sm\:px-50 {
    padding-left: 3.125rem !important;
    padding-right: 3.125rem !important;
  }
  .sm\:pt-50 {
    padding-top: 3.125rem !important;
  }
  .sm\:pb-50 {
    padding-bottom: 3.125rem !important;
  }
  .sm\:pl-50 {
    padding-left: 3.125rem !important;
  }
  .sm\:pr-50 {
    padding-right: 3.125rem !important;
  }
  .sm\:mt-50 {
    margin-top: 3.125rem !important;
  }
  .sm\:mb-50 {
    margin-bottom: 3.125rem !important;
  }
  .sm\:ml-50 {
    margin-left: 3.125rem !important;
  }
  .sm\:mr-50 {
    margin-right: 3.125rem !important;
  }
  .sm\:py-60 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .sm\:px-60 {
    padding-left: 3.75rem !important;
    padding-right: 3.75rem !important;
  }
  .sm\:pt-60 {
    padding-top: 3.75rem !important;
  }
  .sm\:pb-60 {
    padding-bottom: 3.75rem !important;
  }
  .sm\:pl-60 {
    padding-left: 3.75rem !important;
  }
  .sm\:pr-60 {
    padding-right: 3.75rem !important;
  }
  .sm\:mt-60 {
    margin-top: 3.75rem !important;
  }
  .sm\:mb-60 {
    margin-bottom: 3.75rem !important;
  }
  .sm\:ml-60 {
    margin-left: 3.75rem !important;
  }
  .sm\:mr-60 {
    margin-right: 3.75rem !important;
  }
  .sm\:py-64 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .sm\:px-64 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .sm\:pt-64 {
    padding-top: 4rem !important;
  }
  .sm\:pb-64 {
    padding-bottom: 4rem !important;
  }
  .sm\:pl-64 {
    padding-left: 4rem !important;
  }
  .sm\:pr-64 {
    padding-right: 4rem !important;
  }
  .sm\:mt-64 {
    margin-top: 4rem !important;
  }
  .sm\:mb-64 {
    margin-bottom: 4rem !important;
  }
  .sm\:ml-64 {
    margin-left: 4rem !important;
  }
  .sm\:mr-64 {
    margin-right: 4rem !important;
  }
  .sm\:py-72 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .sm\:px-72 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .sm\:pt-72 {
    padding-top: 4.5rem !important;
  }
  .sm\:pb-72 {
    padding-bottom: 4.5rem !important;
  }
  .sm\:pl-72 {
    padding-left: 4.5rem !important;
  }
  .sm\:pr-72 {
    padding-right: 4.5rem !important;
  }
  .sm\:mt-72 {
    margin-top: 4.5rem !important;
  }
  .sm\:mb-72 {
    margin-bottom: 4.5rem !important;
  }
  .sm\:ml-72 {
    margin-left: 4.5rem !important;
  }
  .sm\:mr-72 {
    margin-right: 4.5rem !important;
  }
  .sm\:py-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .sm\:px-80 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .sm\:pt-80 {
    padding-top: 5rem !important;
  }
  .sm\:pb-80 {
    padding-bottom: 5rem !important;
  }
  .sm\:pl-80 {
    padding-left: 5rem !important;
  }
  .sm\:pr-80 {
    padding-right: 5rem !important;
  }
  .sm\:mt-80 {
    margin-top: 5rem !important;
  }
  .sm\:mb-80 {
    margin-bottom: 5rem !important;
  }
  .sm\:ml-80 {
    margin-left: 5rem !important;
  }
  .sm\:mr-80 {
    margin-right: 5rem !important;
  }
  .sm\:py-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .sm\:px-100 {
    padding-left: 6.25rem !important;
    padding-right: 6.25rem !important;
  }
  .sm\:pt-100 {
    padding-top: 6.25rem !important;
  }
  .sm\:pb-100 {
    padding-bottom: 6.25rem !important;
  }
  .sm\:pl-100 {
    padding-left: 6.25rem !important;
  }
  .sm\:pr-100 {
    padding-right: 6.25rem !important;
  }
  .sm\:mt-100 {
    margin-top: 6.25rem !important;
  }
  .sm\:mb-100 {
    margin-bottom: 6.25rem !important;
  }
  .sm\:ml-100 {
    margin-left: 6.25rem !important;
  }
  .sm\:mr-100 {
    margin-right: 6.25rem !important;
  }
  .sm\:py-120 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .sm\:px-120 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }
  .sm\:pt-120 {
    padding-top: 7.5rem !important;
  }
  .sm\:pb-120 {
    padding-bottom: 7.5rem !important;
  }
  .sm\:pl-120 {
    padding-left: 7.5rem !important;
  }
  .sm\:pr-120 {
    padding-right: 7.5rem !important;
  }
  .sm\:mt-120 {
    margin-top: 7.5rem !important;
  }
  .sm\:mb-120 {
    margin-bottom: 7.5rem !important;
  }
  .sm\:ml-120 {
    margin-left: 7.5rem !important;
  }
  .sm\:mr-120 {
    margin-right: 7.5rem !important;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.lg\:mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.md\:mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.sm\:mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.w-1\/1 {
  width: 100% !important;
}

@media (max-width: 1199px) {
  .lg\:w-1\/1 {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .md\:w-1\/1 {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .sm\:w-1\/1 {
    width: 100%;
  }
}

.h-sm {
  height: 32.5rem;
}

.h-md {
  height: 36.25rem;
}

.h-lg {
  height: 42.5rem;
}

.h-70vh {
  height: 70vh;
}

.h-80vh {
  height: 80vh;
}

.h-90vh {
  height: 90vh;
}

.h-100vh {
  height: 100vh;
}

.h-unset {
  height: unset;
}

.h-1\/1 {
  height: 100%;
}

@media (max-width: 991px) {
  .md\:h-unset {
    height: unset;
  }
  .md\:h-70vh {
    height: 70vh;
  }
  .md\:h-80vh {
    height: 80vh;
  }
  .md\:h-90vh {
    height: 90vh;
  }
  .md\:h-100vh {
    height: 100vh;
  }
}

@media (max-width: 767px) {
  .sm\:h-md {
    height: 36.25rem;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

@media (max-width: 1199px) {
  .lg\:text-center {
    text-align: center !important;
  }
  .lg\:text-right {
    text-align: right !important;
  }
  .lg\:text-left {
    text-align: left !important;
  }
}

@media (max-width: 991px) {
  .md\:text-center {
    text-align: center !important;
  }
  .md\:text-right {
    text-align: right !important;
  }
  .md\:text-left {
    text-align: left !important;
  }
}

@media (max-width: 767px) {
  .sm\:text-center {
    text-align: center !important;
  }
  .sm\:text-right {
    text-align: right !important;
  }
  .sm\:text-left {
    text-align: left !important;
  }
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-end {
  align-items: flex-end !important;
}

.items-center {
  align-items: center !important;
}

@media (max-width: 1199px) {
  .lg\:justify-start {
    justify-content: flex-start !important;
  }
  .lg\:justify-end {
    justify-content: flex-end !important;
  }
  .lg\:justify-center {
    justify-content: center !important;
  }
  .lg\:justify-between {
    justify-content: space-between !important;
  }
  .lg\:items-start {
    align-items: flex-start !important;
  }
  .lg\:items-end {
    align-items: flex-end !important;
  }
  .lg\:items-center {
    align-items: center !important;
  }
}

@media (max-width: 991px) {
  .md\:justify-start {
    justify-content: flex-start !important;
  }
  .md\:justify-end {
    justify-content: flex-end !important;
  }
  .md\:justify-center {
    justify-content: center !important;
  }
  .md\:justify-between {
    justify-content: space-between !important;
  }
  .md\:items-start {
    align-items: flex-start !important;
  }
  .md\:items-end {
    align-items: flex-end !important;
  }
  .md\:items-center {
    align-items: center !important;
  }
}

@media (max-width: 767px) {
  .sm\:justify-start {
    justify-content: flex-start !important;
  }
  .sm\:justify-end {
    justify-content: flex-end !important;
  }
  .sm\:justify-center {
    justify-content: center !important;
  }
  .sm\:justify-between {
    justify-content: space-between !important;
  }
  .sm\:items-start {
    align-items: flex-start !important;
  }
  .sm\:items-end {
    align-items: flex-end !important;
  }
  .sm\:items-center {
    align-items: center !important;
  }
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

@media (max-width: 1199px) {
  .lg\:d-none {
    display: none !important;
  }
  .lg\:d-inline-block {
    display: inline-block !important;
  }
  .lg\:d-block {
    display: block !important;
  }
  .lg\:d-flex {
    display: flex !important;
  }
}

@media (max-width: 991px) {
  .md\:d-none {
    display: none !important;
  }
  .md\:d-inline-block {
    display: inline-block !important;
  }
  .md\:d-block {
    display: block !important;
  }
  .md\:d-flex {
    display: flex !important;
  }
}

@media (max-width: 767px) {
  .sm\:d-none {
    display: none !important;
  }
  .sm\:d-inline-block {
    display: inline-block !important;
  }
  .sm\:d-block {
    display: block !important;
  }
  .sm\:d-flex {
    display: flex !important;
  }
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

@media (max-width: 1199px) {
  .lg\:order-1 {
    order: 1;
  }
  .lg\:order-2 {
    order: 2;
  }
}

@media (max-width: 991px) {
  .md\:order-1 {
    order: 1;
  }
  .md\:order-2 {
    order: 2;
  }
}

@media (max-width: 767px) {
  .sm\:order-1 {
    order: 1;
  }
  .sm\:order-2 {
    order: 2;
  }
}

.layout-pt-xs {
  padding-top: 2.5rem;
}

.layout-pt-sm {
  padding-top: 3.75rem;
}

.layout-pt-md {
  padding-top: 6.25rem;
}

.layout-pt-lg {
  padding-top: 7.5rem;
}

.layout-pt-xl {
  padding-top: 8.75rem;
}

.layout-pt-2xl {
  padding-top: 11.25rem;
}

@media (max-width: 991px) {
  .layout-pt-md {
    padding-top: 3.75rem;
  }
  .layout-pt-lg {
    padding-top: 6.25rem;
  }
  .layout-pt-xl {
    padding-top: 7.5rem;
  }
  .layout-pt-2xl {
    padding-top: 10rem;
  }
}

@media (max-width: 767px) {
  .layout-pt-sm {
    padding-top: 3.125rem;
  }
  .layout-pt-md {
    padding-top: 3.75rem;
  }
  .layout-pt-lg {
    padding-top: 6.25rem;
  }
  .layout-pt-xl {
    padding-top: 7.5rem;
  }
  .layout-pt-2xl {
    padding-top: 8.125rem;
  }
}

.layout-pb-xs {
  padding-bottom: 2.5rem;
}

.layout-pb-sm {
  padding-bottom: 3.75rem;
}

.layout-pb-md {
  padding-bottom: 6.25rem;
}

.layout-pb-lg {
  padding-bottom: 7.5rem;
}

.layout-pb-xl {
  padding-bottom: 8.75rem;
}

.layout-pb-2xl {
  padding-bottom: 11.25rem;
}

@media (max-width: 991px) {
  .layout-pb-md {
    padding-bottom: 3.75rem;
  }
  .layout-pb-lg {
    padding-bottom: 6.25rem;
  }
  .layout-pb-xl {
    padding-bottom: 7.5rem;
  }
  .layout-pb-2xl {
    padding-bottom: 10rem;
  }
}

@media (max-width: 767px) {
  .layout-pb-sm {
    padding-bottom: 3.125rem;
  }
  .layout-pb-md {
    padding-bottom: 3.75rem;
  }
  .layout-pb-lg {
    padding-bottom: 6.25rem;
  }
  .layout-pb-xl {
    padding-bottom: 7.5rem;
  }
  .layout-pb-2xl {
    padding-bottom: 8.125rem;
  }
}

.x-gap-80 {
  margin-left: -80/32rem;
  margin-right: -80/32rem;
}

.x-gap-80 > * {
  padding-left: 80 / 32rem;
  padding-right: 80 / 32rem;
}

.x-gap-4 {
  margin-left: -0.125rem;
  margin-right: -0.125rem;
}

.x-gap-4 > * {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.x-gap-10 {
  margin-left: -0.3125rem;
  margin-right: -0.3125rem;
}

.x-gap-10 > * {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}

.x-gap-16 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.x-gap-16 > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.x-gap-18 {
  margin-left: -0.5625rem;
  margin-right: -0.5625rem;
}

.x-gap-18 > * {
  padding-left: 0.5625rem;
  padding-right: 0.5625rem;
}

.x-gap-20 {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}

.x-gap-20 > * {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.x-gap-24 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.x-gap-24 > * {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.x-gap-32 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.x-gap-32 > * {
  padding-left: 1rem;
  padding-right: 1rem;
}

.x-gap-40 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.x-gap-40 > * {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.x-gap-48 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.x-gap-48 > * {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.x-gap-60 {
  margin-left: -1.875rem;
  margin-right: -1.875rem;
}

.x-gap-60 > * {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}

.x-gap-64 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.x-gap-64 > * {
  padding-left: 2rem;
  padding-right: 2rem;
}

.x-gap-72 {
  margin-left: -2.25rem;
  margin-right: -2.25rem;
}

.x-gap-72 > * {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.x-gap-100 {
  margin-left: -3.125rem;
  margin-right: -3.125rem;
}

.x-gap-100 > * {
  padding-left: 3.125rem;
  padding-right: 3.125rem;
}

.x-gap-120 {
  margin-left: -3.75rem;
  margin-right: -3.75rem;
}

.x-gap-120 > * {
  padding-left: 3.75rem;
  padding-right: 3.75rem;
}

.y-gap-4 {
  margin-top: -0.125rem;
  margin-bottom: -0.125rem;
}

.y-gap-4 > * {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.y-gap-10 {
  margin-top: -0.3125rem;
  margin-bottom: -0.3125rem;
}

.y-gap-10 > * {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}

.y-gap-16 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.y-gap-16 > * {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.y-gap-18 {
  margin-top: -0.5625rem;
  margin-bottom: -0.5625rem;
}

.y-gap-18 > * {
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
}

.y-gap-20 {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}

.y-gap-20 > * {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.y-gap-24 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}

.y-gap-24 > * {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.y-gap-32 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.y-gap-32 > * {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.y-gap-40 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.y-gap-40 > * {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.y-gap-48 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.y-gap-48 > * {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.y-gap-60 {
  margin-top: -1.875rem;
  margin-bottom: -1.875rem;
}

.y-gap-60 > * {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}

.y-gap-64 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.y-gap-64 > * {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.y-gap-72 {
  margin-top: -2.25rem;
  margin-bottom: -2.25rem;
}

.y-gap-72 > * {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.y-gap-100 {
  margin-top: -3.125rem;
  margin-bottom: -3.125rem;
}

.y-gap-100 > * {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}

.y-gap-120 {
  margin-top: -3.75rem;
  margin-bottom: -3.75rem;
}

.y-gap-120 > * {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}

@media (max-width: 991px) {
  .md\:x-gap-4 {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }
  .md\:x-gap-4 > * {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .md\:x-gap-10 {
    margin-left: -0.3125rem;
    margin-right: -0.3125rem;
  }
  .md\:x-gap-10 > * {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }
  .md\:x-gap-16 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .md\:x-gap-16 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .md\:x-gap-18 {
    margin-left: -0.5625rem;
    margin-right: -0.5625rem;
  }
  .md\:x-gap-18 > * {
    padding-left: 0.5625rem;
    padding-right: 0.5625rem;
  }
  .md\:x-gap-20 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
  .md\:x-gap-20 > * {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .md\:x-gap-24 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .md\:x-gap-24 > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .md\:x-gap-32 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .md\:x-gap-32 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .md\:x-gap-40 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .md\:x-gap-40 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .md\:x-gap-48 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .md\:x-gap-48 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .md\:x-gap-60 {
    margin-left: -1.875rem;
    margin-right: -1.875rem;
  }
  .md\:x-gap-60 > * {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  .md\:x-gap-64 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .md\:x-gap-64 > * {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .md\:x-gap-72 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }
  .md\:x-gap-72 > * {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .md\:x-gap-100 {
    margin-left: -3.125rem;
    margin-right: -3.125rem;
  }
  .md\:x-gap-100 > * {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
  .md\:x-gap-120 {
    margin-left: -3.75rem;
    margin-right: -3.75rem;
  }
  .md\:x-gap-120 > * {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
  .md\:y-gap-4 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  }
  .md\:y-gap-4 > * {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .md\:y-gap-10 {
    margin-top: -0.3125rem;
    margin-bottom: -0.3125rem;
  }
  .md\:y-gap-10 > * {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  .md\:y-gap-16 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .md\:y-gap-16 > * {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .md\:y-gap-18 {
    margin-top: -0.5625rem;
    margin-bottom: -0.5625rem;
  }
  .md\:y-gap-18 > * {
    padding-top: 0.5625rem;
    padding-bottom: 0.5625rem;
  }
  .md\:y-gap-20 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
  .md\:y-gap-20 > * {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .md\:y-gap-24 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .md\:y-gap-24 > * {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .md\:y-gap-32 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .md\:y-gap-32 > * {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .md\:y-gap-40 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .md\:y-gap-40 > * {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .md\:y-gap-48 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .md\:y-gap-48 > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .md\:y-gap-60 {
    margin-top: -1.875rem;
    margin-bottom: -1.875rem;
  }
  .md\:y-gap-60 > * {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  .md\:y-gap-64 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .md\:y-gap-64 > * {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .md\:y-gap-72 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }
  .md\:y-gap-72 > * {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .md\:y-gap-100 {
    margin-top: -3.125rem;
    margin-bottom: -3.125rem;
  }
  .md\:y-gap-100 > * {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
  .md\:y-gap-120 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }
  .md\:y-gap-120 > * {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
}

@media (max-width: 767px) {
  .sm\:x-gap-4 {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }
  .sm\:x-gap-4 > * {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .sm\:x-gap-10 {
    margin-left: -0.3125rem;
    margin-right: -0.3125rem;
  }
  .sm\:x-gap-10 > * {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }
  .sm\:x-gap-16 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .sm\:x-gap-16 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .sm\:x-gap-18 {
    margin-left: -0.5625rem;
    margin-right: -0.5625rem;
  }
  .sm\:x-gap-18 > * {
    padding-left: 0.5625rem;
    padding-right: 0.5625rem;
  }
  .sm\:x-gap-20 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
  .sm\:x-gap-20 > * {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .sm\:x-gap-24 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .sm\:x-gap-24 > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .sm\:x-gap-32 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .sm\:x-gap-32 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .sm\:x-gap-40 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .sm\:x-gap-40 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .sm\:x-gap-48 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .sm\:x-gap-48 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .sm\:x-gap-60 {
    margin-left: -1.875rem;
    margin-right: -1.875rem;
  }
  .sm\:x-gap-60 > * {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  .sm\:x-gap-64 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .sm\:x-gap-64 > * {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .sm\:x-gap-72 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }
  .sm\:x-gap-72 > * {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .sm\:x-gap-100 {
    margin-left: -3.125rem;
    margin-right: -3.125rem;
  }
  .sm\:x-gap-100 > * {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
  .sm\:x-gap-120 {
    margin-left: -3.75rem;
    margin-right: -3.75rem;
  }
  .sm\:x-gap-120 > * {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
  .sm\:y-gap-4 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  }
  .sm\:y-gap-4 > * {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .sm\:y-gap-10 {
    margin-top: -0.3125rem;
    margin-bottom: -0.3125rem;
  }
  .sm\:y-gap-10 > * {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  .sm\:y-gap-16 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .sm\:y-gap-16 > * {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .sm\:y-gap-18 {
    margin-top: -0.5625rem;
    margin-bottom: -0.5625rem;
  }
  .sm\:y-gap-18 > * {
    padding-top: 0.5625rem;
    padding-bottom: 0.5625rem;
  }
  .sm\:y-gap-20 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
  .sm\:y-gap-20 > * {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .sm\:y-gap-24 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .sm\:y-gap-24 > * {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .sm\:y-gap-32 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .sm\:y-gap-32 > * {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .sm\:y-gap-40 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .sm\:y-gap-40 > * {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .sm\:y-gap-48 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .sm\:y-gap-48 > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .sm\:y-gap-60 {
    margin-top: -1.875rem;
    margin-bottom: -1.875rem;
  }
  .sm\:y-gap-60 > * {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  .sm\:y-gap-64 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .sm\:y-gap-64 > * {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .sm\:y-gap-72 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }
  .sm\:y-gap-72 > * {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .sm\:y-gap-100 {
    margin-top: -3.125rem;
    margin-bottom: -3.125rem;
  }
  .sm\:y-gap-100 > * {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
  .sm\:y-gap-120 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }
  .sm\:y-gap-120 > * {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
}

.mt--220 {
  margin-top: -13.75rem;
}

.mt--80 {
  margin-top: -5rem;
}

.mb-minus-170 {
  margin-bottom: -170px;
}

.mb-minus-lg {
  margin-bottom: -3.75rem;
}

.mt-minus-xl {
  margin-top: -15rem;
}
