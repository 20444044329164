//Mobile------------------------------------------------------------------------------------------------------
.timeline {
	h2 {
		color: var(--color-primary);
	}
	h3 {
		font-size: 1.5rem;
		font-weight: 700;
		color: var(--color-text);
	}
	p {
		font-size: 1rem;
		color: var(--color-text);
	}
	.tabs.-active-3 .tabs__controls .tabs__button.is-active .-tab-active {
		background-color: var(--color-primary) !important;
	}
	.-tab-active {
		background-color: var(--color-primary) !important;
		color: #FFF;
	}
	.tabs-line::after {
		background-color: var(--color-accent);
		height: calc(100% - 200px);
	}
	.initial-img {
		height: 100%;
		width: auto;
	}
	.absolute-center {
		left: 0;
		transform: translateY(-50%);
	}
	.max-w-100 {
		width: 100%;
		max-width: inherit;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.timeline {
		.absolute-center {
			transform: translateY(-50%) translateX(5%);
		}
		.max-w-100 {
			width: 120%;
			max-width: inherit;
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.timeline {
		.absolute-center {
			transform: translateY(-50%);
		}
		.max-w-100 {
			width: 106%;
			max-width: inherit;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}