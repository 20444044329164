//Mobile------------------------------------------------------------------------------------------------------
.contact {
	h2 {
		color: var(--color-primary);
		font-size: 2.5rem;
		margin-bottom: 24px;
	}
	p {
		font-size: 1.125rem;
	}
	input[type="text"],
	input[type="email"]{
		font-size: 1.125rem;
		&::placeholder {
			font-size: 1.125rem;
		}
	}
	input::placeholder {
		color: #5C6F82;
	}
	.form-checkbox .checkbox__mark {
		border-color: var(--color-primary);
	}
	.form-checkbox .checkbox input:checked ~ .checkbox__mark {
		background-color: var(--color-primary);
		border-color: var(--color-primary);
	}
	button[type="submit"] {
		background-color: var(--color-accent);
		border-radius: 16px;
	}
	.right {
		padding: 24px;
	}
	img {
		width: 100%;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.contact {
		.left,
		.right {
			width: 50%;
		}
		.left {
			position: relative;
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				position: absolute;
				inset: 0;
			}
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.contact {
		.left,
		.right {
			width: 50%;
		}
		.right {
			padding: 90px;
			text-align: center;
		}
		
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}