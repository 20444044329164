//Mobile------------------------------------------------------------------------------------------------------
html {
	scroll-padding-top: 4rem;
}
body {
	font-family: "Nunito", sans-serif;
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;
}
.bg-light {
	background-color: var(--bg-light);
}
.fs-14 {
	font-size: 0.875rem !important;
}
.position-absolute {
	position: absolute;
	margin-top: -50px;
}
.privacy {
	h1 {
		font-size: 2rem;
		color: var(--color-primary);
		margin-bottom: 24px;
	}
	h2 {
		font-size: 1.5rem;
		margin-bottom: 12px;
		color: var(--color-primary);
	}
	p {
		margin-bottom: 24px;
	}
}

.form-group {
	label {
		a {
			&:hover {
				color: var(--font-dark);
			}
		}
	}
}

//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
}

//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
}

//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
}

//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}

//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
