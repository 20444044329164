//Mobile------------------------------------------------------------------------------------------------------
.header {
	position: relative;
	.nav li a {
		font-size: 1rem;
		font-weight: 700;
		color: var(--color-primary);
	}
	.container {
		width: 100%;
		max-width: inherit;
		padding-left: 32px;
		padding-right: 32px;
	}
	.base-logo {
		width: 200px;
		position: relative;
		img {
			width: 100%;
		}
	}
	&.-container .header__bar {
		padding-top: 28px;
		padding-bottom: 28px;
	}
	&.is-sticky .header__bar {
		padding-top: 28px !important;
		padding-bottom: 28px !important;
	}
	.base-logo-second {
		top :0
	}
	.menu-logo {
		width: 200px;
		img {
			width: 100%;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.header {
		.container {
			padding-left: 64px;
			padding-right: 64px;
		}
		.menu-logo, .base-logo {
			width: 273px;
		}
		&.-container .header__bar {
			padding-top: 50px;
			padding-bottom: 50px;
		}
		.base-logo-second {
			top :0px
		}
		&.is-sticky .header__bar {
			//padding-top: 50px !important;
			//padding-bottom: 50px !important;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}