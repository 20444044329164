//Mobile------------------------------------------------------------------------------------------------------
.footer {
	color: #FFF;
	p {
		font-size: 1.125rem;
	}
	li {
		font-size: 1rem;
	}
	.footer-top {
		padding: 32px;
		background-color: var(--color-primary);
	}
	.footer-bottom {
		padding: 16px 32px;
		background-color: #005C70;
		p {
			font-size: 1rem;
		}
	}
	ul{
		margin-top: 24px;
		li {
			margin-right: 16px;
		}
		a {
			text-decoration: none;
			&:hover {
				color: #FFF;
				text-decoration: underline;
			}
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.footer {
		ul{
			margin-top: 0;
			li {
				margin-left: 16px;
				margin-right: 0;
			}
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.footer {
		.footer-bottom {
			padding: 16px 62px;
		}
		.footer-top {
			padding: 64px;
		}
		
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}