//Mobile------------------------------------------------------------------------------------------------------
.img-3 {
	.bg-img {
		img {
			width: 100%;
		}
	}
	.imgs-wrapper {
		position: relative;
	}
	.img-left {
		position: absolute;
		left: 20px;
		bottom: -45%;
		width: 35%;
		img {
			width: 100%;
		}
		.circle {
			position: absolute;
			top: -6px;
			right: -1px;
		}
	}
	.img-right {
		position: absolute;
		right: 30px;
		top: -15%;
		width: 30%;
		.circle {
			position: absolute;
			top: -6px;
			left: -1px;
		}
		img {
			width: 100%;
		}
	}
	.circle {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 16px;
		height: 16px;
		border-radius: 50px;
		border: 2px solid #FFF;
		&:after {
			content: '';
			display: block;
			width: 6px;
			height: 6px;
			border-radius: 50px;
			background-color: var(--color-accent);
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.img-3 {
		.img-right {
			top: auto;
			bottom: -30%;
			width: 28%;
			.circle {
				top: -15px;
			}
			
		}
		.img-left {
			.circle {
				top: -15px;
			}
		}
		.circle {
			width: 32px;
			height: 32px;
			border: 6px solid #FFF;
			&:after {
				width: 10px;
				height: 10px;
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}