:root {
	--color-primary:#00738C;
	--color-accent : #EC6608;
	--color-text:#252C34;
	--bg-light: #C2E1E8;


	//-icon
	--icon-size-sm: 16px;
	--icon-size: 24px;
	--icon-size-lg: 32px;
	--icon-size-xl: 40px;
}

//dark theme
@media (prefers-color-scheme: dark) {
	:root {
		//-general
	}
}
