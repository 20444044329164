//Mobile------------------------------------------------------------------------------------------------------
.cols-2-img {
	p {
		font-weight: 700;
		color: var(--color-primary);
		font-size: 1.75rem;
		line-height: 2.625rem;
		margin-top: 24px;
		span {
			color:var(--color-accent)
		}
	}
	.bg-img {
		width: 75%;
		img {
			width: 100%;
		}
	}
	.top-img {
		position: absolute;
		top: -19%;
		right: 0;
		width: 80%;
		img {
			width: 100%;
		}
	}
	.atropos-inner {
		position: relative;
	}
	.pin {
		float: right;
		position: relative;
	}
	
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.cols-2-img {
		p {
			margin-top: 0;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}