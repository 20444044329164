//Mobile------------------------------------------------------------------------------------------------------
.section-number {
	.numCounter {
		font-size: 2rem;
		color: var(--color-primary);
		font-weight: 700;
		line-height: 1.1;
		margin-bottom: 32px;
	}
	h3 {
		font-size: 1.5rem;
		font-weight: 700;
		color: var(--color-primary);
	}
	p {
		color: var(--color-text);
		font-size: 1.125rem;
		margin-top: 24px;
	}
	img {
		width: 100%;
		height: auto;
	}

}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.section-number {
		scroll-padding: 500px;
		.numCounter {
			font-size: 3.5rem;
			margin-bottom: 64px;
		}
		.col-lg-6:nth-child(3),
		.col-lg-6:nth-child(4){
			.numCounter {
				margin-bottom: 0;
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}