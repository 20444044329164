//Mobile------------------------------------------------------------------------------------------------------
.tab-icon {
	.circle {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		border: 6px solid var(--color-primary);
		border-radius: 50px;
		margin-bottom: 24px;
		&:after {
			content: '';
			display: block;
			border-radius: 50px;
			width: 12px;
			height: 12px;
			background-color: var(--color-accent);

		}
	}
	.sectionHeading__title {
		color: var(--color-primary);
		span {
			color:var(--color-accent)
		}
		p {
			font-size: 1.125rem;
			color: var(--color-text)
		}
	}
	.tabs.-lines .tabs__button {
		padding: 16px;
		font-weight: 700;
		border-bottom-width: 2px;
		color: var(--color-text);
		&.is-active {
			color: var(--color-primary);
			border-color: var(--color-accent);
		}
	}
	.item-icon {
		margin-top: 40px;
		img {
			height: 64px;
			width: auto;
			margin-bottom: 24px;
		}
		h3 {
			font-size: 1.5rem;
			font-weight: 700;
			margin-bottom: 16px;
		}
		p {
			font-size: 1.125rem;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}